import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import {
  setCertifications,
  setEducation,
} from "../../../redux/actions/dataCollectionAction";
import { styles } from "./updateEducationModalStyles";

export default function UpdateEducationModal({
  type,
  modalTitle,
  show,
  selectedItemId,
  selectedItem,
  setSelectedItem = () => {},
  handleClose = () => {},
}) {
  const dataCollection = useSelector((state) => state.dataCollection);

  const dispatch = useDispatch();

  const handleFomation = (event, selectedFormation, formationElement) => {
    setSelectedItem({
      ...selectedFormation,
      [formationElement]: event.target.value,
    });
  };

  const handleEditEducationForm = () => {
    let data = [];
    if (type === "formation") {
      data = dataCollection?.Education || [];
    }
    if (type === "certification") {
      data = dataCollection?.Certifications || [];
    }
    const newListArray = data.map((elem, index) => {
      if (index.toString() === selectedItemId) return selectedItem;
      return elem;
    });
    if (type === "formation") {
      dispatch(setEducation(newListArray));
    }
    if (type === "certification") {
      dispatch(setCertifications(newListArray));
    }

    handleClose("save");
  };
  return (
    <Modal show={show} onHide={() => handleClose("reset")}>
      <Modal.Header closeButton>
        <Modal.Title style={styles.headerTitle}>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {dataCollection.Education != null ||
          dataCollection.Certifications != null ? (
            <div>
              <Form.Group className="mb-1">
                <Form.Label size="sm" color="primary">
                  <span style={styles.diplomeLabel}>Diplôme</span>
                </Form.Label>
                <div className="row">
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <Grid mb={1}>
                          <Form.Control
                            xm={1}
                            size="sm"
                            type="text"
                            value={selectedItem?.Title}
                            placeholder="diplôme"
                            autoFocus
                            onChange={(e) =>
                              handleFomation(e, selectedItem, "Title")
                            }
                          />
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Group>
              <Form.Group
                className="mb-1"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label size="sm">
                  <span style={styles.etablissementLabel}>Etablissement</span>
                </Form.Label>
                <div className="row">
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <Grid mb={1}>
                          <Form.Control
                            xm={1}
                            size="sm"
                            type="text"
                            value={selectedItem?.Institute}
                            onChange={(e) =>
                              handleFomation(e, selectedItem, "Institute")
                            }
                            placeholder="Etablissement"
                          />
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Group>
              <Form.Group
                className="mb-1"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label size="sm">
                  <span style={styles.periodLabel}>Période</span>
                </Form.Label>
                <div className="row">
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <Grid mb={1}>
                          <Form.Control
                            xm={1}
                            size="sm"
                            type="text"
                            value={selectedItem?.Period}
                            onChange={(e) =>
                              handleFomation(e, selectedItem, "Period")
                            }
                            placeholder="période"
                          />
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </div>
          ) : (
            <div> </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-xs-3"></div>

        <Button
          style={styles.saveButton}
          sx={{ bgcolor: "#DA2889", color: "white" }}
          onClick={handleEditEducationForm}
        >
          Enregistrer Changements
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
