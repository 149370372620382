export const SET_PDF_FILE = 'SET_PDF_FILE';
export const RESET_PDF_FILE = 'RESET_PDF_FILE';

export const setPdfFileData = (data) => ({
  type: SET_PDF_FILE,
  payload: { data }
});
export const resetPdfFileData = (data) => ({
  type: RESET_PDF_FILE,
  payload: { data }
});
