import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Tabs, Tab, InputGroup } from 'react-bootstrap';
import axios from "axios";
import config from "../../config";
import { setLoggedInUser } from '../../redux/actions/userActions';
import { useDispatch } from 'react-redux';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { setLoggedInNotif } from '../../redux/actions/notificationAction';
import { Box, Button, CircularProgress } from '@mui/material';

const LoginModal = ({ show, onHide }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [currentTab, setCurrentTab] = useState('login');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forgotPasswordError, setForgotPasswordError] = useState('');
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState('');

  const dispatch = useDispatch();

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const enterLoginUser = (event) => {
    if (event.key === 'Enter') {
      loginUser();
    }
  }

  const enterForgotPassword = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      forgotPassword();
    }
  }

  const loginUser = async () => {
    if (email.trim() === '' || password.trim() === '') {
      setLoginError("Veuillez saisir vos identifiants");
      return;
    }

    try {
      setLoading(true);
      const url=`${config.urlBackOffice}/login/`
      const response = await axios.post(url, {
          email: email,
          password: password
      });
      const token = response.data.token;
      dispatch(setLoggedInUser(email, token));
      setLoading(false);
      dispatch(setLoggedInNotif(true));
      onHide();
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        setLoginError(error.response.data.message);
      } else {
        setLoginError("Erreur lors de la requête");
      }
      console.error('Error logging in:', error);
      return null;
    }
  };

  const forgotPassword = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setForgotPasswordError("Veuillez saisir une adresse email valide. Les utilisateurs du groupe Aptea ne peuvent pas réinitialiser leur mot de passe.");
      return;
    }

    try {
      setLoading(true);
      const url = `${config.urlBackOffice}/forgot-password/`;
      await axios.post(url, { email });
      setLoading(false);
      setForgotPasswordSuccess("L'email de réinitialisation a été envoyé. Pensez à vérifier vos spams.");
      setForgotPasswordError('');
    } catch (error) {
      setLoading(false);
      setForgotPasswordSuccess("")
      if (error.response && error.response.data && error.response.data.message) {
        setForgotPasswordError(error.response.data.message);
      } else {
        setForgotPasswordError("Erreur lors de la requête");
      }
    }
  };

  useEffect(() => {
    setEmail("");
    setPassword("");
    setLoginError("");
    setCurrentTab("login");
    setShowPassword(false);
    setForgotPasswordError("");
    setForgotPasswordSuccess("");
  }, [show]);

  const tabTitleStyle = {
    color: '#200A5F',
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header style={{ backgroundColor: '#200A5F', color: 'white' }} closeButton closeVariant="white">
        <Modal.Title style={{ marginLeft: "10px" }}>Compte</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs activeKey={currentTab} onSelect={(k) => setCurrentTab(k)} className="mb-3">
          <Tab eventKey="login" title={<span style={currentTab !== 'login' ? tabTitleStyle : {}}>Connexion</span>}>
            <Form>
              <Row className="mb-3 mt-4">
                <Form.Label column sm={4}>Email:</Form.Label>
                <Col sm={8}>
                  <Form.Control type="text" placeholder="Entrez votre email" value={email} onChange={handleEmailChange} onKeyDown={enterLoginUser} autoFocus />
                </Col>
              </Row>
              <Row className="mb-3">
                <Form.Label column sm={4}>Mot de passe:</Form.Label>
                <Col sm={8}>
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Mot de passe"
                      value={password}
                      onChange={handlePasswordChange}
                      onKeyDown={enterLoginUser}
                    />
                    <Button variant="text" onClick={handleClickShowPassword} style={{ minWidth: "auto", color:"black" }}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col className="text-center my-1">
                  Vous souhaitez créer un compte premium ? <a href="https://www.aptea.net/contact">Contactez-nous</a>
                </Col>
              </Row>
            </Form>
          </Tab>
          <Tab eventKey="forgotPassword" title={<span style={currentTab !== 'forgotPassword' ? tabTitleStyle : {}}>Mot de passe oublié</span>}>
            <Form>
              <Row className="mb-3 mt-4">
                <Form.Label column sm={4}>Email:</Form.Label>
                <Col sm={8}>
                  <Form.Control type="text" placeholder="Entrez votre email" value={email} onChange={handleEmailChange} onKeyDown={enterForgotPassword} autoFocus />
                </Col>
              </Row>
            </Form>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        {currentTab === 'login' && (
          <>
            <Box mr={3} color="red" fontWeight={"bold"} fontSize={"small"}>
              {loginError}
            </Box>
            <Button
              style={{
                background: "#200A5F",
                color: "white",
                margin: "5px",
                transition: "background-color 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.background = "#36226f";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = "#200A5F";
              }}
              onClick={loginUser}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Connexion'
              )}
            </Button>
          </>
        )}
        {currentTab === 'forgotPassword' && (
          <>
            {forgotPasswordError && <Box mr={3} color="red" fontWeight={"bold"} fontSize={"small"}>{forgotPasswordError}</Box>}
            {forgotPasswordSuccess && <Box mr={3} color="green" fontWeight={"bold"} fontSize={"small"}>{forgotPasswordSuccess}</Box>}
            <Button
              style={{
                background: "#200A5F",
                color: "white",
                margin: "5px",
                transition: "background-color 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.background = "#36226f";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = "#200A5F";
              }}
              onClick={forgotPassword}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Envoyer un email de réinitialisation'
              )}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default LoginModal;
