import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";

import GlobalCorrection from "../components/correction/GlobalCorrection";
import StepperComponent from "../components/StepperComponent";

import { setActiveStep } from "../redux/actions/stepperAction";
export default function Correction() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActiveStep(1));
  }, []);

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <StepperComponent />
      </div>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 5, sm: 2, md: 3 }}>
        <Grid item xs={3.7}></Grid>
        <Grid item sx={{ mt: 8 }} xs={11.5}>
          <GlobalCorrection />
        </Grid>
      </Grid>
    </>
  );
}
