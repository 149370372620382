import React from "react";
import DropFileInput from "./DropFileInput";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

function DropFileComponent({
  onDropFile=()=> {},
  nextPage=()=>{}
}) {
  const onFileChange = (files) => {
    console.log(files);
  };

  return (
    <div>
      <DropFileInput  nextPage={nextPage} onDropFile={onDropFile} onFileChange={(files) => onFileChange(files)} />
    </div>
  );
}

export default DropFileComponent;
