import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { TextareaAutosize, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Tooltip } from "@mui/material";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HandymanIcon from "@mui/icons-material/Handyman";

import CardContent from "@mui/material/CardContent";

import experienceIllustration from "../../../assets/experienceIllustration1.jpg";
import { setExperiences } from "../../../redux/actions/dataCollectionAction";
import {
  getReordredDescriptionDetailsInNewModal,
  handleMovingTextToTechnologiesSection,
  reorder,
} from "./ExperienceUtilities";
import ExperienceTools from "./ExperienceTools";
import SnackBar from "../../SnackBar";

const DeleteButton = ({ handleClick }) => (
  <span onClick={handleClick} style={{ cursor: "pointer", fontSize: "30px" }}>
    &times;
  </span>
);

export default function AddExperienceModal({
  openModal,
  handleAddExperienceClose = () => {},
}) {
  const [position, setPosition] = useState("");
  const [company, setCompany] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [technologies, setTechnologies] = useState([]);
  const [descriptions, setDescriptions] = useState([
    {
      Context: [""],
      Details: [""],
    },
  ]);
  const [selectedText, setSelectedText] = useState("");
  const [snackBarInfo, setSnackBar] = useState({
    status: false,
    message: "",
    type: "",
  });
  const [descriptionRowInfo, setDescriptionRowInfo] = useState({
    showPalette: false,
    descriptionIndex: "0",
    originIndex: 0,
    origin: "",
  });

  const textAreaDetailsRefs = useRef({});
  const technologiesRefs = useRef([]);
  const descriptionsRefs = useRef([]);

  const dataCollection = useSelector((state) => state.dataCollection);

  const dispatch = useDispatch();

  const styles = {
    title: {
      color: "#fff",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      fontFamily: "FancyFont, sans-serif",
    },
    illustration: {
      width: "50px",
      height: "50px",
      marginRight: "10px",
      filter: "hue-rotate(150deg) saturate(200%)",
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        transform: "scale(1.2)",
        filter: "brightness(110%)",
      },
    },
    header: {
      background: "linear-gradient(to right, #200A5F, #6A0080)",
    },
  };

  const addInputTechnologies = () => {
    setTechnologies((s) => {
      return [...s, ""];
    });
  };

  const addTechnologiesToNewExperience = (e, index) => {
    setTechnologies((s) => {
      let newArr = s.slice().filter((val) => val !== null && val !== "");
      newArr[index] = e.target.value;
      return newArr;
    });
  };

  const deleteTechnologieFromNexExperience = (element) => {
    setTechnologies(technologies.filter((item) => item !== element));
  };

  const addInputDescriptions = () => {
    setDescriptions([...descriptions, { Context: [""], Details: [""] }]);
  };

  const deleteDetailFromDescription = (element) => {
    setDescriptions(descriptions.filter((item) => item !== element));
  };

  const addContextToNewDescription = (e, indexDescription, index) => {
    setDescriptions((s) => {
      const newArr = [...s];
      newArr[indexDescription].Context[index] = e.target.value;
      return newArr;
    });
  };

  const addDetailToNewDescription = (e, indexDescription, index) => {
    setDescriptions((s) => {
      const newArr = [...s];
      newArr[indexDescription].Details[index] = e.target.value;
      return newArr;
    });
  };

  const addInputDeatilsByDescriptions = (index) => {
    setDescriptions((prevDescriptions) => {
      let updatedDescriptions = [...prevDescriptions];
      let selectedDescription = updatedDescriptions[index];
      selectedDescription.Details = [...selectedDescription.Details, ""];
      updatedDescriptions[index] = selectedDescription;
      return updatedDescriptions;
    });
  };

  const deleteInputDetailByDescriptions = (
    descriptions,
    descIndex,
    DetailsIndex
  ) => {
    let updatedDescriptions = [...descriptions];
    let selectedDescription = updatedDescriptions[descIndex];
    selectedDescription.Details = selectedDescription.Details.filter(
      (_, i) => i !== DetailsIndex
    );
    updatedDescriptions[descIndex] = selectedDescription;
    setDescriptions(updatedDescriptions);
    return updatedDescriptions;
  };

  const addNewExperience = (newPosition, dateStart, dateEnd, newEntreprise) => {
    const newPeriode = dateStart.concat("    -   ", dateEnd);
    const oldExperience = dataCollection.Experiences || [];
    const newExperience = [
      ...oldExperience,
      {
        Company: newEntreprise,
        Position: newPosition,
        Description: descriptions,
        Technologies: technologies,
        Period: newPeriode,
        Score: "12",
      },
    ];

    dispatch(setExperiences(newExperience));

    handleAddExperienceClose();
    // setUser({ ...user, Experiences: parentsArray });
  };

  const onDragEnd = ({ result, selectedType, descriptionIndex }) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let list = [];

    if (selectedType === "technologiesInNewExperienceModal") {
      list = technologies ? technologies : [];
    } else if (selectedType === "descriptionInNewExperienceModal") {
      list = descriptions ? descriptions : [];
    } else if (selectedType === "descriptionDetailsInNewExperienceModal") {
      list = descriptions[descriptionIndex]?.Details
        ? descriptions[descriptionIndex]?.Details
        : [];
    }

    const newList = reorder(
      list,
      result.source.index,
      result.destination.index
    );

    if (selectedType === "technologiesInNewExperienceModal") {
      setTechnologies(newList);
    } else if (selectedType === "descriptionInNewExperienceModal") {
      setDescriptions(newList);
    } else if (selectedType === "descriptionDetailsInNewExperienceModal") {
      const descriptionFiltred = getReordredDescriptionDetailsInNewModal(
        descriptions,
        newList,
        descriptionIndex
      );
      setDescriptions(descriptionFiltred);
    }
  };

  const updateSelectedContext = (
    descriptions,
    descriptionIndex,
    contextDestinationIndex,
    contextConcat
  ) => {
    const descriptionContext = descriptions[descriptionIndex]?.Context || [];
    const oldDescriptionElement = descriptions[descriptionIndex];
    const oldDescription = descriptions;
    const newDescription = oldDescription?.map((elem, index) => {
      if (index?.toString() === descriptionIndex.toString()) {
        const newContext = descriptionContext?.map((contextElem, indexElem) => {
          if (indexElem.toString() === contextDestinationIndex.toString()) {
            return contextConcat.target.value;
          } else {
            return contextElem;
          }
        });
        return {
          ...oldDescriptionElement,
          Context: newContext,
        };
      } else {
        return elem;
      }
    });

    setDescriptions(newDescription);
    return newDescription;
  };
  const updateSelectedDetail = (
    descriptions,
    descriptionIndex,
    detailIndex,
    event
  ) => {
    const descriptionDetails = descriptions[descriptionIndex]?.Details || [];
    const oldDescriptionElement = descriptions[descriptionIndex];
    const newDescription = descriptions?.map((elem, index) => {
      if (index?.toString() === descriptionIndex.toString()) {
        const newDetails = descriptionDetails?.map((detailElem, indexElem) => {
          if (indexElem.toString() === detailIndex.toString()) {
            return event.target.value;
          } else {
            return detailElem;
          }
        });

        return {
          ...oldDescriptionElement,
          Details: newDetails,
        };
      } else {
        return elem;
      }
    });
    setDescriptions(newDescription);
    return newDescription;
  };
  const moveUpDetail = (descriptions, descriptionIndex, detailIndex) => {
    let updatedDescriptions = [];
    const descriptionDetail = descriptions[descriptionIndex]?.Details || [];

    const detailToBeMoved = descriptionDetail[detailIndex];
    if (!detailToBeMoved.length) {
      setSnackBar({
        status: "error",
        message: "Le champ est vide, vous ne pouvez pas le déplace",
        type: "error",
      });
      return;
    }
    if (detailIndex > 0) {
      const detailDestinationIndex = detailIndex - 1;

      const detailDestination = descriptionDetail[detailDestinationIndex];
      const detailConcat = {
        target: { value: detailDestination.concat(` ${detailToBeMoved}`) },
      };

      updatedDescriptions = updateSelectedDetail(
        descriptions,
        descriptionIndex,
        detailDestinationIndex,
        detailConcat
      );
    } else {
      const descriptionContext = descriptions[descriptionIndex]?.Context || [];
      if (descriptionContext.length !== 0) {
        const contextDestinationIndex = descriptionContext.length - 1;
        const contextDestination = descriptionContext[contextDestinationIndex];
        const contextConcat = {
          target: { value: contextDestination.concat(` ${detailToBeMoved}`) },
        };
        updatedDescriptions = updateSelectedContext(
          descriptions,
          descriptionIndex,
          contextDestinationIndex,
          contextConcat
        );
      }
    }
    deleteInputDetailByDescriptions(
      updatedDescriptions,
      descriptionIndex,
      detailIndex
    );
  };
  const moveDownDetail = (descriptions, descriptionIndex, detailIndex) => {
    let updatedDescriptions = {};
    const descriptionDetails = descriptions[descriptionIndex]?.Details || [];
    const detailToBeMoved = descriptionDetails[detailIndex];
    if (!detailToBeMoved.length) {
      setSnackBar({
        status: "error",
        message: "Le champ est vide, impossible de le déplacer",
        type: "error",
      });
      return;
    }
    if (detailIndex < descriptionDetails.length - 1) {
      const detailDestinationIndex = detailIndex + 1;

      const detailDestination = descriptionDetails[detailDestinationIndex];

      const detailConcat = {
        target: { value: detailToBeMoved.concat(` ${detailDestination}`) }, // detailToBeMoved
      };
      updatedDescriptions = updateSelectedDetail(
        descriptions,
        descriptionIndex,
        detailDestinationIndex,
        detailConcat
      );
    } else {
      const descriptionDestinationIndex = parseInt(descriptionIndex) + 1;
      const descriptionContext =
        descriptions[descriptionDestinationIndex]?.Context || [];
      if (descriptionIndex == descriptions.length - 1) {
        return;
      }
      if (descriptionContext.length != 0) {
        const contextDestinationIndex = 0;
        const contextDestination = descriptionContext[contextDestinationIndex];
        const contextConcat = {
          target: { value: detailToBeMoved.concat(` ${contextDestination}`) },
        };

        updatedDescriptions = updateSelectedContext(
          descriptions,
          descriptionDestinationIndex.toString(),
          contextDestinationIndex,
          contextConcat
        );
      }
    }
    updatedDescriptions = deleteInputDetailByDescriptions(
      updatedDescriptions,
      descriptionIndex,
      detailIndex
    );
  };

  const handleTextSelection = (descriptionIndex, oringinIndex) => {
    /**
     * descriptionIndex : description index from selected experience
     * oringinIndex : text index from origin
     * origin : define selected text from Context or Details
     */
    if (!textAreaDetailsRefs.current) return;

    // Access the correct textarea ref using both descriptionIndex and oringinIndex
    const textarea =
      textAreaDetailsRefs.current[descriptionIndex]?.[oringinIndex];

    // Check if the textarea ref exists before using it
    if (textarea) {
      const indexStart = textarea.selectionStart;
      const indexEnd = textarea.selectionEnd;

      // Extract the selected text
      const _selectedText = textarea.value
        .substring(indexStart, indexEnd)
        .trim();

      setSelectedText(_selectedText);
    } else {
      console.log("Textarea ref is null or undefined");
    }
  };
  const moveToTechnologiesSection = () => {
    const selectedItem = {
      Technologies: technologies,
      Description: descriptions,
    };
    if (!selectedText.length) {
      setSnackBar({
        status: true,
        message:
          "Sélectionner un texte pour pouvoir le déplacer dans le champ 'technologies'",
        type: "info",
      });
      return;
    }

    // Move text to Technologies section and cut selected text from his origin
    const experience = handleMovingTextToTechnologiesSection(
      selectedItem,
      selectedText
    );

    setTechnologies(experience.Technologies);
    setDescriptions(experience.Description);
    setSelectedText("");
    setSnackBar({
      status: true,
      message: "Le texte sélectionné a été copié avec succès",
      type: "success",
    });
  };

  useEffect(() => {
    setPosition("");
    setCompany("");
    setDateStart("");
    setDateEnd("");
    setSelectedText("");
    setTechnologies([]);
    setDescriptions([
      {
        Context: [""],
        Details: [""],
      },
    ]);
    setDescriptionRowInfo({
      descriptionIndex: 0,
      originIndex: 0,
      origin: "",
      showPalette: false,
    });
  }, [openModal]);

  const onAddTechnologieFocus = () => {
    if (technologies.length > 0) {
      technologiesRefs.current[technologies.length - 1].focus();
    }
  };
  const onAddDescriptionFocus = () => {
    if (descriptions.length > 0) {
      const lastDescriptionRef =
        descriptionsRefs.current[descriptions.length - 1];
      if (lastDescriptionRef) {
        lastDescriptionRef.scrollIntoView({ behavior: "smooth" });
        lastDescriptionRef.focus();
      }
    }
  };

  return (
    <Modal
      dialogClassName="modal-xl"
      show={openModal}
      onHide={handleAddExperienceClose}
    >
      <Modal.Header closeButton style={styles.header}>
        <Modal.Title style={styles.title}>
          <img src={experienceIllustration} style={styles.illustration} />
          <h2>Ajouter Experience</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Position */}
          <Form.Group className="mb-1">
            <Form.Label size="sm" color="primary">
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Position
              </span>
            </Form.Label>
            <div className="row">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <Grid mb={1}>
                      <Form.Control
                        xm={1}
                        size="sm"
                        type="text"
                        value={position}
                        placeholder="position"
                        autoFocus
                        onChange={(e) => setPosition(e.target.value)}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Form.Group>

          {/* Entreprise */}
          <Form.Group className="mb-1">
            <Form.Label size="sm" color="primary">
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Entreprise
              </span>
            </Form.Label>
            <div className="row">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <Grid mb={1}>
                      <Form.Control
                        xm={1}
                        size="sm"
                        type="text"
                        value={company}
                        placeholder="entreprise"
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Form.Group>
          {/* Date de début */}
          <Form.Group className="mb-1">
            <Form.Label size="sm" color="primary">
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Date de début
              </span>
            </Form.Label>
            <div className="row">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <Grid mb={1}>
                      <Form.Control
                        xm={1}
                        size="sm"
                        type="text"
                        value={dateStart}
                        placeholder="date de début"
                        onChange={(e) => setDateStart(e.target.value)}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Form.Group>
          {/* Date de Fin */}
          <Form.Group className="mb-1">
            <Form.Label size="sm" color="primary">
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Date de Fin
              </span>
            </Form.Label>

            <div className="row">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <Grid mb={1}>
                      <Form.Control
                        xm={1}
                        size="sm"
                        type="text"
                        value={dateEnd}
                        placeholder="date de fin"
                        onChange={(e) => setDateEnd(e.target.value)}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Form.Group>
          {/* Technologies */}
          <Form.Group
            className="mb-1 mt-3"
            controlId="exampleForm.ControlInput1"
          >
            <div className="row">
              <div className="col-9 col-md-9 col-lg-10">
                <Form.Label size="sm">
                  <span
                    style={{
                      fontstyle: "oblique",
                      bgcolor: "#200A5F",
                      color: "#200A5F",
                    }}
                  >
                    Technologies
                  </span>
                </Form.Label>
              </div>
              <div className="col-3 col-md-3 col-lg-2">
                <Grid mb={2} mr={3}>
                  <Button
                    sx={{
                      bgcolor: "#200A5F",
                      color: "white",
                      ":hover": {
                        color: "#200A5F",
                        border: "1px solid #200A5F",
                        paddingRight: "5px",
                      },
                    }}
                    onClick={() => {
                      addInputTechnologies();
                      onAddTechnologieFocus();
                    }}
                  >
                    <AddCircleOutlineIcon fontSize="small" />
                    <span style={{ fontSize: "x-small" }}> Ajouter</span>
                  </Button>
                </Grid>
              </div>
            </div>

            <div>
              <DragDropContext
                onDragEnd={(result) =>
                  onDragEnd({
                    result,
                    selectedType: "technologiesInNewExperienceModal",
                  })
                }
              >
                <Droppable droppableId="list">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {technologies
                        .filter((val) => val !== null)
                        .map((element, index) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={parseInt(index)}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="row" key={index}>
                                  <div className="col-11 col-md-11">
                                    <input
                                      type="text"
                                      list="techList"
                                      ref={(el) =>
                                        (technologiesRefs.current[index] = el)
                                      }
                                      value={element}
                                      onChange={(e) =>
                                        addTechnologiesToNewExperience(e, index)
                                      }
                                      style={{
                                        backgroundColor: "white",
                                        border: "1px solid gray",
                                        borderRadius: "5px",
                                        padding: "10px",
                                        fontSize: "16px",
                                        width: "100%",
                                        height: "30px",
                                      }}
                                    />
                                  </div>

                                  <div className="col-1 col-md-1">
                                    <Grid mb={1}>
                                      <Grid mx={-3}>
                                        <Button
                                          onClick={() =>
                                            deleteTechnologieFromNexExperience(
                                              element
                                            )
                                          }
                                        >
                                          <span style={{ fontSize: "small" }}>
                                            <DeleteOutlinedIcon
                                              style={{ color: "#DA2889" }}
                                            />
                                          </span>
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Form.Group>

          {/* Descriptions */}
          <Form.Group
            className="mb-1 mt-3"
            controlId="exampleForm.ControlInput1"
          >
            <div className="row">
              <div className="col-9 col-md-9 col-lg-10">
                <Form.Label size="sm">
                  <span
                    style={{
                      fontstyle: "oblique",
                      bgcolor: "#200A5F",
                      color: "#200A5F",
                    }}
                  >
                    Descriptions
                  </span>
                </Form.Label>
              </div>
              <div className="col-3 col-md-3 col-lg-2">
                <Grid mb={2}>
                  <Button
                    sx={{
                      bgcolor: "#200A5F",
                      color: "white",
                      ":hover": {
                        color: "#200A5F",
                        border: "1px solid #200A5F",
                      },
                    }}
                    onClick={() => {
                      addInputDescriptions();
                      onAddDescriptionFocus();
                    }}
                  >
                    <AddCircleOutlineIcon fontSize="small" />
                    <span style={{ fontSize: "x-small" }}> Ajouter</span>
                  </Button>
                </Grid>
              </div>
            </div>

            <div>
              <DragDropContext
                onDragEnd={(result) =>
                  onDragEnd({
                    result,
                    selectedType: "descriptionInNewExperienceModal",
                  })
                }
              >
                <Droppable droppableId="list1">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {descriptions.length &&
                        descriptions?.map((element, key) => (
                          <Draggable
                            key={key}
                            draggableId={key.toString()}
                            index={parseInt(key)}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div key={key}>
                                  <Card
                                    style={{
                                      root: {
                                        minWidth: 275,
                                      },
                                    }}
                                  >
                                    <div
                                      className="row"
                                      ref={(el) =>
                                        (descriptionsRefs.current[key] = el)
                                      }
                                    >
                                      <div className="col-10 col-md-10 col-lg-11"></div>
                                      <div
                                        className="col-2 col-md-2 col-lg-1"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          fontWeight: "bold",
                                          color: "#DA2889",
                                        }}
                                      >
                                        <DeleteButton
                                          handleClick={() =>
                                            deleteDetailFromDescription(element)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="card-number"
                                      style={{
                                        fontSize: "30px",
                                        fontWeight: "bold",
                                        color: "#DA2889",
                                        WebkitBackgroundClip: "text",
                                        textAlign: "center",
                                      }}
                                    >
                                      {key + 1}
                                    </div>
                                    <CardContent>
                                      <Typography variant="body2" component="p">
                                        {element.Context.map((ctx, keyc) => (
                                          <div className="row" key={keyc}>
                                            <div className="col-md-8">
                                              <TextareaAutosize
                                                style={{
                                                  width: "100%",
                                                  backgroundColor: "#f3f4fa",
                                                  padding: "3px",
                                                }}
                                                minRows={3}
                                                type="text"
                                                value={ctx}
                                                id={keyc}
                                                placeholder="Nom du projet"
                                                onChange={(e) =>
                                                  addContextToNewDescription(
                                                    e,
                                                    key,
                                                    keyc
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        ))}
                                        <div className="row">
                                          <div>
                                            <Grid mt={2} mb={2}>
                                              <Button
                                                onClick={(e) =>
                                                  addInputDeatilsByDescriptions(
                                                    key
                                                  )
                                                }
                                                sx={{
                                                  bgcolor: "#a9bef0",
                                                  color: "#003366",
                                                }}
                                                _hover={{
                                                  color: "#000099",
                                                }}
                                                _active={{
                                                  color: "#000066",
                                                }}
                                                _focus={{
                                                  color: "#0000ff",
                                                }}
                                              >
                                                <AddCircleOutlineIcon fontSize="extraSmall" />
                                                <span
                                                  style={{
                                                    fontSize: "xx-small",
                                                    paddingLeft: "5px",
                                                    paddingTop: "3px",
                                                  }}
                                                >
                                                  Ajouter details
                                                </span>
                                              </Button>
                                            </Grid>
                                          </div>
                                        </div>

                                        <DragDropContext
                                          onDragEnd={(result) =>
                                            onDragEnd({
                                              result,
                                              selectedType:
                                                "descriptionDetailsInNewExperienceModal",
                                              descriptionIndex: key,
                                            })
                                          }
                                        >
                                          <Droppable droppableId="list2">
                                            {(provided, snapshot) => (
                                              <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                              >
                                                {element.Details.map(
                                                  (ctx, keyd) => (
                                                    <Draggable
                                                      key={keyd}
                                                      draggableId={keyd.toString()}
                                                      index={parseInt(keyd)}
                                                    >
                                                      {(provided, snapshot) => (
                                                        <div
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                        >
                                                          {descriptionRowInfo?.showPalette &&
                                                            descriptionRowInfo?.origin ==
                                                              "Details" &&
                                                            keyd ==
                                                              descriptionRowInfo?.originIndex &&
                                                            key ==
                                                              descriptionRowInfo?.descriptionIndex && (
                                                              <div className="row">
                                                                <div className="col-0 col-sm-0 col-md-0 col-lg-4" />
                                                                <div className="col-10 col-sm-10 col-md-11 col-lg-4">
                                                                  <ExperienceTools
                                                                    type="add"
                                                                    onMoveToTechnologies={() =>
                                                                      moveToTechnologiesSection()
                                                                    }
                                                                    onDeleteRow={() =>
                                                                      deleteInputDetailByDescriptions(
                                                                        descriptions,
                                                                        descriptionRowInfo.descriptionIndex,
                                                                        descriptionRowInfo.originIndex
                                                                      )
                                                                    }
                                                                    onMoveUp={() =>
                                                                      moveUpDetail(
                                                                        descriptions,
                                                                        descriptionRowInfo.descriptionIndex,
                                                                        descriptionRowInfo.originIndex
                                                                      )
                                                                    }
                                                                    onMoveDown={() =>
                                                                      moveDownDetail(
                                                                        descriptions,
                                                                        descriptionRowInfo.descriptionIndex,
                                                                        descriptionRowInfo.originIndex
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                                <div className="col-2 col-sm-2 col-md-1 col-lg-4" />
                                                              </div>
                                                            )}
                                                          <div
                                                            className="row"
                                                            style={{
                                                              paddingTop: "5px",
                                                            }}
                                                            key={keyd}
                                                          >
                                                            <div className="col-10 col-md-11">
                                                              <TextareaAutosize
                                                                ref={(el) => {
                                                                  if (
                                                                    !textAreaDetailsRefs
                                                                      .current[
                                                                      key
                                                                    ]
                                                                  ) {
                                                                    textAreaDetailsRefs.current[
                                                                      key
                                                                    ] = {};
                                                                  }
                                                                  textAreaDetailsRefs.current[
                                                                    key
                                                                  ][keyd] = el;
                                                                }}
                                                                style={{
                                                                  width: "100%",
                                                                  backgroundColor:
                                                                    "#f3f4fa",
                                                                  padding:
                                                                    "3px",
                                                                }}
                                                                placeholder="Description"
                                                                minRows={3}
                                                                type="text"
                                                                id={keyd}
                                                                value={ctx}
                                                                onChange={(e) =>
                                                                  addDetailToNewDescription(
                                                                    e,
                                                                    key,
                                                                    keyd
                                                                  )
                                                                }
                                                                onMouseUp={() =>
                                                                  handleTextSelection(
                                                                    key,
                                                                    keyd
                                                                  )
                                                                }
                                                                onFocus={() => {
                                                                  setDescriptionRowInfo(
                                                                    {
                                                                      ...descriptionRowInfo,
                                                                      showPalette: true,
                                                                      descriptionIndex:
                                                                        key,
                                                                      origin:
                                                                        "Details",
                                                                      originIndex:
                                                                        keyd,
                                                                    }
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                            <div
                                                              className=" col-1 col-md-1"
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              <Grid mb={1}>
                                                                <Button
                                                                  onClick={() => {
                                                                    const showPaletteValue =
                                                                      keyd ==
                                                                      descriptionRowInfo?.originIndex
                                                                        ? !descriptionRowInfo?.showPalette
                                                                        : true;
                                                                    setDescriptionRowInfo(
                                                                      {
                                                                        showPalette:
                                                                          showPaletteValue,
                                                                        descriptionIndex:
                                                                          key,
                                                                        originIndex:
                                                                          keyd,
                                                                        origin:
                                                                          "Details",
                                                                      }
                                                                    );
                                                                  }}
                                                                >
                                                                  <Tooltip
                                                                    title={
                                                                      "Outils"
                                                                    }
                                                                  >
                                                                    <HandymanIcon
                                                                      style={{
                                                                        color:
                                                                          "#200A5F",
                                                                      }}
                                                                    />
                                                                  </Tooltip>
                                                                </Button>
                                                              </Grid>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                                {provided.placeholder}
                                              </div>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Form.Group>
        </Form>
        {snackBarInfo && (
          <SnackBar
            openSnack={snackBarInfo?.status}
            message={snackBarInfo?.message}
            setOpenSnack={(val) => setSnackBar(val)}
            type={snackBarInfo?.type}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="col-xs-3"></div>

        <Button
          style={{
            textTransform: "capitalize",
            border: "2px solid transparent",
            borderColor: "#DA2889",
            fontweight: "bold",
            borderRadius: "5px",
          }}
          sx={{ bgcolor: "#DA2889", color: "white" }}
          onClick={() =>
            addNewExperience(position, dateStart, dateEnd, company)
          }
        >
          Enregistrer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
