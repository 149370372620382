import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Tooltip } from "@mui/material";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CardContent from "@mui/material/CardContent";
import HandymanIcon from "@mui/icons-material/Handyman";

import experienceIllustration from "../../../assets/experienceIllustration1.jpg";
import { setExperiences } from "../../../redux/actions/dataCollectionAction";
import {
  addDetail,
  createNewDescriptionFromIndex,
  deleteSelectedDetailInEditModalExperirnce,
  getFinalEditedOrderExperienceParams,
  handleMovingTextToTechnologiesSection,
  updateSelectedDetailInEditModalExperirnce,
} from "./ExperienceUtilities";
import { TextareaAutosize } from "@mui/material";
import { styles } from "./UpdateExperienceModalStyles";
import SnackBar from "../../SnackBar";
import ExperienceTools from "./ExperienceTools";

const DeleteButton = ({ handleClick }) => (
  <span onClick={handleClick} style={{ cursor: "pointer", fontSize: "30px" }}>
    &times;
  </span>
);

export default function UpdateExperienceModal({
  show,
  selectedItem,
  experienceId,
  handleClose = () => {},
  setSelectedItem = () => {},
}) {
  const [parametersTechno, setParametersTechno] = useState([]);
  const [selectedText, setSelectedText] = useState("");
  const [newExperience, setNewExperience] = useState(null);
  const [descriptionRowInfo, setDescriptionRowInfo] = useState({
    showPalette: false,
    descriptionIndex: "0",
    originIndex: 0,
    origin: "",
  });

  const [snackBar, setSnackBar] = useState({
    status: false,
    message: "",
    type: "",
  });

  const textAreaContextRefs = useRef({});
  const textAreaDetailsRefs = useRef({});
  const technologiesRefs = useRef([]);
  const descriptionsRefs = useRef([]);

  const dataCollection = useSelector((state) => state.dataCollection);

  const dispatch = useDispatch();

  const handleExperience = (event, selectedExperience, experienceElement) => {
    setSelectedItem({
      ...selectedExperience,
      [experienceElement]: event.target.value,
    });
  };

  const addForComplexType = (event, selectedExperience, experienceElement) => {
    const technologies = selectedExperience?.Technologies || [];
    setSelectedItem({
      ...selectedExperience,
      [experienceElement]: [...technologies, ""],
    });
  };

  const handleExperienceComplexeType = (
    event,
    key,
    selectedExperience,
    experienceElement
  ) => {
    let technologies = selectedExperience?.Technologies || [];
    technologies[key] = event.target.value;
    setSelectedItem({
      ...selectedExperience,
      [experienceElement]: technologies,
    });
  };

  const deleteComplexType = (key, selectedExperience, experienceElement) => {
    const newTechnologies = selectedExperience?.Technologies?.filter(
      (elem, index) => index.toString() !== key
    );

    setSelectedItem({
      ...selectedExperience,
      [experienceElement]: newTechnologies,
    });
  };

  const deleteDescriptionsByExperience = (selectedExperience, key) => {
    const newDescriptArray = selectedExperience?.Description?.filter(
      (elem, index) => index.toString() !== key.toString()
    );
    const updateSelectExperience = {
      ...selectedExperience,
      Description: newDescriptArray,
    };
    setSelectedItem(updateSelectExperience);
    return updateSelectExperience;
  };

  const addInputDescriptionsByExperience = (selectedExperience) => {
    const newDescriptionElement = { Context: [""], Details: [""] };
    const description = selectedExperience?.Description;
    setSelectedItem({
      ...selectedExperience,
      Description: [...description, newDescriptionElement],
    });
  };

  const addContextToSelectedDescription = (
    selectedExperience,
    descriptionIndex,
    contextElement = ""
  ) => {
    const descriptionContext =
      selectedExperience?.Description[descriptionIndex]?.Context || [];
    const oldDescriptionElement =
      selectedExperience?.Description[descriptionIndex];
    const oldDescription = selectedExperience?.Description;
    const newDescription = oldDescription?.map((elem, index) => {
      if (index?.toString() === descriptionIndex) {
        return {
          ...oldDescriptionElement,
          Context: [...descriptionContext, contextElement],
        };
      } else {
        return elem;
      }
    });
    const updateSelectedExperience = {
      ...selectedExperience,
      Description: newDescription,
    };

    setSelectedItem(updateSelectedExperience);
    return updateSelectedExperience;
  };
  const handleTextSelection = (descriptionIndex, oringinIndex, origin) => {
    /**
     * descriptionIndex : description index from selected experience
     * oringinIndex : text index from origin
     * origin : define selected text from Context or Details
     */
    if (!textAreaContextRefs.current && !textAreaDetailsRefs.current) return;

    // Access the correct textarea ref using both descriptionIndex and oringinIndex
    const textarea =
      origin == "Context"
        ? textAreaContextRefs.current[descriptionIndex]?.[oringinIndex]
        : textAreaDetailsRefs.current[descriptionIndex]?.[oringinIndex];

    // Check if the textarea ref exists before using it
    if (textarea) {
      const indexStart = textarea.selectionStart;
      const indexEnd = textarea.selectionEnd;

      // Extract the selected text
      const _selectedText = textarea.value
        .substring(indexStart, indexEnd)
        .trim();

      setSelectedText(_selectedText);
    } else {
      console.log("Textarea ref is null or undefined");
    }
  };
  const moveToTechnologiesSection = (selectedItem) => {
    if (!selectedText.length) {
      setSnackBar({
        status: true,
        message:
          "Sélectionner un texte pour pouvoir le déplacer dans le champ 'technologies'",
        type: "info",
      });
      return;
    }
    // Move text to Technologies section and cut selected text from his origin
    const experience = handleMovingTextToTechnologiesSection(
      selectedItem,
      selectedText
    );
    setSelectedItem(experience);
    setSelectedText("");
    setSnackBar({
      status: true,
      message: "Le texte sélectionné a été copié avec succèss",
      type: "success",
    });
  };

  function updateSelectedContext(
    selectedExperience,
    descriptionIndex,
    contextIndex,
    event
  ) {
    const descriptionContext =
      selectedExperience?.Description[descriptionIndex]?.Context || [];
    const oldDescriptionElement =
      selectedExperience?.Description[descriptionIndex];
    const oldDescription = selectedExperience?.Description;
    const newDescription = oldDescription?.map((elem, index) => {
      if (index?.toString() === descriptionIndex) {
        const newContext = descriptionContext?.map((contextElem, indexElem) => {
          if (indexElem.toString() === contextIndex.toString()) {
            return event.target.value;
          } else {
            return contextElem;
          }
        });
        return {
          ...oldDescriptionElement,
          Context: newContext,
        };
      } else {
        return elem;
      }
    });
    const updateSelectedExperience = {
      ...selectedExperience,
      Description: newDescription,
    };
    setSelectedItem(updateSelectedExperience);
    return updateSelectedExperience;
  }

  const deleteSelectedContext = (
    selectedExperience,
    descriptionIndex,
    contextIndex
  ) => {
    const descriptionContext =
      selectedExperience?.Description[descriptionIndex]?.Context || [];
    const oldDescriptionElement =
      selectedExperience?.Description[descriptionIndex];
    const oldDescription = selectedExperience?.Description;
    const newDescription = oldDescription?.map((elem, index) => {
      if (index?.toString() === descriptionIndex) {
        const newContext = descriptionContext?.filter(
          (contextElem, indexElem) =>
            indexElem.toString() !== contextIndex.toString()
        );
        return {
          ...oldDescriptionElement,
          Context: newContext,
        };
      } else {
        return elem;
      }
    });
    const updatedSelectedDetailtedSelectExperience = {
      ...selectedExperience,
      Description: newDescription,
    };
    setSelectedItem(updatedSelectedDetailtedSelectExperience);
    return updatedSelectedDetailtedSelectExperience;
  };
  const moveUpContext = (
    selectedExperience,
    descriptionIndex,
    contextIndex
  ) => {
    let selectUpdatedItem = {};
    const descriptionContext =
      selectedExperience?.Description[descriptionIndex]?.Context || [];
    const currentDescriptionDetails =
      selectedExperience?.Description[descriptionIndex]?.Details || [];

    const contextToBeMoved = descriptionContext[contextIndex];
    if (!contextToBeMoved.length) {
      setSnackBar({
        status: "error",
        message: "Le champ est vide, impossible de le déplacer",
        type: "error",
      });
      return;
    }
    if (contextIndex > 0) {
      const contextDestinationIndex = contextIndex - 1;

      const contextDestination = descriptionContext[contextDestinationIndex];
      const contextConcat = {
        target: { value: contextDestination.concat(` ${contextToBeMoved}`) },
      };

      selectUpdatedItem = updateSelectedContext(
        selectedExperience,
        descriptionIndex,
        contextDestinationIndex,
        contextConcat
      );
    } else {
      const destinationDescriptionIndex = descriptionIndex - 1;
      if (destinationDescriptionIndex == -1) {
        return;
      }
      const descriptionDetails =
        selectedExperience?.Description[destinationDescriptionIndex]?.Details ||
        [];

      if (descriptionDetails.length !== 0) {
        const detailDestinationIndex = descriptionDetails.length - 1;
        const detailDestination = descriptionDetails[detailDestinationIndex];
        const detailConcat = {
          target: { value: detailDestination.concat(` ${contextToBeMoved}`) },
        };
        selectUpdatedItem = updateSelectedDetail(
          selectedExperience,
          destinationDescriptionIndex,
          detailDestinationIndex,
          detailConcat
        );
      } else {
        selectUpdatedItem = addDetailToSelectedDescription(
          selectedExperience,
          destinationDescriptionIndex,
          contextToBeMoved
        );
      }
    }
    selectUpdatedItem = deleteSelectedContext(
      selectUpdatedItem,
      descriptionIndex,
      contextIndex
    );
    if (
      selectUpdatedItem?.Description[descriptionIndex]?.Details?.length == 0 &&
      selectUpdatedItem?.Description[descriptionIndex]?.Context?.length == 0
    ) {
      deleteDescriptionsByExperience(selectUpdatedItem, descriptionIndex);
    }
  };

  const onCreateExperience = (
    experience,
    descriptionIndex,
    originIndex,
    origin,
    selectedText
  ) => {
    if (!selectedText.length) {
      setSnackBar({
        status: true,
        message:
          "Sélectionner le début de la nouvelle expérience à créer (ex: premier mot, première phrase).",
        type: "info",
      });
      return;
    }

    const description = experience?.Description || [];
    const newDescription = description
      ?.map((currVal, index) => {
        if (descriptionIndex.toString() == index.toString()) {
          let descriptionElement =
            origin == "Details"
              ? { Context: [""], [origin]: currVal[origin] }
              : { ...currVal };

          const result = createNewDescriptionFromIndex(
            currVal[origin],
            originIndex,
            selectedText
          );

          if (typeof result == "string") {
            setSnackBar({
              status: true,
              message: "Erreur dans la création d'une nouvelle expérience",
              type: "error",
            });
          } else {
            descriptionElement[origin] = result;
            return descriptionElement;
          }
        } else if (descriptionIndex.toString() < index.toString()) {
          return currVal;
        }
      })
      .filter((e) => e);
    console.log("newDescription===>", newDescription);

    const newExperience = {
      Company: "",
      Period: "",
      Position: "",
      Technologies: [],
      Description: newDescription,
    };
    setSnackBar({
      status: true,
      message: "Une nouvelle expérience a été crée avec succés",
      type: "success",
    });
    setNewExperience(newExperience);
    setSelectedText("");
  };
  const onMoveUp = (selectedItem, descriptionIndex, originIndex, origin) => {
    if (origin == "Context") {
      moveUpContext(selectedItem, descriptionIndex, originIndex);
    }
    if (origin == "Details") {
      moveUpDetail(selectedItem, descriptionIndex, originIndex);
    }
  };
  const onMoveDown = (selectedItem, descriptionIndex, originIndex, origin) => {
    if (origin == "Context") {
      moveDownContext(selectedItem, descriptionIndex, originIndex);
    }
    if (origin == "Details") {
      moveDownDetail(selectedItem, descriptionIndex, originIndex);
    }
  };
  const moveUpDetail = (selectedExperience, descriptionIndex, detailIndex) => {
    let selectUpdatedItem = {};
    const descriptionDetail =
      selectedExperience?.Description[descriptionIndex]?.Details || [];

    const detailToBeMoved = descriptionDetail[detailIndex];
    if (!detailToBeMoved.length) {
      setSnackBar({
        status: "error",
        message: "Le champ est vide, impossible de le déplacer",
        type: "error",
      });
      return;
    }
    if (detailIndex > 0) {
      const detailDestinationIndex = detailIndex - 1;

      const detailDestination = descriptionDetail[detailDestinationIndex];
      const detailConcat = {
        target: { value: detailDestination.concat(` ${detailToBeMoved}`) },
      };

      selectUpdatedItem = updateSelectedDetail(
        selectedExperience,
        descriptionIndex,
        detailDestinationIndex,
        detailConcat
      );
    } else {
      const descriptionContext =
        selectedExperience?.Description[descriptionIndex]?.Context || [];
      if (descriptionContext.length !== 0) {
        const contextDestinationIndex = descriptionContext.length - 1;
        const contextDestination = descriptionContext[contextDestinationIndex];
        const contextConcat = {
          target: { value: contextDestination.concat(` ${detailToBeMoved}`) },
        };
        selectUpdatedItem = updateSelectedContext(
          selectedExperience,
          descriptionIndex,
          contextDestinationIndex,
          contextConcat
        );
      } else {
        selectUpdatedItem = addContextToSelectedDescription(
          selectedExperience,
          descriptionIndex,
          detailToBeMoved
        );
      }
    }
    deleteSelectedDetail(selectUpdatedItem, descriptionIndex, detailIndex);
  };
  const moveDownContext = (
    selectedExperience,
    descriptionIndex,
    contextIndex
  ) => {
    let selectUpdatedItem = {};
    const descriptionContext =
      selectedExperience?.Description[descriptionIndex]?.Context || [];
    const contextToBeMoved = descriptionContext[contextIndex];
    if (!contextToBeMoved.length) {
      setSnackBar({
        status: "error",
        message: "Le champ est vide, impossible de le déplacer",
        type: "error",
      });
      return;
    }
    if (contextIndex < descriptionContext.length - 1) {
      const contextDestinationIndex = contextIndex + 1;

      const contextDestination = descriptionContext[contextDestinationIndex];
      const contextConcat = {
        target: { value: contextToBeMoved.concat(` ${contextDestination}`) },
      };
      selectUpdatedItem = updateSelectedContext(
        selectedExperience,
        descriptionIndex,
        contextDestinationIndex,
        contextConcat
      );
    } else {
      const descriptionDetails =
        selectedExperience?.Description[descriptionIndex]?.Details || [];
      if (descriptionDetails.length !== 0) {
        const detailDestinationIndex = 0;
        const detailDestination = descriptionDetails[detailDestinationIndex];
        const detailConcat = {
          target: { value: contextToBeMoved.concat(` ${detailDestination}`) },
        };
        selectUpdatedItem = updateSelectedDetail(
          selectedExperience,
          descriptionIndex,
          detailDestinationIndex,
          detailConcat
        );
      } else {
        selectUpdatedItem = addDetailToSelectedDescription(
          selectedExperience,
          descriptionIndex,
          contextToBeMoved
        );
      }
    }
    deleteSelectedContext(selectUpdatedItem, descriptionIndex, contextIndex);
  };
  const moveDownDetail = (
    selectedExperience,
    descriptionIndex,
    detailIndex
  ) => {
    let selectUpdatedItem = {};
    const descriptionDetails =
      selectedExperience?.Description[descriptionIndex]?.Details || [];
    const detailToBeMoved = descriptionDetails[detailIndex];
    if (!detailToBeMoved.length) {
      setSnackBar({
        status: "error",
        message: "Le champ est vide, impossible de le déplacer",
        type: "error",
      });
      return;
    }
    if (detailIndex < descriptionDetails.length - 1) {
      const detailDestinationIndex = detailIndex + 1;

      const detailDestination = descriptionDetails[detailDestinationIndex];
      const detailConcat = {
        target: { value: detailToBeMoved.concat(` ${detailDestination}`) },
      };
      selectUpdatedItem = updateSelectedDetail(
        selectedExperience,
        descriptionIndex,
        detailDestinationIndex,
        detailConcat
      );
    } else {
      const descriptionDestinationIndex = parseInt(descriptionIndex) + 1;
      const descriptionContext =
        selectedExperience?.Description[descriptionDestinationIndex]?.Context ||
        [];
      if (descriptionIndex == selectedExperience?.Description.length - 1) {
        return;
      }
      if (descriptionContext.length != 0) {
        const contextDestinationIndex = 0;
        const contextDestination = descriptionContext[contextDestinationIndex];
        const contextConcat = {
          target: { value: detailToBeMoved.concat(` ${contextDestination}`) },
        };

        selectUpdatedItem = updateSelectedContext(
          selectedExperience,
          descriptionDestinationIndex.toString(),
          contextDestinationIndex,
          contextConcat
        );
      } else {
        selectUpdatedItem = addContextToSelectedDescription(
          selectedExperience,
          descriptionDestinationIndex.toString(),
          detailToBeMoved
        );
      }
    }
    selectUpdatedItem = deleteSelectedDetail(
      selectUpdatedItem,
      descriptionIndex,
      detailIndex
    );
    if (
      selectUpdatedItem?.Description[descriptionIndex]?.Details?.length == 0 &&
      selectUpdatedItem?.Description[descriptionIndex]?.Context?.length == 0
    ) {
      deleteDescriptionsByExperience(selectUpdatedItem, descriptionIndex);
    }
  };

  const addDetailToSelectedDescription = (
    selectedExperience,
    descriptionIndex,
    detailElement = ""
  ) => {
    const newDescription = addDetail(
      selectedExperience,
      descriptionIndex,
      detailElement
    );
    const updatedSelectExperience = {
      ...selectedExperience,
      Description: newDescription,
    };
    setSelectedItem(updatedSelectExperience);
    return updatedSelectExperience;
  };

  const updateSelectedDetail = (
    selectedExperience,
    descriptionIndex,
    detailIndex,
    event
  ) => {
    const updatedSelectedDetail = updateSelectedDetailInEditModalExperirnce(
      selectedExperience,
      descriptionIndex,
      detailIndex,
      event
    );

    setSelectedItem(updatedSelectedDetail);
    return updatedSelectedDetail;
  };

  const deleteSelectedDetail = (
    selectedExperience,
    descriptionIndex,
    detailIndex
  ) => {
    const filtredDetail = deleteSelectedDetailInEditModalExperirnce(
      selectedExperience,
      descriptionIndex,
      detailIndex
    );
    setSelectedItem(filtredDetail);
    return filtredDetail;
  };

  const onDragEnd = ({ result, selectedType, descriptionIndex }) => {
    if (!result.destination) {
      return;
    }
    const finalEditedOrderExperienceParams =
      getFinalEditedOrderExperienceParams({
        selectedItem,
        result,
        selectedType,
        descriptionIndex,
      });
    setSelectedItem(finalEditedOrderExperienceParams);
  };

  const handleEditExperienceButton = () => {
    const oldExperience = dataCollection.Experiences || [];
    const newExperienceList = oldExperience?.map((expElem, index) => {
      if (index.toString() === experienceId.toString()) {
        return selectedItem;
      } else {
        return expElem;
      }
    });
    const newExperiences =
      newExperience !== null
        ? [...newExperienceList, newExperience]
        : newExperienceList;

    dispatch(setExperiences(newExperiences));

    handleClose("save");
  };

  const onAddTechnologieFocus = () => {
    if (selectedItem?.Technologies.length > 0) {
      technologiesRefs.current[selectedItem.Technologies.length - 1].focus();
    }
  };
  const onAddDescriptionFocus = () => {
    if (selectedItem?.Description.length > 0) {
      const lastDescriptionRef =
        descriptionsRefs.current[selectedItem?.Description.length - 1];
      if (lastDescriptionRef) {
        lastDescriptionRef.scrollIntoView({ behavior: "smooth" });
        lastDescriptionRef.focus();
      }
    }
  };

  useEffect(() => {
    if (show) {
      setDescriptionRowInfo({
        descriptionIndex: 0,
        originIndex: 0,
        origin: "",
        showPalette: false,
      });
      setNewExperience(null);
    }
  }, [show]);

  return (
    <Modal
      dialogClassName="modal-xl"
      show={show}
      onHide={() => handleClose("reset")}
    >
      <Modal.Header closeButton style={styles.header}>
        <Modal.Title style={styles.title}>
          <img src={experienceIllustration} style={styles.illustration} />
          <h2>Experiences</h2>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          {dataCollection?.Experiences != null ? (
            <div>
              <div>
                <Form.Group className="mb-1">
                  {/* Position */}

                  <Grid mb={1}>
                    <TextField
                      fullWidth
                      helperText="Entrez votre position actuelle ou désirée"
                      label="Position"
                      variant="outlined"
                      autoFocus
                      xm={1}
                      size="sm"
                      type="text"
                      value={selectedItem?.Position}
                      onChange={(e) =>
                        handleExperience(e, selectedItem, "Position")
                      }
                    />
                  </Grid>
                </Form.Group>
                {/* Entreprise */}
                <Form.Group
                  className="mb-1"
                  controlId="exampleForm.ControlInput1"
                >
                  <Grid mb={1}>
                    <TextField
                      fullWidth
                      helperText="Entrez le nom d'entrprise"
                      label="Entreprise"
                      variant="outlined"
                      xm={1}
                      size="sm"
                      type="text"
                      value={selectedItem?.Company}
                      onChange={(e) =>
                        handleExperience(e, selectedItem, "Company")
                      }
                    />
                  </Grid>
                </Form.Group>
                {/* Période */}
                <Form.Group
                  className="mb-1"
                  controlId="exampleForm.ControlInput1"
                >
                  <Grid mb={1}>
                    <TextField
                      fullWidth
                      helperText="Entrez la période désirée"
                      label="période"
                      variant="outlined"
                      xm={1}
                      size="sm"
                      type="text"
                      value={selectedItem?.Period}
                      onChange={(e) =>
                        handleExperience(e, selectedItem, "Period")
                      }
                    />
                  </Grid>
                </Form.Group>
                {/* Technologies */}
                <div style={styles.separator}></div>

                <div className="col-md-12">
                  <Form.Group
                    className="mb-1"
                    controlId="exampleForm.ControlInput1"
                  >
                    {selectedItem?.Technologies ? (
                      <div>
                        <div style={styles.technologieHeader}>
                          <div style={{ paddingTop: "5px" }}>
                            <span style={styles.technologieTitle}>
                              Technologies:
                            </span>
                          </div>
                          <div>
                            <Grid>
                              <Button
                                onClick={(e) => {
                                  addForComplexType(
                                    e,
                                    selectedItem,
                                    "Technologies"
                                  );
                                  onAddTechnologieFocus();
                                }}
                                style={styles.addTechnologieButton}
                              >
                                <AddCircleOutlineIcon fontSize="small" />
                                <span style={styles.addTechnologieButtonTitle}>
                                  Ajouter
                                </span>
                              </Button>
                            </Grid>
                          </div>
                        </div>

                        <DragDropContext
                          onDragEnd={(result) =>
                            onDragEnd({
                              result,
                              selectedType: "technologies",
                            })
                          }
                        >
                          <Droppable droppableId="list">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {Object.entries(selectedItem.Technologies).map(
                                  ([key, value]) => (
                                    <Draggable
                                      key={key}
                                      draggableId={key.toString()}
                                      index={parseInt(key)}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <div
                                            style={styles.technologiesContainer}
                                            key={key}
                                          >
                                            <div className="col-11 col-md-11">
                                              <Grid mb={1}>
                                                <input
                                                  type="text"
                                                  list="techList"
                                                  ref={(el) =>
                                                    (technologiesRefs.current[
                                                      key
                                                    ] = el)
                                                  }
                                                  value={value}
                                                  placeholder="technologie"
                                                  onChange={(e) =>
                                                    handleExperienceComplexeType(
                                                      e,
                                                      key,
                                                      selectedItem,
                                                      "Technologies"
                                                    )
                                                  }
                                                  style={
                                                    styles.technologieInput
                                                  }
                                                />
                                                <datalist id="techList">
                                                  {parametersTechno.map(
                                                    (param) => (
                                                      <option
                                                        key={param.id}
                                                        value={param.value}
                                                      />
                                                    )
                                                  )}
                                                </datalist>
                                              </Grid>
                                            </div>

                                            <div className="col-1 col-md-1">
                                              <Grid mb={1}>
                                                <Button
                                                  onClick={() => {
                                                    deleteComplexType(
                                                      key,
                                                      selectedItem,
                                                      "Technologies"
                                                    );
                                                  }}
                                                >
                                                  <span
                                                    style={
                                                      styles.deleteButtonContainer
                                                    }
                                                  >
                                                    <DeleteOutlinedIcon
                                                      style={
                                                        styles.deleteButton
                                                      }
                                                    />
                                                  </span>
                                                </Button>
                                              </Grid>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div style={styles.separator}></div>
              <div>
                <Form.Group className="mb-1 mt-3">
                  {/* Description  */}
                  <div style={styles.descriptionHeader}>
                    <div style={styles.descriptionTitleContainer}>
                      <Form.Label size="sm">
                        <span style={styles.descriptionTitle}>
                          Descriptions:
                        </span>
                      </Form.Label>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          addInputDescriptionsByExperience(selectedItem);
                          onAddDescriptionFocus();
                        }}
                        style={styles.addDescriptionButtonContainer}
                      >
                        <AddCircleOutlineIcon fontSize="small" />
                        <span style={styles.addDescriptionButtonTitle}>
                          Ajouter
                        </span>
                      </Button>
                    </div>
                  </div>

                  {selectedItem?.Description ? (
                    <div>
                      <DragDropContext
                        onDragEnd={(result) =>
                          onDragEnd({ result, selectedType: "description" })
                        }
                      >
                        <Droppable droppableId="list1">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {Object.entries(selectedItem?.Description).map(
                                ([key, value], index) => {
                                  return (
                                    <Draggable
                                      key={key}
                                      draggableId={key.toString()}
                                      index={parseInt(key)}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={
                                            styles.descriptionCardContainer
                                          }
                                        >
                                          <div className="col-md-12" key={key}>
                                            <Card>
                                              <div
                                                className="row"
                                                ref={(el) =>
                                                  (descriptionsRefs.current[
                                                    key
                                                  ] = el)
                                                }
                                              >
                                                <div className="col-10 col-md-10 col-lg-11"></div>
                                                <div
                                                  className="col-2 col-md-2 col-lg-1"
                                                  style={
                                                    styles.deleteDescriptionCard
                                                  }
                                                >
                                                  <DeleteButton
                                                    handleClick={() =>
                                                      deleteDescriptionsByExperience(
                                                        selectedItem,
                                                        key
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <CardContent>
                                                <div
                                                  className="card-number"
                                                  style={
                                                    styles.descriptionCardNumber
                                                  }
                                                >
                                                  {index + 1}
                                                </div>

                                                <Grid mt={1} mb={1}>
                                                  <div className="row">
                                                    <div>
                                                      <Grid mb={2}>
                                                        <Button
                                                          onClick={() =>
                                                            addContextToSelectedDescription(
                                                              selectedItem,
                                                              key
                                                            )
                                                          }
                                                          sx={{
                                                            bgcolor: "#a9bef0",
                                                            color: "#003366",
                                                          }}
                                                          _hover={{
                                                            color: "#000099",
                                                          }}
                                                          _active={{
                                                            color: "#000066",
                                                          }}
                                                          _focus={{
                                                            color: "#0000ff",
                                                          }}
                                                        >
                                                          <AddCircleOutlineIcon fontSize="extraSmall" />
                                                          <span
                                                            style={
                                                              styles.addContextButtonTitle
                                                            }
                                                          >
                                                            Ajouter Context
                                                          </span>
                                                        </Button>
                                                      </Grid>
                                                    </div>
                                                  </div>
                                                  <DragDropContext
                                                    onDragEnd={(result) =>
                                                      onDragEnd({
                                                        result,
                                                        selectedType:
                                                          "descriptionContext",
                                                        descriptionIndex: index,
                                                      })
                                                    }
                                                  >
                                                    <Droppable droppableId="list2">
                                                      {(provided, snapshot) => (
                                                        <div
                                                          {...provided.droppableProps}
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                        >
                                                          {value.Context?.map(
                                                            (det, keyc) => (
                                                              <Draggable
                                                                key={keyc}
                                                                draggableId={keyc.toString()}
                                                                index={parseInt(
                                                                  keyc
                                                                )}
                                                              >
                                                                {(
                                                                  provided,
                                                                  snapshot
                                                                ) => (
                                                                  <div
                                                                    ref={
                                                                      provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                  >
                                                                    <div>
                                                                      {descriptionRowInfo.showPalette &&
                                                                        descriptionRowInfo?.origin ==
                                                                          "Context" &&
                                                                        keyc ==
                                                                          descriptionRowInfo?.originIndex &&
                                                                        descriptionRowInfo?.descriptionIndex ==
                                                                          key && (
                                                                          <div className="row">
                                                                            <div className="col-0 col-sm-0 col-md-0 col-lg-4" />
                                                                            <div className="col-10 col-sm-10 col-md-11 col-lg-4">
                                                                              <ExperienceTools
                                                                                type="update"
                                                                                onMoveToTechnologies={() =>
                                                                                  moveToTechnologiesSection(
                                                                                    selectedItem
                                                                                  )
                                                                                }
                                                                                onDeleteRow={() =>
                                                                                  deleteSelectedContext(
                                                                                    selectedItem,
                                                                                    descriptionRowInfo.descriptionIndex,
                                                                                    descriptionRowInfo.originIndex
                                                                                  )
                                                                                }
                                                                                onMoveUp={() =>
                                                                                  onMoveUp(
                                                                                    selectedItem,
                                                                                    descriptionRowInfo.descriptionIndex,
                                                                                    descriptionRowInfo.originIndex,
                                                                                    descriptionRowInfo.origin
                                                                                  )
                                                                                }
                                                                                onMoveDown={() =>
                                                                                  onMoveDown(
                                                                                    selectedItem,
                                                                                    descriptionRowInfo.descriptionIndex,
                                                                                    descriptionRowInfo.originIndex,
                                                                                    descriptionRowInfo.origin
                                                                                  )
                                                                                }
                                                                                onCreateExperience={() =>
                                                                                  onCreateExperience(
                                                                                    selectedItem,
                                                                                    descriptionRowInfo.descriptionIndex,
                                                                                    descriptionRowInfo.originIndex,
                                                                                    descriptionRowInfo.origin,
                                                                                    selectedText
                                                                                  )
                                                                                }
                                                                              />
                                                                            </div>
                                                                            <div className="col-2 col-sm-2 col-md-1 col-lg-4" />
                                                                          </div>
                                                                        )}
                                                                      <div
                                                                        className="row"
                                                                        style={{
                                                                          paddingTop:
                                                                            "5px",
                                                                        }}
                                                                        key={
                                                                          keyc
                                                                        }
                                                                      >
                                                                        <div className=" col-10 col-md-11 ">
                                                                          <TextareaAutosize
                                                                            ref={(
                                                                              el
                                                                            ) => {
                                                                              if (
                                                                                !textAreaContextRefs
                                                                                  .current[
                                                                                  key
                                                                                ]
                                                                              ) {
                                                                                textAreaContextRefs.current[
                                                                                  key
                                                                                ] =
                                                                                  {};
                                                                              }
                                                                              textAreaContextRefs.current[
                                                                                key
                                                                              ][
                                                                                keyc
                                                                              ] =
                                                                                el;
                                                                            }}
                                                                            style={
                                                                              styles.contextInput
                                                                            }
                                                                            minRows={
                                                                              3
                                                                            }
                                                                            type="text"
                                                                            value={
                                                                              det
                                                                            }
                                                                            placeholder="Context"
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              updateSelectedContext(
                                                                                selectedItem,
                                                                                key,
                                                                                keyc,
                                                                                e
                                                                              )
                                                                            }
                                                                            onMouseUp={() =>
                                                                              handleTextSelection(
                                                                                key,
                                                                                keyc,
                                                                                "Context"
                                                                              )
                                                                            }
                                                                            onFocus={() => {
                                                                              setDescriptionRowInfo(
                                                                                {
                                                                                  ...descriptionRowInfo,
                                                                                  showPalette: true,
                                                                                  descriptionIndex:
                                                                                    key,
                                                                                  origin:
                                                                                    "Context",
                                                                                  originIndex:
                                                                                    keyc,
                                                                                }
                                                                              );
                                                                            }}
                                                                          />
                                                                        </div>
                                                                        <div
                                                                          className=" col-1 col-md-1"
                                                                          style={{
                                                                            display:
                                                                              "flex",
                                                                            alignItems:
                                                                              "center",
                                                                            justifyContent:
                                                                              "center",
                                                                          }}
                                                                        >
                                                                          <Grid
                                                                            mb={
                                                                              1
                                                                            }
                                                                          >
                                                                            <Button
                                                                              onClick={() => {
                                                                                const showPaletteValue =
                                                                                  keyc ==
                                                                                  descriptionRowInfo?.originIndex
                                                                                    ? !descriptionRowInfo?.showPalette
                                                                                    : true;
                                                                                setDescriptionRowInfo(
                                                                                  {
                                                                                    showPalette:
                                                                                      showPaletteValue,
                                                                                    descriptionIndex:
                                                                                      key,
                                                                                    originIndex:
                                                                                      keyc,
                                                                                    origin:
                                                                                      "Context",
                                                                                  }
                                                                                );
                                                                              }}
                                                                            >
                                                                              <Tooltip
                                                                                title={
                                                                                  "Outils"
                                                                                }
                                                                              >
                                                                                <HandymanIcon
                                                                                  style={{
                                                                                    color:
                                                                                      "#200A5F",
                                                                                  }}
                                                                                />
                                                                              </Tooltip>
                                                                            </Button>
                                                                          </Grid>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                )}
                                                              </Draggable>
                                                            )
                                                          )}
                                                          {provided.placeholder}
                                                        </div>
                                                      )}
                                                    </Droppable>
                                                  </DragDropContext>
                                                </Grid>
                                                <Stack>
                                                  <div className="row">
                                                    <div>
                                                      <Grid mb={2}>
                                                        <Button
                                                          onClick={(e) =>
                                                            addDetailToSelectedDescription(
                                                              selectedItem,
                                                              key
                                                              // e
                                                            )
                                                          }
                                                          sx={{
                                                            bgcolor: "#a9bef0",
                                                            color: "#003366",
                                                          }}
                                                          _hover={{
                                                            color: "#000099",
                                                          }}
                                                          _active={{
                                                            color: "#000066",
                                                          }}
                                                          _focus={{
                                                            color: "#0000ff",
                                                          }}
                                                        >
                                                          <AddCircleOutlineIcon fontSize="extraSmall" />
                                                          <span
                                                            style={
                                                              styles.addDetailButtonTitle
                                                            }
                                                          >
                                                            Ajouter details
                                                          </span>
                                                        </Button>
                                                      </Grid>
                                                    </div>
                                                  </div>
                                                  <DragDropContext
                                                    onDragEnd={(result) =>
                                                      onDragEnd({
                                                        result,
                                                        selectedType:
                                                          "descriptionDetail",
                                                        descriptionIndex: index,
                                                      })
                                                    }
                                                  >
                                                    <Droppable droppableId="list3">
                                                      {(provided, snapshot) => (
                                                        <div
                                                          {...provided.droppableProps}
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                        >
                                                          {value.Details?.map(
                                                            (det, keyd) => (
                                                              <Draggable
                                                                key={keyd}
                                                                draggableId={keyd.toString()}
                                                                index={parseInt(
                                                                  keyd
                                                                )}
                                                              >
                                                                {(
                                                                  provided,
                                                                  snapshot
                                                                ) => (
                                                                  <div
                                                                    ref={
                                                                      provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                  >
                                                                    <div>
                                                                      {descriptionRowInfo?.showPalette &&
                                                                        descriptionRowInfo?.origin ==
                                                                          "Details" &&
                                                                        keyd ==
                                                                          descriptionRowInfo?.originIndex &&
                                                                        descriptionRowInfo?.descriptionIndex ==
                                                                          key && (
                                                                          <div className="row">
                                                                            <div className="col-0 col-sm-0 col-md-0 col-lg-4" />
                                                                            <div className="col-10 col-sm-10 col-md-11 col-lg-4">
                                                                              <ExperienceTools
                                                                                type="update"
                                                                                onMoveToTechnologies={() =>
                                                                                  moveToTechnologiesSection(
                                                                                    selectedItem
                                                                                  )
                                                                                }
                                                                                onDeleteRow={() =>
                                                                                  deleteSelectedDetail(
                                                                                    selectedItem,
                                                                                    descriptionRowInfo.descriptionIndex,
                                                                                    descriptionRowInfo.originIndex
                                                                                  )
                                                                                }
                                                                                onMoveUp={() =>
                                                                                  onMoveUp(
                                                                                    selectedItem,
                                                                                    descriptionRowInfo.descriptionIndex,
                                                                                    descriptionRowInfo.originIndex,
                                                                                    descriptionRowInfo.origin
                                                                                  )
                                                                                }
                                                                                onMoveDown={() =>
                                                                                  onMoveDown(
                                                                                    selectedItem,
                                                                                    descriptionRowInfo.descriptionIndex,
                                                                                    descriptionRowInfo.originIndex,
                                                                                    descriptionRowInfo.origin
                                                                                  )
                                                                                }
                                                                                onCreateExperience={() =>
                                                                                  onCreateExperience(
                                                                                    selectedItem,
                                                                                    descriptionRowInfo.descriptionIndex,
                                                                                    descriptionRowInfo.originIndex,
                                                                                    descriptionRowInfo.origin,
                                                                                    selectedText
                                                                                  )
                                                                                }
                                                                              />
                                                                            </div>
                                                                            <div className="col-2 col-sm-2 col-md-1 col-lg-4" />
                                                                          </div>
                                                                        )}
                                                                      <div
                                                                        className="row"
                                                                        style={{
                                                                          paddingTop:
                                                                            "5px",
                                                                        }}
                                                                        key={
                                                                          keyd
                                                                        }
                                                                      >
                                                                        <div className="col-10 col-md-11">
                                                                          <TextareaAutosize
                                                                            style={
                                                                              styles.detailInput
                                                                            }
                                                                            minRows={
                                                                              3
                                                                            }
                                                                            type="text"
                                                                            value={
                                                                              det
                                                                            }
                                                                            placeholder="detail"
                                                                            ref={(
                                                                              el
                                                                            ) => {
                                                                              if (
                                                                                !textAreaDetailsRefs
                                                                                  .current[
                                                                                  key
                                                                                ]
                                                                              ) {
                                                                                textAreaDetailsRefs.current[
                                                                                  key
                                                                                ] =
                                                                                  {};
                                                                              }
                                                                              textAreaDetailsRefs.current[
                                                                                key
                                                                              ][
                                                                                keyd
                                                                              ] =
                                                                                el;
                                                                            }}
                                                                            onMouseUp={() =>
                                                                              handleTextSelection(
                                                                                key,
                                                                                keyd,
                                                                                "Details"
                                                                              )
                                                                            }
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              updateSelectedDetail(
                                                                                selectedItem,
                                                                                key,
                                                                                keyd,
                                                                                e
                                                                              )
                                                                            }
                                                                            onFocus={() => {
                                                                              setDescriptionRowInfo(
                                                                                {
                                                                                  ...descriptionRowInfo,
                                                                                  showPalette: true,
                                                                                  descriptionIndex:
                                                                                    key,
                                                                                  origin:
                                                                                    "Details",
                                                                                  originIndex:
                                                                                    keyd,
                                                                                }
                                                                              );
                                                                            }}
                                                                          />
                                                                        </div>
                                                                        <div
                                                                          className=" col-1 col-md-1"
                                                                          style={{
                                                                            display:
                                                                              "flex",
                                                                            alignItems:
                                                                              "center",
                                                                            justifyContent:
                                                                              "center",
                                                                          }}
                                                                        >
                                                                          <Grid
                                                                            mb={
                                                                              1
                                                                            }
                                                                          >
                                                                            <Button
                                                                              onClick={() => {
                                                                                const showPaletteValue =
                                                                                  keyd ==
                                                                                  descriptionRowInfo?.originIndex
                                                                                    ? !descriptionRowInfo?.showPalette
                                                                                    : true;
                                                                                setDescriptionRowInfo(
                                                                                  {
                                                                                    showPalette:
                                                                                      showPaletteValue,
                                                                                    descriptionIndex:
                                                                                      key,
                                                                                    originIndex:
                                                                                      keyd,
                                                                                    origin:
                                                                                      "Details",
                                                                                  }
                                                                                );
                                                                              }}
                                                                            >
                                                                              <Tooltip
                                                                                title={
                                                                                  "Outils"
                                                                                }
                                                                              >
                                                                                <HandymanIcon
                                                                                  style={{
                                                                                    color:
                                                                                      "#200A5F",
                                                                                  }}
                                                                                />
                                                                              </Tooltip>
                                                                            </Button>
                                                                          </Grid>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                )}
                                                              </Draggable>
                                                            )
                                                          )}
                                                          {provided.placeholder}
                                                        </div>
                                                      )}
                                                    </Droppable>
                                                  </DragDropContext>
                                                </Stack>
                                              </CardContent>
                                            </Card>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Form.Group>
              </div>
            </div>
          ) : (
            <div> </div>
          )}
        </Form>
        {snackBar?.status && (
          <SnackBar
            openSnack={snackBar?.status}
            message={snackBar?.message}
            setOpenSnack={(val) => setSnackBar(val)}
            type={snackBar?.type}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="col-xs-3"></div>

        <Button
          style={styles.submitButton}
          onClick={handleEditExperienceButton}
        >
          Enregistrer Changements
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
