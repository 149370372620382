import {
  RESET_CONTACT_FOOTER,
  SET_CONTACT_FOOTER,
} from "../actions/contactFooterAction";

const initialState = {
  Adresse: {
    Content: "143 Bureaux de la Colline 92213 Saint-Cloud Cedex France",
  },
  telephoneOne: { Content: "Tél. : +33 1 82 88 39 39" },
  telephoneTwo: { Content: "Fax : +33 1 82 88 39 40" },
  EntrepriseType: { Content: "RCS de NANTERRE n°499 197 986 " },
  PublicRegister: { Content: "SARL au capital de 100 500 €" },
  Mail: { Content: "contact@aptea.net" },
  InternetLink: { Content: "https://www.aptea.net/" },
  NomEntreprise: { Content: "© APTEA" },
  CouleurLien: { Content: "#d11fb6" },
};

const contactFooterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTACT_FOOTER:
      return {
        ...state,
        ...action.payload.data,
      };
    case RESET_CONTACT_FOOTER:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default contactFooterReducer;
