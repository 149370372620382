import {
  SET_AVTIVE_STEP,
  SET_NEXT_BUTTON_STATUS,
  RESET_STEPPER,
} from "../actions/stepperAction";

const initialState = {
  activeStep: 0,
  nextButtonStatus: true,
};

const stepperReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AVTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload.activeStep,
      };
    case SET_NEXT_BUTTON_STATUS:
      return {
        ...state,
        nextButtonStatus: action.payload.nextButtonStatus,
      };
    case RESET_STEPPER:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default stepperReducer;
