export const SET_LOGGED_IN_NOTIF = 'SET_LOGGED_IN_NOTIF';
export const SET_LOGGED_OUT_NOTIF = 'SET_LOGGED_OUT_NOTIF';
export const SET_PASSWORD_RESET_NOTIF = 'SET_PASSWORD_RESET_NOTIF';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';

export const setLoggedInNotif = (data) => ({
  type: SET_LOGGED_IN_NOTIF,
  payload: { data }
});
export const setLoggedOutNotif = (data) => ({
  type: SET_LOGGED_OUT_NOTIF,
  payload: { data }
});
export const setPasswordResetNotif = (data) => ({
  type: SET_PASSWORD_RESET_NOTIF,
  payload: { data }
});
export const resetNotification = () => ({
  type: RESET_NOTIFICATION,
  payload: {}
});
