export const SET_CONTACT_FOOTER = "SET_CONTACT_FOOTER";
export const RESET_CONTACT_FOOTER = "RESET_CONTACT_FOOTER";

export const setContactFooter = (data) => ({
  type: SET_CONTACT_FOOTER,
  payload: { data },
});

export const resetContactFooter = () => ({
  type: RESET_CONTACT_FOOTER,
  payload: {},
});
