export const styles = {
  headerContact: { bgcolor: "#f3f4fa" },
  headerTitleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitle: {
    fontstyle: "oblique",
    fontWeight: "bold",
    paddingTop: "30px",
  },
  editButton: {
    maxHeight: "2.3em",
    minHeight: "2.0em",
    minWidth: "2em",
    fontSize: ".85em",
    textTransform: "capitalize",
    border: "2px solid transparent",
    borderColor: "#DA2889",
    color: "#DA2889",
    fontweight: "bold",
    backgroundColor: "white",
    borderRadius: "5px",
  },
  editTitleContainer: {
    display: "flex ",
    justifyContent: "space-between",
    alignItems: "center",
  },
  collapsButton: {
    maxHeight: "2.3em",
    minHeight: "2.0em",
    minWidth: "2em",
    fontSize: ".85em",
    textTransform: "capitalize",
    border: "2px solid transparent",
    borderColor: "#1976d2",
    color: "#1976d2",
    fontweight: "bold",
    backgroundColor: "white",
    borderRadius: "5px",
  },
  collapsButtonContainer: {
    display: "flex ",
    justifyContent: "space-between",
    alignItems: "center",
  },
  nameLabel: {
    fontSize: "small",
    justifyContent: "start",
    fontstyle: "oblique",
    fontWeight: "bold",
  },
  nameValue: {
    fontSize: "small",
    justifyContent: "start",
  },
  telLabel: {
    fontSize: "small",
    justifyContent: "start",
    fontstyle: "oblique",
    fontWeight: "bold",
  },
  emailLabel: {
    fontSize: "small",
    justifyContent: "start",
    fontstyle: "oblique",
    fontWeight: "bold",
  },
  emailValue: {
    fontSize: "small",
    justifyContent: "start",
  },
  roleLabel: {
    fontSize: "small",
    justifyContent: "start",
    fontstyle: "oblique",
    fontWeight: "bold",
  },
  roleValue: {
    fontSize: "small",
    justifyContent: "start",
  },
  experienceNumberLabel: {
    fontSize: "small",
    justifyContent: "start",
    fontstyle: "oblique",
    fontWeight: "bold",
  },
  experienceNumberValue: {
    fontSize: "small",
    justifyContent: "start",
  },
  addressLabel: {
    fontSize: "small",
    justifyContent: "left",
    fontstyle: "oblique",
    fontWeight: "bold",
  },
  addressValue: {
    fontSize: "small",
    justifyContent: "start",
  },
  linkedInLabel: {
    fontSize: "small",
    justifyContent: "start",
    fontstyle: "oblique",
    fontWeight: "bold",
  },
  linkedInValue: {
    fontSize: "small",
    justifyContent: "start",
    fontstyle: "oblique",
    fontWeight: "bold",
  },
};
