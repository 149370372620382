import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Box, Paper, Grid } from "@mui/material";
import { Document, Page } from "react-pdf";
import pdfFile from "../Template/CV Modèle APTEA.pdf";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const TemplateGuide = () => {
  const [scale, setScale] = useState(1.0);
  const containerRef = useRef(null);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const updateScale = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const desiredWidth = containerWidth * 0.8;
        const defaultWidth = 600;
        const newScale = desiredWidth / defaultWidth;
        setScale(newScale);
      }
    };

    updateScale();

    window.addEventListener("resize", updateScale);
    return () => window.removeEventListener("resize", updateScale);
  }, []);

  return (
    <Container sx={{ my: 3 }} ref={containerRef}>
      <Typography variant="h4" gutterBottom>
        Guide de création de template
      </Typography>
      <Box>
        <Typography variant="h5" gutterBottom>
          Document Word
        </Typography>
        <Typography variant="body1" paragraph>
          Le template est un document Word dans lequel sont placées des balises permettant d'intégrer les éléments extraits du CV.
          Un template par défaut est utilisé dans CVerter, mais il est possible d'utiliser son propre template dans la version premium de l'application.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Exemple de Balises
        </Typography>
        <Typography variant="body1" paragraph>
          Il existe plusieurs types de balises :
        </Typography>
        <Paper elevation={3} style={{ padding: "16px" }}>
          <pre style={{ margin: "0px" }}>
            {`{{ variable }} - pour afficher une variable
{% if condition %} - pour une condition
{% for item in list %} - pour une boucle`}
          </pre>
        </Paper>
        <Typography variant="body1" paragraph mt={2}>
          Les balises utilisables pour afficher les différentes données extraites sont les suivantes :
        </Typography>

        <Grid container spacing={2} mb={2}>
          <Grid item>
            <Paper elevation={3} style={{ padding: "8px" }}>
              <pre style={{ margin: "0px" }}>
                {`{{ profilePicture }}`}
              </pre>
            </Paper>
          </Grid>

          <Grid item>
            <Paper elevation={3} style={{ padding: "8px" }}>
              <pre style={{ margin: "0px" }}>
                {`{{ Name.Content }}`}
              </pre>
            </Paper>
          </Grid>
          
          <Grid item>
            <Paper elevation={3} style={{ padding: "8px" }}>
              <pre style={{ margin: "0px" }}>
                {`{{ Profile.Content }}`}
              </pre>
            </Paper>
          </Grid>
          
          <Grid item>
            <Paper elevation={3} style={{ padding: "8px" }}>
              <pre style={{ margin: "0px" }}>
                {`{{ YearsOfExperience.Content }}`}
              </pre>
            </Paper>
          </Grid>

          

        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Paper elevation={3} style={{ padding: "8px" }}>
              <pre style={{ margin: "0px" }}>
                {`{%tr for item in Skills %}
{{ item.Name }}	{% for skill in item.Skills %}{{ skill }}, {% endfor %}
{%tr endfor %}`}
              </pre>
            </Paper>
          </Grid>

          <Grid item>
            <Paper elevation={3} style={{ padding: "8px" }}>
              <pre style={{ margin: "0px" }}>
                {`{%tr for item in Education %}
{{ item.Period }}	{% if item.Institute %}{{ item.Institute }}, 
                        {% endif %}{{ item.Title }}
{%tr endfor %}`}
              </pre>
            </Paper>
          </Grid>

          <Grid item>
            <Paper elevation={3} style={{ padding: "8px" }}>
              <pre style={{ margin: "0px" }}>
                {`{%tr for item in Languages %}
{{ item.Language }}	{{ item.Level }}
{%tr endfor %}`}
              </pre>
            </Paper>
          </Grid>

          <Grid item>
            <Paper elevation={3} style={{ padding: "8px" }}>
              <pre style={{ margin: "0px" }}>
                {`{%tr for item in Hobbies %}
{{ item.Name }}	
{%tr endfor %}`}
              </pre>
            </Paper>
          </Grid>

          <Grid item>
            <Paper elevation={3} style={{ padding: "8px" }}>
              <pre style={{ margin: "0px" }}>
                {`{%p for item in Experiences %}
{{ item.Company }}
{{ item.Period }} – {{ item.Position }}{% for part in item.Description %}{% for context in part.Context %}
{{ context }}{% endfor %}{% for bullet in part.Details %}
•	{{ bullet }}{% endfor %}{% endfor %}
Technologies projet : {% for tech in item.Technologies %}{{ tech }}, {% endfor %}
{%p endfor %}`}
              </pre>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={4}>
        <Typography variant="h5" gutterBottom>
          Exemple de template
        </Typography>
        <Box style={{ maxHeight: "86vh", overflowY: "auto" }}>
          <Document file={pdfFile}>
            {[1, 2].map((pageNumber) => (
              <Box
                key={pageNumber}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "16px",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid black",
                    borderRadius: "6px",
                  }}
                >
                  <Page pageNumber={pageNumber} scale={scale} />
                </Box>
              </Box>
            ))}
            <div
              style={{
                position: "sticky",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "60px",
                background:
                  "linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff)",
              }}
            />
          </Document>
        </Box>
      </Box>
      <Button
        variant="outlined"
        onClick={handleBack}
        sx={{
          ml: 2,
          mb: 2,
          bgcolor: "#200A5F",
          color: "white",
          fontSize: "12px",
          position: "fixed",
          bottom: 0,
          zIndex: 11,
          left: 0,
          ":hover": {
            color: "#200A5F",
            border: "1px solid #200A5F",
          },
        }}
        startIcon={<ArrowBackIosIcon />}
      >
        <div className="d-none d-md-block">Retour</div>
      </Button>
    </Container>
  );
};

export default TemplateGuide;
