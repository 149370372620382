import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const AppFooter = () => {

  return (
    <Box
      component="footer"
      sx={{
        pt: 3.5,
        pb: 3,
        px: 2,
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
      }}
    >
      <Container>
        <Box align="center" fontSize={14}>
          <Link to="/terms-of-service" style={{ marginRight: "16px", textDecoration: "underline" }}>
            Conditions générales d'utilisation
          </Link>
          <Link to="/template-guide" style={{ textDecoration: "underline" }}>
            Créer un template
          </Link>
        </Box>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
          {'© '}
          {new Date().getFullYear()}
          {' '}
          <Link href="https://www.aptea.net/" style={{ color: "inherit", textDecoration: "underline"}}>
            APTEA
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default AppFooter;
