import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Box, Button, Tabs, Tab, TextField, Typography, Divider, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, CircularProgress, Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import config from "../../config";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import RefreshIcon from '@mui/icons-material/Refresh';

const AdminModal = ({ show, onHide }) => {
  const [tabValue, setTabValue] = useState(0);
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ email: '', password: '' });
  const [selectedUser, setSelectedUser] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [createUserError, setCreateUserError] = useState('');
  const [createUserSuccess, setCreateUserSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const userToken = useSelector((state) => state.user.token);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      setTabValue(0);
      fetchUsers();
      setCreateUserError("");
      setCreateUserSuccess("");
      setNewUser({ email: '', password: '' });
    }
  }, [show]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const url = `${config.urlBackOffice}/users/`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setLoading(false);
      setUsers(response.data);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching users:', error);
    }
  };

  const handleRegister = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    setCreateUserError("");
    setCreateUserSuccess("");
    
    if (!emailPattern.test(newUser.email)) {
      setCreateUserError("Veuillez saisir une adresse email valide");
      return;
    }
    try {
      setLoading(true);
      const url = `${config.urlBackOffice}/register/`;
      const response = await axios.post(url, newUser, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setLoading(false);
      if (response.data.message) {
        setCreateUserSuccess(response.data.message);
      } else {
        setCreateUserSuccess("Utilisateur créé avec succès et email envoyé");
      }
      setNewUser({ email: '', password: '' });
      fetchUsers();
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        setCreateUserError(error.response.data.message);
      } else {
        setCreateUserError("Erreur lors de la requête");
      }
      console.error('Error registering user:', error);
    }
  };

  const handleDelete = async (user) => {
    try {
      setLoading(true);
      const url = `${config.urlBackOffice}/delete-user/`;
      await axios.delete(url, {
        data: { email: user.email },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setLoading(false);
      fetchUsers();
      handleCloseConfirmDialog();
    } catch (error) {
      setLoading(false);
      console.error('Error deleting user:', error);
    }
  };

  const handleOpenConfirmDialog = (user) => {
    setSelectedUser(user);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setSelectedUser(null);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const refreshUsers = async () => {
    fetchUsers();
  };

  return (
    <Modal open={show} onClose={onHide}>
      <Box sx={{
        maxWidth: 500,
        maxHeight: 500,
        margin: 'auto',
        mt: '20vh',
        p: 3,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 2,
      }}>
        <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} aria-label="Admin tabs" TabIndicatorProps={{style: {backgroundColor: '#200A5F'}}}>
          <Tab label="Utilisateurs" sx={{ '&.Mui-selected': { color: '#200A5F' } }} />
          <Tab label="Créer un utilisateur" sx={{ '&.Mui-selected': { color: '#200A5F' } }} />
        </Tabs>
        {tabValue === 0 && (
          <Box sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Liste des utilisateurs</Typography>
              <IconButton sx={{ ml: 2 }} onClick={refreshUsers}>
                <RefreshIcon />
              </IconButton>
            </Box>
            <Box sx={{ maxHeight: 345, overflow: 'auto' }}>
              <Divider color="gray" />
              {loading ? (
                <Box my={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <CircularProgress />
                </Box>
              ) : (
                users.map((user) => (
                  <Box key={user.id}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 0.5 }}>
                      <Typography>{user.email}</Typography>
                      <IconButton sx={{ color: 'gray' }} onClick={() => handleOpenConfirmDialog(user)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    <Divider color="gray" />
                  </Box>
                ))
              )}
            </Box>
          </Box>
        )}
        {tabValue === 1 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>Création d'un utilisateur</Typography>
            <TextField
              label="Email"
              name="email"
              value={newUser.email}
              onChange={handleChange}
              fullWidth
              variant="standard"
              sx={{ mb: 2 }}
            />
            <TextField
              label="Mot de passe (optionnel)"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={newUser.password}
              onChange={handleChange}
              fullWidth
              variant="standard"
              sx={{ mb: 3 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Alert severity="info">Un email va être envoyé à l'utilisateur afin qu'il puisse définir son mot de passe.</Alert>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 3 }}>
              {createUserError && <Box mr={3} color="red" fontWeight={"bold"} fontSize={"small"}>{createUserError}</Box>}
              {createUserSuccess && <Box mr={3} color="green" fontWeight={"bold"} fontSize={"small"}>{createUserSuccess}</Box>}
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#200A5F',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#321a8b',
                  },
                }}
                onClick={handleRegister}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Créer'
                )}
              </Button>
            </Box>
          </Box>
        )}
        <Dialog
          open={openConfirmDialog}
          onClose={handleCloseConfirmDialog}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="confirm-dialog-description"
        >
          <DialogTitle id="confirm-dialog-title">Confirmer la suppression</DialogTitle>
          <DialogContent>
            <DialogContentText id="confirm-dialog-description">
              Êtes-vous sûr de vouloir supprimer l'utilisateur : 
              <Box>{selectedUser?.email} ?</Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmDialog} color="primary">
              Annuler
            </Button>
            <Button onClick={() => handleDelete(selectedUser)} color="secondary" autoFocus>
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Supprimer'
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Modal>
  );
};

export default AdminModal;