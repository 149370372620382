import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Container } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PdfReader from "../components/drop-file-input/PdfReader";
import { useNavigate } from "react-router-dom";
import pdfFile from "../Template/CGU.pdf";
import { setUserDataAgreement } from "../redux/actions/dataCollectionAction";
import axios from "axios";
import config from "../config";
import { jwtDecode } from "jwt-decode";

const TermsOfService = () => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataCollection = useSelector((state) => state.dataCollection);
  const userToken = useSelector((state) => state.user.token);

  const handleApprove = async () => {

    if (!userToken) {
      dispatch(setUserDataAgreement(true));
      navigate(-1);
      return;
    }

    if (userToken) {
      const decodedToken = jwtDecode(userToken);
      
      if (decodedToken.ldap_user) {
        dispatch(setUserDataAgreement(true));
        navigate(-1);
        return;
      }

      try {
        const payload = {
          user_id: decodedToken.user_id,
          terms: true
        };

        const url = `${config.urlBackOffice}/accept-terms/`;
        const response = await axios.put(url, payload);
        navigate(-1);
      } catch (error) {
        console.error('Erreur en approuvant les CGU:', error);
        if (error.response && error.response.data) {
          console.log(`Erreur: ${error.response.data.error}`);
        } else {
          console.log('Erreur en approuvant les CGU');
        }
      }
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleScroll = () => {
    if (!isPdfLoaded) return;

    const scrollPosition = window.scrollY + window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const threshold = 0.9; // 90% of the document height

    if (scrollPosition >= documentHeight * threshold) {
      setIsButtonEnabled(true);
    }
  };

  useEffect(() => {
    if (isPdfLoaded) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isPdfLoaded]);

  const onPdfLoadComplete = () => {
    setIsPdfLoaded(true);
  };

  return (
    <Container sx={{ my: 1 }}>
      <PdfReader pdfFile={pdfFile} onPdfLoadComplete={onPdfLoadComplete} />
      {!dataCollection.userDataAgreement && (
        <Button
          variant="outlined"
          onClick={handleApprove}
          disabled={!isButtonEnabled}
          sx={{
            ml: 2,
            mb: 2,
            bgcolor: isButtonEnabled ? "#200A5F" : "grey",
            color: "white",
            fontSize: "12px",
            position: "fixed",
            bottom: "15%",
            zIndex: 11,
            right: "15%",
            ":hover": {
              color: isButtonEnabled ? "#200A5F" : "white",
              border: isButtonEnabled ? "1px solid #200A5F" : "1px solid grey",
            },
          }}
        >
          <div className="d-none d-md-block">Lu et approuvé</div>
        </Button>
      )}
      {dataCollection.userDataAgreement && (
        <Button
          variant="outlined"
          onClick={handleBack}
          sx={{
            ml: 2,
            mb: 2,
            bgcolor: "#200A5F",
            color: "white",
            fontSize: "12px",
            position: "fixed",
            bottom: 0,
            zIndex: 11,
            left: 0,
            ":hover": {
              color: "#200A5F",
              border: "1px solid #200A5F",
            },
          }}
          startIcon={<ArrowBackIosIcon />}
        >
          <div className="d-none d-md-block">Retour</div>
        </Button>
      )}
    </Container>
  );
};

export default TermsOfService;
