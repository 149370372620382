export const SET_CV_THEME = "SET_CV_THEME";
export const RESET_CV_THEME = "RESET_CV_THEME";

export const setCvTheme = (data) => ({
  type: SET_CV_THEME,
  payload: { data },
});

export const resetCvTheme = () => ({
  type: RESET_CV_THEME,
  payload: {},
});
