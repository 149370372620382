import React from "react";
import { Button, Grid } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import TitleIcon from "@mui/icons-material/Title";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";

import { Tooltip } from "@mui/material";

export default function ExperienceTools({
  type = "update",
  onMoveUp = () => {},
  onMoveDown = () => {},
  onMoveToTechnologies = () => {},
  onCreateExperience = () => {},
  onDeleteRow = () => {},
}) {
  const styles = {
    paletteContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#dae7e9",
      borderRadius: "2px",
    },
    paletteElementContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#200A5F",
      borderRadius: "3px",
      margin: "5px",
      padding: "3px",
      cursor: "pointer",
    },
    paletteIconElement: {
      color: "#ffffff",
      fontSize: "16px",
    },
  };
  return (
    <div style={styles.paletteContainer}>
      <div style={styles.paletteElementContainer} onClick={onMoveUp}>
        <Tooltip title={"Monter"}>
          <ArrowUpwardIcon style={styles.paletteIconElement} />
        </Tooltip>
      </div>
      <div style={styles.paletteElementContainer} onClick={onMoveDown}>
        <Tooltip title={"Descendre"}>
          <ArrowDownwardIcon style={styles.paletteIconElement} />
        </Tooltip>
      </div>
      <div
        style={styles.paletteElementContainer}
        onClick={onMoveToTechnologies}
      >
        <Tooltip title={"Ajouter aux technologies"}>
          <TitleIcon style={styles.paletteIconElement} />
        </Tooltip>
      </div>
      {type == "update" && (
        <div
          style={styles.paletteElementContainer}
          onClick={onCreateExperience}
        >
          <Tooltip
            title={
              "Créer une nouvelle expérience à partir du texte selectionné"
            }
          >
            <WorkOutlineIcon style={styles.paletteIconElement} />
          </Tooltip>
        </div>
      )}
      <div style={styles.paletteElementContainer} onClick={onDeleteRow}>
        <Tooltip title={"Supprimer"}>
          <DeleteOutlinedIcon style={styles.paletteIconElement} />
        </Tooltip>
      </div>
    </div>
  );
}
