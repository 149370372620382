import React, { useState, useEffect, useRef } from "react";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import {
  resetTemplate,
  setTemplate,
} from "../../redux/actions/templateActions";

import html2pdf from "html2pdf.js";

import { Document, Page } from "@react-pdf/renderer";

import Stack from "@mui/material/Stack";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { jwtDecode } from "jwt-decode";
import { setCvTheme } from "../../redux/actions/cvThemeAction";
import UpdateContactFooterModal from "./UpdateContactFooterModal";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import PaletteColor from "../../assets/paletteColor.png";
import { Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { setLogoImage } from "../../redux/actions/dataCollectionAction";
import mammoth from "mammoth";
import ImageCropModal from "../correction/image/ImageCropModal";

const ref = React.createRef();

export function Template() {
  const containerRef = useRef(null);
  const [html, setHtml] = useState("");
  const [premiumEnabled, setPremiumEnabled] = useState(false);
  const [show, setShow] = useState(false);
  const [pdfFileError, setPdfFileError] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [docContent, setDocContent] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [mtop, setMtop] = useState(-2);
  const [mleft, setMleft] = useState(10);
  const [fileUrl, setFileUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [showPaletteColor, setShowPaletteColor] = useState(false);
  const [sketchPickerColor, setSketchPickerColor] = useState("#c41070");
  const [showImageCropModal, setShowImageCropModal] = useState(false);

  const themeCv = useSelector((state) => state.themeCv);
  const contactFooter = useSelector((state) => state.contactFooter);
  const dataCollection = useSelector((state) => state.dataCollection);
  const templateFile = useSelector((state) => state.template);
  const userToken = useSelector((state) => state.user.token);

  const dispatch = useDispatch();
  const fileInputRef = useRef();

  const themeColors = [
    "#C41070",
    "#ff0000",
    "#3009a5",
    "#008000",
    "#ffa500",
    "#800080",
    "#000000",
  ];

  const globaleThemeChange = (member, item) => {
    if (member == "backgroundColor") {
      dispatch(
        setCvTheme({
          ...themeCv,
          theme: {
            Content: item,
          },
        })
      );
    } else {
      dispatch(
        setCvTheme({
          ...themeCv,
          [member]: {
            Content: item.target.value,
          },
        })
      );
    }
  };

  // destructuring rgba from state
  const { r, g, b, a } = sketchPickerColor;

  const changeSketchPickerColor = (color) => {
    dispatch(
      setCvTheme({
        ...themeCv,
        sketchPickerColor: color,
      })
    );
  };

  const generatePDF = () => {
    var element = document.getElementById("report");
    var opt = {
      margin: [10, 10, 31, 5],

      filename: "myfile.pdf",
      image: {
        type: "jpeg",
        quality: 0.98,
      },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },

      html2canvas: {
        windowWidth: 3000,
        windowHeight: 3000,
        scale: 5,
        logging: true,
        dpi: 96,
        useCORS: true,
      },
      jsPDF: {
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        floatPrecision: "smart",
      },
    };
    // setMleft(-7);
    // setMtop(-2);

    html2pdf()
      .from(element)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();
        var pageHeight = pdf.internal.pageSize.height;
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8);
          pdf.setTextColor(150);
          pdf.setLineWidth(0.1);
          pdf.setDrawColor("#C41070");

          var centerX = pdf.internal.pageSize.getWidth() / 2;
          pdf.line(
            centerX - (centerX * 3) / 4,
            pdf.internal.pageSize.getHeight() - 25,
            centerX + (centerX * 3) / 4,
            pdf.internal.pageSize.getHeight() - 25
          );
          if (i != 1) {
            pdf.setTextColor(0, 0, 0);
            pdf.setFontSize(9);
            pdf.text(
              +i + "/" + totalPages,
              pdf.internal.pageSize.getWidth() - 33,
              pdf.internal.pageSize.getHeight() - 14
            );
            pdf.setTextColor(0, 0, 0);
            pdf.setFontSize(9);
            pdf.text(
              "Aptea",
              centerX - (centerX * 2) / 3 - 7,
              pdf.internal.pageSize.getHeight() - 14
            );
          } else {
            pdf.setTextColor(0, 0, 0);
            pdf.setFontSize(9);
            pdf.text(
              Object.values(contactFooter.Adresse)[0],
              pdf.internal.pageSize.getWidth() - 150,
              pdf.internal.pageSize.getHeight() - 20
            );
            pdf.setTextColor(0, 0, 0);
            pdf.setFontSize(9);
            pdf.text(
              Object.values(contactFooter.telephoneOne)[0],
              centerX - (centerX * 2) / 3 + 70,
              pdf.internal.pageSize.getHeight() - 15
            );
            pdf.setTextColor(0, 0, 0);
            pdf.setFontSize(9);
            pdf.text(
              Object.values(contactFooter.telephoneTwo)[0],
              centerX - (centerX * 2) / 3 + 33,
              pdf.internal.pageSize.getHeight() - 15
            );
            pdf.setTextColor(0, 0, 0);
            pdf.setFontSize(9);
            pdf.text(
              Object.values(contactFooter.EntrepriseType)[0],
              centerX - (centerX * 2) / 3 + 70,
              pdf.internal.pageSize.getHeight() - 10
            );
            pdf.setTextColor(0, 0, 0);
            pdf.setFontSize(9);
            pdf.text(
              Object.values(contactFooter.PublicRegister)[0],
              centerX - (centerX * 2) / 3 + 20,
              pdf.internal.pageSize.getHeight() - 10
            );
            pdf.setTextColor(196, 16, 112);
            pdf.setFontSize(9);
            pdf.text(
              Object.values(contactFooter.InternetLink)[0],
              centerX - (centerX * 2) / 3 + 70,
              pdf.internal.pageSize.getHeight() - 5,
              pdf.setDrawColor("#C41070")
            );
            pdf.setFontSize(9);
            pdf.setTextColor(196, 16, 112);
            pdf.text(
              Object.values(contactFooter.Mail)[0],
              centerX - (centerX * 2) / 3 + 38,
              pdf.internal.pageSize.getHeight() - 5
            );
          }
        }
      })
      .save();
    handleOpen();
  };

  const handleDownload = () => {
    const source =
      "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);
    const fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = "cverter.doc";
    fileDownload.click();
    document.body.removeChild(fileDownload);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleFootClose = () => setShow(false);
  const handleFootShow = () => setShow(true);

  const handleUploadClick = () => {
    // trigger the file input element's click event
    if (premiumEnabled) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (selectedFile.name === "CV Modèle APTEA.docx") {
        setPdfFileError(
          "CV Modèle APTEA.docx est déjà le template par défaut."
        );
        return;
      }
      if (
        selectedFile.name.endsWith(".docx") ||
        selectedFile.type.includes(
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        )
      ) {
        setPdfFileError("");
        const selectedFileURL = URL.createObjectURL(selectedFile);
        dispatch(setTemplate(selectedFileURL, selectedFile.name));
      } else {
        setPdfFileError("Please select a valid Word file.");
      }
      event.target.value = null;
    } else {
      console.log("Please select your file.");
    }
  };

  const handleDeleteTemplate = () => {
    dispatch(resetTemplate());
  };

  useEffect(() => {
    if (userToken) {
      const decodedToken = jwtDecode(userToken);
      setPremiumEnabled(decodedToken.is_premium);
    } else {
      setPremiumEnabled(false);
    }
  }, [userToken]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        handleClose();
      }, 1500);
    }
  }, [open]);

  useEffect(() => {
    const header =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office"' +
      'xmlns:w="urn:schemas-microsoft-com:office:word"' +
      'xmlns="http://www.w3.org/TR/REC-html40">' +
      '<head><meta charset="utf-8"><title>Export HTML to Word Document with JavaScript</title></head><body>';

    const footer = "</body></html>";

    // Convert the selected image to a base64 encoded string
    const imageUrl = themeCv.selectedImagesl;
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const imageTag = `<img src="${reader.result}" alt="My Image"/>`;
          const sourceHTML =
            header + containerRef?.current?.innerHTML + imageTag + footer;
          setHtml(sourceHTML);
        };
      })
      .catch((error) => {
        console.log("Error fetching image", error);
      });
  }, []);

  useEffect(() => {
    setTemplateName(templateFile.templateName);

    if (templateFile.templateURL && templateFile.templateName) {
      fetch(templateFile.templateURL)
        .then((res) => res.blob())
        .then(async (blob) => {
          const file = new File([blob], templateFile.templateName, {
            type: blob.type,
          });
          const arrayBuffer = await file.arrayBuffer();
          const { value: htmlContent } = await mammoth.convertToHtml({
            arrayBuffer,
          });
          setDocContent(htmlContent);
        });
    } else {
      setDocContent("");
      setTemplateName("");
    }
  }, [templateFile]);

  return (
    <div>
      <div className="row">
        <div
          className="col-lg-4 col-md-4"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 5px",
                border: "solid 1px #A3A0B8",
                borderRadius: "6px",
                minWidth: "300px",
                backgroundColor: "#FBFAFF",
                marginBottom: "24px",
              }}
            >
              <Typography align="center" fontWeight={"bold"}>
                Template :
              </Typography>

              {templateName ? (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: "400px",
                    margin: "4px 0px 16px 0px",
                  }}
                >
                  <Box className="template-name">{templateName}</Box>
                  <MuiLink
                    style={{
                      marginTop: "8px",
                      color: "gray",
                      cursor: "pointer",
                      fontSize: "15px",
                    }}
                    onClick={() => handleDeleteTemplate()}
                  >
                    Remettre le template par défaut
                  </MuiLink>
                </Box>
              ) : (
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: "400px",
                    margin: "4px 0px 16px 0px",
                  }}
                >
                  Modèle APTEA par défaut
                </Typography>
              )}

              <Stack
                style={{
                  width: "100%",
                  marginBottom: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    background: premiumEnabled
                      ? "linear-gradient(to bottom right, #EDE6EB,#DC1C85,#EDE6EB)"
                      : "#CCCCCC",
                    color: premiumEnabled ? "white" : "#666666",
                    padding: "5px",
                    width: "75%",
                    cursor: premiumEnabled ? "pointer" : "not-allowed",
                    borderRadius: "4px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.background = premiumEnabled
                      ? "linear-gradient(to bottom right, #DC1C85,#EDE6EB,#DC1C85)"
                      : "#CCCCCC";
                    e.currentTarget.style.color = premiumEnabled
                      ? "black"
                      : "#666666";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.background = premiumEnabled
                      ? "linear-gradient(to bottom right, #EDE6EB,#DC1C85,#EDE6EB)"
                      : "#CCCCCC";
                    e.currentTarget.style.color = premiumEnabled
                      ? "white"
                      : "#666666";
                  }}
                  onClick={handleUploadClick}
                >
                  <span style={{ display: "flex", justifyContent: "center" }}>
                    Postez votre template
                  </span>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                  />
                </div>
                <Tooltip
                  title="Premium requis"
                  arrow
                  style={{ marginLeft: "5px" }}
                >
                  <InfoOutlinedIcon />
                </Tooltip>
              </Stack>

              {pdfFileError && (
                <div className="error-msg" style={{ margin: "4px 0px" }}>
                  {pdfFileError}
                </div>
              )}

              <Link
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "8px 0px",
                }}
                to="/template-guide"
              >
                Comment créer un template
              </Link>
            </Box>

            {!templateName && (
              <Box
                style={{
                  padding: "15px",
                  border: "solid 1px #A3A0B8",
                  borderRadius: "6px",
                  backgroundColor: "#FBFAFF",
                  marginBottom: "24px",
                }}
              >
                <Grid style={{ display: "flex" }}>
                  <Typography align="left">
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Logo:
                    </span>
                  </Typography>
                </Grid>
                <Grid display={"flex"} alignItems={"center"}>
                  <Stack mt={1}>
                    <div onClick={() => setShowImageCropModal(true)}>
                      <label
                        htmlFor="fileInput"
                        className="custom-file-upload"
                        style={{
                          display: "inline-block",
                          padding: "5px ",
                          cursor: "pointer",
                          backgroundColor: "#efefef",
                          borderRadius: "5px",
                        }}
                      >
                        Choisir un logo
                      </label>
                    </div>
                  </Stack>
                  <Stack mt={1}>
                    {dataCollection?.logoImage?.logoImageName !== "" && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "35px",
                          marginLeft: "10px",
                          marginRight: "5px",
                        }}
                      >
                        {dataCollection?.logoImage?.logoImageName}
                      </div>
                    )}
                  </Stack>
                  <Stack mt={1}>
                    {dataCollection?.logoImage?.base64Url !== "" && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <IconButton
                          size="small"
                          style={{}}
                          onClick={() =>
                            dispatch(
                              setLogoImage({
                                base64Url: "",
                                logoImageName: "",
                                prefixBase64Url: "",
                              })
                            )
                          }
                        >
                          <Delete />
                        </IconButton>
                      </div>
                    )}
                  </Stack>
                </Grid>
                <Stack mt={2} mb={2} direction="row" spacing={4}>
                  <Paper
                    style={{
                      width: "70px",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      pointerEvents: dataCollection?.profilImage?.base64Url
                        ? "none"
                        : "auto", // Disable interactions
                      opacity: dataCollection?.profilImage?.base64Url ? 0.5 : 1, // Visually indicate disabled state
                    }}
                    elevation={2}
                  >
                    <span
                      onClick={() => {
                        dispatch(
                          setCvTheme({
                            ...themeCv,
                            positionLogo: {
                              Content: "flex-start",
                            },
                          })
                        );
                      }}
                    >
                      Gauche
                    </span>
                  </Paper>
                  <Paper
                    style={{
                      width: "70px",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    elevation={2}
                  >
                    <span
                      onClick={() => {
                        dispatch(
                          setCvTheme({
                            ...themeCv,
                            positionLogo: {
                              Content: "center",
                            },
                          })
                        );
                      }}
                    >
                      Centre
                    </span>
                  </Paper>
                  <Paper
                    style={{
                      width: "70px",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    elevation={2}
                  >
                    <Box>
                      <span
                        onClick={() => {
                          dispatch(
                            setCvTheme({
                              ...themeCv,
                              positionLogo: {
                                Content: "flex-end",
                              },
                            })
                          );
                        }}
                      >
                        Droite
                      </span>
                    </Box>
                  </Paper>
                </Stack>
                <Grid container style={{ margin: "5px" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!Object.values(themeCv.showLine)[0]}
                        onChange={() => {
                          dispatch(
                            setCvTheme({
                              ...themeCv,
                              showLine: {
                                Content: !Object.values(themeCv.showLine)[0],
                              },
                            })
                          );
                        }}
                      />
                    }
                    label="Cacher la ligne"
                  />
                </Grid>
                <Box align="left">
                  <Stack direction="row" spacing={2}>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Nom:
                    </span>
                    <select
                      onChange={(event) => {
                        const value = event.target.value;
                        if (value === "complet") {
                          dispatch(
                            setCvTheme({
                              ...themeCv,
                              showName: {
                                Content: true,
                              },
                              showAbbriviationName: {
                                Content: false,
                              },
                            })
                          );
                        } else if (value === "initiales") {
                          dispatch(
                            setCvTheme({
                              ...themeCv,
                              showName: {
                                Content: false,
                              },
                              showAbbriviationName: {
                                Content: true,
                              },
                            })
                          );
                        } else {
                          dispatch(
                            setCvTheme({
                              ...themeCv,
                              showName: {
                                Content: false,
                              },
                              showAbbriviationName: {
                                Content: false,
                              },
                            })
                          );
                        }
                      }}
                    >
                      <option value="complet">Complet</option>
                      <option value="initiales">Initiales</option>
                      <option value="aucun">Aucun</option>
                    </select>
                  </Stack>
                </Box>
                <Grid container style={{ marginTop: "10px" }}>
                  {themeColors.map((item, themeIndex) => (
                    <Grid key={themeIndex} item>
                      <Box
                        width="30px"
                        height="30px"
                        marginBottom="15px"
                        marginRight="5px"
                        onClick={() =>
                          globaleThemeChange("backgroundColor", item)
                        }
                      >
                        <Paper elevation={2} style={{ backgroundColor: item }}>
                          <Box padding="20px" width="100%" height="100%"></Box>
                        </Paper>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <Stack mb={1}>
                  <Typography align="left">
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Titres:
                    </span>
                  </Typography>
                </Stack>

                <Stack direction="row" spacing={4}>
                  <Box align="left">
                    <Stack direction="row" spacing={2}>
                      <span>Font:</span>
                      <select
                        onChange={(event) =>
                          globaleThemeChange("fontFamily", event)
                        }
                        value={themeCv.fontFamily.Content}
                      >
                        <option value="Times New Roman">Times New Roman</option>
                        <option value="Arial">Arial</option>
                        <option value="Baskerville">Baskerville</option>
                        <option value="Calibri">Calibri</option>
                        <option value="Cambria">Cambria</option>
                        <option value="Consolas">Consolas</option>
                        <option value="Didot">Didot</option>
                        <option value="Garamond">Garamond</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Helvetica">Helvetica</option>
                        <option value="Verdana">Verdana</option>
                      </select>
                    </Stack>
                  </Box>
                </Stack>
                <Stack mt={2} direction="row" spacing={4}>
                  <Box align="left">
                    <Stack direction="row" spacing={2}>
                      <span>Style:</span>
                      <select
                        onChange={(event) => globaleThemeChange("font", event)}
                        value={themeCv.font.Content}
                      >
                        <option value="normal">normal</option>
                        <option value="bold">bold</option>
                        <option value="lighter">lighter</option>
                        <option value="bolder">bolder</option>
                      </select>
                    </Stack>
                  </Box>
                </Stack>
                <Stack mt={2} direction="row" spacing={4}>
                  <Box align="left">
                    <Stack direction="row" spacing={1}>
                      <span>Taille:</span>
                      <input
                        onChange={(event) => globaleThemeChange("size", event)}
                        value={Object.values(themeCv.size)[0]}
                        type="number"
                        width="5px"
                        name="taille"
                        id="taille"
                        step="1"
                      />
                    </Stack>
                  </Box>
                </Stack>

                <Stack mt={2} spacing={2}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ marginRight: "10px" }}>
                      Choisir la couleur des titres:
                    </span>
                    <img
                      alt="not fount"
                      width={"30px"}
                      height={"30px"}
                      src={PaletteColor}
                      onClick={() => setShowPaletteColor(!showPaletteColor)}
                    />
                  </div>
                </Stack>
                {showPaletteColor && (
                  <Stack mt={2} spacing={2}>
                    <div className="sketchpicker">
                      <SketchPicker
                        onChange={(color) => {
                          setSketchPickerColor(color.hex);
                          changeSketchPickerColor(color.hex);
                        }}
                        color={sketchPickerColor}
                      />
                    </div>
                  </Stack>
                )}
                <Grid container style={{ margin: "5px" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!Object.values(themeCv.showHobbies)[0]}
                        onChange={() => {
                          dispatch(
                            setCvTheme({
                              ...themeCv,
                              showHobbies: {
                                Content: !Object.values(themeCv.showHobbies)[0],
                              },
                            })
                          );
                        }}
                      />
                    }
                    label="Cacher les loisirs"
                  />
                </Grid>

                <Grid
                  style={{
                    display: "flex",
                    width: "100%",
                    marginTop: "16px",
                    alignItems: "center",
                  }}
                >
                  <Typography align="left" style={{ fontWeight: "bold" }}>
                    Pied de page:
                  </Typography>
                  <Paper
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#DC1C85",
                      color: "white",
                      marginLeft: "6%",
                      cursor: "pointer",
                      padding: "5px 10px",
                      borderRadius: "4px",
                    }}
                    elevation={2}
                    onClick={handleFootShow}
                  >
                    Modifier
                  </Paper>
                </Grid>
              </Box>
            )}
          </div>
        </div>

        <div
          className="col-lg-8 col-md-8 col-12 mx-auto"
          style={{ height: "86vh", overflow: "auto" }}
        >
          {!templateName ? (
            <div>
              <Document
                style={{
                  pageBreakInside: "avoid",
                  fontFamily: Object.values(themeCv.fontFamily)[0],
                }}
              >
                <Page
                  style={{
                    pageBreakInside: "avoid",
                  }}
                >
                  <div className="page">
                    <div className="element-to-print" ref={ref}>
                      <Paper
                        ref={containerRef}
                        id="report"
                        onMouseOver={(e) => {
                          setMleft(10);
                          setMtop(-2);
                        }}
                        elevation={7}
                        style={{
                          minHeight: "calc(100vh - 20px)",
                          paddingBottom: 20,
                        }}
                      >
                        <div
                          className="container"
                          style={{
                            paddingTop: "10px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          {dataCollection?.profilImage?.base64Url !== "" && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                alt="not fount"
                                width={"135px"}
                                height={"150px"}
                                style={{
                                  // border: "2px solid #a9bef0",
                                  // filter: "drop-shadow(0 0 2px #DA2889)",
                                  // borderRadius: "50%",
                                  objectFit: "contain",
                                  borderRadius: "5%",
                                }}
                                src={`data:image/jpeg;base64,${dataCollection.profilImage.base64Url}`}
                              />
                            </div>
                          )}
                          <Grid
                            container
                            wrap="nowrap"
                            direction="row"
                            justifyContent={
                              Object.values(themeCv.positionLogo)[0]
                            }
                            alignItems="center"
                            style={{
                              paddingTop: "10px",
                            }}
                          >
                            <Box
                              maxWidth="151px"
                              maxHeight="151px"
                              overflow="hidden"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginRight:
                                  Object.values(themeCv.positionLogo)[0] ==
                                    "center" &&
                                  dataCollection?.profilImage?.base64Url
                                    ? "20%"
                                    : "0px",
                              }}
                            >
                              <div>
                                {dataCollection?.logoImage?.base64Url !==
                                  "" && (
                                  <img
                                    alt="not fount"
                                    width={"135px"}
                                    height={"135px"}
                                    style={{
                                      // border: "2px solid #a9bef0",
                                      // filter: "drop-shadow(0 0 2px #DA2889)",
                                      // borderRadius: "50%",
                                      objectFit: "contain",
                                      borderRadius: "5%",
                                    }}
                                    src={`data:image/jpeg;base64,${dataCollection.logoImage?.base64Url}`}
                                  />
                                )}
                              </div>
                            </Box>
                          </Grid>
                        </div>
                        <Grid hidden={!Object.values(themeCv.showLine)[0]}>
                          <Stack direction="row" spacing={15}>
                            <Grid
                              container
                              wrap="nowrap"
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <hr
                                style={{
                                  bgcolor: Object.values(themeCv.theme)[0],
                                  width: "600px",
                                  color: Object.values(themeCv.theme)[0],
                                  backgroundColor: Object.values(
                                    themeCv.theme
                                  )[0],
                                  height: 3,
                                }}
                              />
                            </Grid>
                          </Stack>
                        </Grid>
                        <div hidden={!Object.values(themeCv.showName)[0]}>
                          <Grid
                            container
                            wrap="nowrap"
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {Object.values(dataCollection.Name)[0]}
                          </Grid>
                        </div>
                        <div
                          hidden={
                            !Object.values(themeCv.showAbbriviationName)[0]
                          }
                        >
                          <Grid
                            container
                            wrap="nowrap"
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {Object.values(dataCollection.abbreviationName)[0]}
                          </Grid>
                        </div>
                        <Grid
                          container
                          wrap="nowrap"
                          direction="row"
                          justifyContent="center"
                        >
                          <div
                            style={{
                              marginTop: 10,
                              width: 500,
                              height: 80,
                              backgroundColor: Object.values(themeCv.theme)[0],
                              border: "1px solid #000",
                            }}
                          >
                            <div
                              style={{
                                textAlign: "center",
                                color: "white",
                                lineHeight: 1.3,
                              }}
                            >
                              {
                                Object.values(
                                  dataCollection.Profile == null
                                    ? 0
                                    : dataCollection.Profile
                                )[0]
                              }
                              <br />
                              {`${
                                Object.values(
                                  dataCollection.YearsOfExperience == null
                                    ? 0
                                    : dataCollection.YearsOfExperience
                                )[0]
                              } an(s) d’expérience(s) `}
                            </div>
                          </div>
                        </Grid>
                        {dataCollection?.Skills !== null &&
                          dataCollection?.Skills.length !== 0 && (
                            <Grid
                              container
                              wrap="nowrap"
                              direction="row"
                              justifyContent="flex-start"
                            >
                              <Grid item md={1}></Grid>
                              <Grid item md={10}>
                                <div
                                  style={{
                                    textAlign: "left",
                                    textTransform: "uppercase",
                                    fontWeight: Object.values(themeCv.font)[0],

                                    fontSize: Object.values(
                                      themeCv.size
                                    )[0].concat("px"),
                                    color: themeCv.sketchPickerColor,
                                    lineHeight: 4.6,
                                  }}
                                >
                                  Compétences technologiques
                                </div>
                              </Grid>
                            </Grid>
                          )}
                        {dataCollection.Skills != null &&
                        dataCollection?.Skills.length !== 0 ? (
                          <div>
                            {Object.entries(dataCollection.Skills)?.map(
                              ([key, value], userSkillIndex) => (
                                <Grid key={userSkillIndex}>
                                  <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-4">
                                      <Typography align="left">
                                        <small>{value.Name}</small>
                                      </Typography>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-6 ">
                                      <Stack
                                        justifyContent="flex-start"
                                        direction="row"
                                        spacing={1}
                                      >
                                        <Typography
                                          align="left"
                                          style={{
                                            width: "100%",
                                            margin: "auto",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          {value.Skills?.map((x, inedx) => (
                                            <small key={inedx}>
                                              {x + (key ? "," : "")}
                                            </small>
                                          ))}
                                        </Typography>
                                      </Stack>
                                    </div>
                                  </div>
                                </Grid>
                              )
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {dataCollection.Education != null &&
                          dataCollection?.Education.length !== 0 && (
                            <Grid
                              container
                              wrap="nowrap"
                              justifyContent="flex-start"
                            >
                              <Grid item md={1}></Grid>
                              <Grid item md={8}>
                                <div
                                  style={{
                                    textAlign: "left",
                                    textTransform: "uppercase",
                                    fontWeight: Object.values(themeCv.font)[0],
                                    fontSize: Object.values(
                                      themeCv.size
                                    )[0].concat("px"),
                                    color: themeCv.sketchPickerColor,
                                    lineHeight: 3.6,
                                  }}
                                >
                                  PARCOURS ÉDUCATIF ET FORMATION
                                </div>
                              </Grid>
                            </Grid>
                          )}
                        {dataCollection.Education != null &&
                        dataCollection.Education.length !== 0 ? (
                          <div>
                            {Object.entries(dataCollection.Education)?.map(
                              ([key, value], userEducationIndex) => (
                                <Grid key={userEducationIndex}>
                                  <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-4  ">
                                      <Typography align="left">
                                        <small>{value.Period}</small>
                                      </Typography>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-6  ">
                                      <Stack
                                        justifyContent="flex-start"
                                        direction="row"
                                        spacing={1}
                                      >
                                        <Typography
                                          style={{
                                            width: "100%",
                                            margin: "auto",
                                            wordWrap: "break-word",
                                          }}
                                          align="left"
                                        >
                                          <small>
                                            {value.Title}-{value.Institute}
                                          </small>
                                        </Typography>
                                      </Stack>
                                    </div>
                                  </div>
                                </Grid>
                              )
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {dataCollection.Certifications != null &&
                          dataCollection?.Certifications.length !== 0 && (
                            <Grid
                              container
                              wrap="nowrap"
                              direction="row"
                              justifyContent="flex-start"
                            >
                              <Grid item md={1}></Grid>
                              <Grid item md={8}>
                                <div
                                  style={{
                                    textAlign: "left",
                                    textTransform: "uppercase",
                                    fontWeight: Object.values(themeCv.font)[0],
                                    fontSize: Object.values(
                                      themeCv.size
                                    )[0].concat("px"),

                                    color: themeCv.sketchPickerColor,
                                    lineHeight: 3.6,
                                  }}
                                >
                                  Certification
                                </div>
                              </Grid>
                            </Grid>
                          )}
                        {dataCollection.Certifications != null &&
                        dataCollection.Certifications.length !== 0 ? (
                          <div>
                            {Object.entries(dataCollection.Certifications).map(
                              ([key, value], userCertificationIndex) => (
                                <Grid key={userCertificationIndex}>
                                  <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-4  ">
                                      <Typography align="left">
                                        <small>{value.Period}</small>
                                      </Typography>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-5 ">
                                      <Stack
                                        justifyContent="flex-start"
                                        direction="row"
                                        spacing={1}
                                      >
                                        <Typography align="left">
                                          <small>
                                            {value.Title}-{value.Institute}
                                          </small>
                                        </Typography>
                                      </Stack>
                                    </div>
                                  </div>
                                </Grid>
                              )
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {dataCollection?.Languages != null &&
                          dataCollection?.Languages.length !== 0 && (
                            <Grid
                              container
                              wrap="nowrap"
                              direction="row"
                              justifyContent="flex-start"
                            >
                              <Grid item md={1}></Grid>
                              <Grid item md={8}>
                                <div
                                  style={{
                                    textAlign: "left",
                                    textTransform: "uppercase",
                                    fontWeight: Object.values(themeCv.font)[0],
                                    fontSize: Object.values(
                                      themeCv.size
                                    )[0].concat("px"),

                                    color: themeCv.sketchPickerColor,
                                    lineHeight: 3.6,
                                  }}
                                >
                                  Langues
                                </div>
                              </Grid>
                            </Grid>
                          )}
                        {dataCollection?.Languages != null &&
                        dataCollection?.Languages.length !== 0 ? (
                          <div>
                            {Object.entries(dataCollection.Languages).map(
                              ([key, value], userLanguageIndex) => (
                                <Grid key={userLanguageIndex}>
                                  <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-4  ">
                                      <Typography align="left">
                                        <small>{value.Language}</small>
                                      </Typography>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-5 ">
                                      <Stack
                                        justifyContent="flex-start"
                                        direction="row"
                                        spacing={1}
                                      >
                                        <Typography align="left">
                                          <small> {value.Level}</small>
                                        </Typography>
                                      </Stack>
                                    </div>
                                  </div>
                                </Grid>
                              )
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {dataCollection?.Hobbies != null &&
                          dataCollection?.Hobbies.length !== 0 &&
                          themeCv?.showHobbies?.Content && (
                            <Grid
                              container
                              wrap="nowrap"
                              direction="row"
                              justifyContent="flex-start"
                            >
                              <Grid item md={1}></Grid>
                              <Grid item md={8}>
                                <div
                                  style={{
                                    textAlign: "left",
                                    textTransform: "uppercase",
                                    fontWeight: Object.values(themeCv.font)[0],
                                    fontSize: Object.values(
                                      themeCv.size
                                    )[0].concat("px"),

                                    color: themeCv.sketchPickerColor,
                                    lineHeight: 3.6,
                                  }}
                                >
                                  Loisirs
                                </div>
                              </Grid>
                            </Grid>
                          )}
                        {dataCollection?.Hobbies != null &&
                        dataCollection?.Hobbies.length !== 0 &&
                        themeCv?.showHobbies?.Content ? (
                          <div>
                            {Object.entries(dataCollection.Hobbies).map(
                              ([key, value], userHobbyIndex) => (
                                <Grid key={userHobbyIndex}>
                                  <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-4  ">
                                      <Typography align="left">
                                        <small>{value.Name}</small>
                                      </Typography>
                                    </div>
                                  </div>
                                </Grid>
                              )
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {dataCollection.Experiences != null &&
                          dataCollection.Experiences.length !== 0 && (
                            <Grid
                              container
                              wrap="nowrap"
                              direction="row"
                              justifyContent="flex-start"
                            >
                              <Grid item md={1}></Grid>
                              <Grid item md={8}>
                                <div
                                  style={{
                                    textAlign: "left",
                                    textTransform: "uppercase",
                                    fontWeight: Object.values(themeCv.font)[0],
                                    fontSize: Object.values(
                                      themeCv.size
                                    )[0].concat("px"),

                                    color: themeCv.sketchPickerColor,
                                    lineHeight: 3.6,
                                  }}
                                >
                                  PARCOURS PROFESSIONNEL
                                </div>
                              </Grid>
                            </Grid>
                          )}
                        {dataCollection.Experiences != null &&
                        dataCollection.Experiences.length !== 0 ? (
                          <div>
                            {Object.entries(dataCollection.Experiences).map(
                              ([key, value], userExperienceIndex) => (
                                <Grid
                                  key={userExperienceIndex}
                                  container
                                  wrap="nowrap"
                                  direction="row"
                                  justifyContent="flex-start"
                                >
                                  <Grid item md={1}></Grid>
                                  <Grid item md={10}>
                                    <Box align="left">
                                      <div
                                        style={{
                                          backgroundColor: Object.values(
                                            themeCv.theme
                                          )[0],
                                          border: "1px solid #000",
                                          height: 25,
                                        }}
                                      >
                                        <div
                                          style={{
                                            textAlign: "center",
                                            color: "white",
                                            lineHeight: 1.3,
                                          }}
                                        >
                                          <Typography
                                            align="left"
                                            style={{
                                              textTransform: "uppercase",
                                            }}
                                          >
                                            {value.Company}
                                          </Typography>
                                        </div>
                                      </div>
                                    </Box>
                                    <Box mt={1} mb={2}>
                                      <Box align="left">
                                        <h3
                                          style={{
                                            fontSize: "small",
                                            fontWeight: "bold",
                                            justifyContent: "start",
                                          }}
                                        >
                                          {value.Period}--{value.Position}
                                        </h3>
                                      </Box>
                                    </Box>
                                    <Box mt={4}>
                                      <Grid key={key}>
                                        <div className="row">
                                          <div className="col-md-11 ">
                                            <Stack
                                              justifyContent="left"
                                              direction="row"
                                              spacing={1}
                                            >
                                              <Box align="left">
                                                <Box mt={0}>
                                                  {value.Description.map(
                                                    (x, index) => (
                                                      <Box
                                                        key={index}
                                                        align="left"
                                                      >
                                                        <Stack
                                                          spacing={2}
                                                          ml={3}
                                                          mt={-2}
                                                          direction="row"
                                                        >
                                                          <div
                                                            style={{
                                                              fontSize: "small",
                                                              justifyContent:
                                                                "start",
                                                            }}
                                                          >
                                                            {x.Context.map(
                                                              (y, index) => (
                                                                <Typography
                                                                  key={index}
                                                                  style={{
                                                                    pageBreakInside:
                                                                      "avoid",
                                                                    fontSize:
                                                                      "small",
                                                                    fontWeight:
                                                                      "bold",
                                                                    justifyContent:
                                                                      "start",
                                                                    marginBottom:
                                                                      "1rem",
                                                                    whiteSpace:
                                                                      "break-spaces",
                                                                  }}
                                                                >
                                                                  {y}
                                                                </Typography>
                                                              )
                                                            )}
                                                          </div>
                                                        </Stack>
                                                        <Stack
                                                          spacing={2}
                                                          ml={3}
                                                          mt={-2}
                                                          direction="row"
                                                        >
                                                          <ul>
                                                            {x.Details?.map(
                                                              (det, key3) => (
                                                                <li key={key3}>
                                                                  <Typography
                                                                    style={{
                                                                      pageBreakInside:
                                                                        "avoid",
                                                                      fontSize:
                                                                        "small",
                                                                      justifyContent:
                                                                        "start",
                                                                      marginBottom:
                                                                        "1rem",
                                                                      whiteSpace:
                                                                        "break-spaces",
                                                                    }}
                                                                  >
                                                                    {det}
                                                                  </Typography>
                                                                </li>
                                                              )
                                                            )}
                                                          </ul>
                                                        </Stack>
                                                      </Box>
                                                    )
                                                  )}
                                                </Box>
                                              </Box>
                                            </Stack>
                                            <Stack
                                              spacing={2}
                                              ml={2}
                                              mt={3}
                                              mb={2}
                                              direction="row"
                                            >
                                              <p
                                                style={{
                                                  color: Object.values(
                                                    themeCv.theme
                                                  )[0],
                                                  fontSize: "small",
                                                  fontWeight: "bold",
                                                  justifyContent: "start",
                                                }}
                                              >
                                                Stack technique:
                                              </p>
                                              <Stack
                                                spacing={1}
                                                direction="row"
                                              >
                                                {value.Technologies?.map(
                                                  (x, indexTechnologie) => (
                                                    <p
                                                      style={{
                                                        color: Object.values(
                                                          themeCv.theme
                                                        )[0],
                                                        fontSize: "small",
                                                        fontWeight: "bold",
                                                        justifyContent: "start",
                                                      }}
                                                      key={indexTechnologie}
                                                    >
                                                      {x +
                                                        (indexTechnologie
                                                          ? ","
                                                          : "")}
                                                    </p>
                                                  )
                                                )}
                                              </Stack>
                                            </Stack>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </Paper>
                    </div>
                  </div>
                </Page>
              </Document>
              <div
                style={{
                  position: "sticky",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "40px",
                  background:
                    "linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff)",
                }}
              />
            </div>
          ) : (
            <div style={{ height: "100%" }}>
              <Paper
                elevation={7}
                style={{
                  marginTop: 8,
                  marginBottom: 8,
                  padding: 24,
                  minHeight: "100%",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: docContent }}
                  style={{
                    fontFamily: "Open Sans, sans-serif, Arial",
                    lineHeight: "1.4",
                    fontSize: "15px",
                  }}
                />
              </Paper>
              <div
                style={{
                  position: "sticky",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "40px",
                  background:
                    "linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff)",
                }}
              />
            </div>
          )}
        </div>
        <Snackbar
          open={openSnack}
          autoHideDuration={5000}
          onClose={() => setOpenSnack(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            onClose={() => setOpenSnack(false)}
            severity="error"
            sx={{ width: "50%" }}
          >
            L'extension d'image doit être .jpg | .jpeg | .jfif | .pjpeg | .pjp |
            .png
          </MuiAlert>
        </Snackbar>
      </div>
      {showImageCropModal && (
        <ImageCropModal
          show={showImageCropModal}
          imageType="logo"
          handleClose={() => setShowImageCropModal(false)}
        />
      )}
      <UpdateContactFooterModal show={show} handleFootClose={handleFootClose} />
      {fileUrl && (
        <a href={fileUrl} download>
          Download
        </a>
      )}
    </div>
  );
}
