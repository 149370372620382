import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { setContactFooter } from "../../redux/actions/contactFooterAction";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { jwtDecode } from "jwt-decode";
import { Button, Grid, Tooltip, Typography } from "@mui/material";

export default function UpdateContactFooterModal({
  show,
  handleFootClose = () => {},
}) {
  const initialState = {
    Adresse: { Content: "" },
    telephoneOne: { Content: "" },
    telephoneTwo: { Content: "" },
    EntrepriseType: { Content: "" },
    PublicRegister: { Content: "" },
    Mail: { Content: "" },
    InternetLink: { Content: "" },
    NomEntreprise: { Content: "" },
    CouleurLien: { Content: "" },
  };

  const [footerFields, setFooterFields] = useState(initialState);
  const [premiumEnabled, setPremiumEnabled] = useState(false);

  const contactFooter = useSelector((state) => state.contactFooter);
  const userToken = useSelector((state) => state.user.token);

  const dispatch = useDispatch();

  const onChangecontactFooter = (event, member) => {
    setFooterFields((prevState) => ({
      ...prevState,
      [member]: { Content: event.target.value },
    }));
  };

  const onRegisterButton = () => {
    dispatch(setContactFooter({ ...contactFooter, ...footerFields }));
    handleFootClose();
  };

  useEffect(() => {
    setFooterFields(contactFooter);
  }, [show]);

  useEffect(() => {
    if (userToken) {
      const decodedToken = jwtDecode(userToken);
      setPremiumEnabled(decodedToken.is_premium);
    } else {
      setPremiumEnabled(false);
    }
  }, [userToken]);

  return (
    <Modal show={show} onHide={handleFootClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Pied de Page
          </Typography>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="label" fontWeight="bold">
                Adresse
              </Typography>
              <Form.Control
                size="sm"
                type="text"
                value={footerFields.Adresse.Content}
                onChange={(event) => onChangecontactFooter(event, "Adresse")}
                placeholder="Adresse"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="label" fontWeight="bold">
                Téléphone 1
              </Typography>
              <Form.Control
                size="sm"
                type="text"
                value={footerFields.telephoneOne.Content}
                onChange={(event) => onChangecontactFooter(event, "telephoneOne")}
                placeholder="Téléphone 1"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="label" fontWeight="bold">
                Téléphone 2
              </Typography>
              <Form.Control
                size="sm"
                type="text"
                value={footerFields.telephoneTwo.Content}
                onChange={(event) => onChangecontactFooter(event, "telephoneTwo")}
                placeholder="Téléphone 2"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="label" fontWeight="bold">
                Type d'entreprise
              </Typography>
              <Form.Control
                size="sm"
                type="text"
                value={footerFields.EntrepriseType.Content}
                onChange={(event) => onChangecontactFooter(event, "EntrepriseType")}
                placeholder="Type d'entreprise"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="label" fontWeight="bold">
                Register Publique
              </Typography>
              <Form.Control
                size="sm"
                type="text"
                value={footerFields.PublicRegister.Content}
                onChange={(event) => onChangecontactFooter(event, "PublicRegister")}
                placeholder="Register Publique"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="label" fontWeight="bold">
                Mail
              </Typography>
              <Form.Control
                size="sm"
                type="text"
                value={footerFields.Mail.Content}
                onChange={(event) => onChangecontactFooter(event, "Mail")}
                placeholder="Mail"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="label" fontWeight="bold">
                Lien internet
              </Typography>
              <Form.Control
                size="sm"
                type="text"
                value={footerFields.InternetLink.Content}
                onChange={(event) => onChangecontactFooter(event, "InternetLink")}
                placeholder="Lien internet"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="label" fontWeight="bold">
                Nom Entreprise
              </Typography>
              <Tooltip title="Premium requis" arrow>
                <InfoOutlinedIcon style={{ marginLeft: "5px" }} />
              </Tooltip>
              <Form.Control
                size="sm"
                type="text"
                value={footerFields.NomEntreprise.Content}
                onChange={(event) => onChangecontactFooter(event, "NomEntreprise")}
                placeholder="Nom Entreprise"
                disabled={!premiumEnabled}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Form.Control
                size="sm"
                type="text"
                onChange={(event) =>
                  onChangecontactFooter("CouleurLien")
                }
                value={Object.values(footerFields.InternetLink)[0]}
                placeholder="Couleur mail & Lien"
                autoFocus
              />
            </Grid> */}
          </Grid>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="contained"
          style={{
            textTransform: "capitalize",
            border: "2px solid #DA2889",
            borderRadius: "5px",
            fontWeight: "bold",
            backgroundColor: "#DA2889",
            color: "white",
          }}
          onClick={onRegisterButton}
        >
          Enregistrer Changements
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
