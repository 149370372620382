import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import "./drop-file-input.css";
import "./PdfViewrStyle.css";
import uploadImg from "../../assets/cloud-upload-regular-240.png";

import CvService from "../../service/CvService";
import CreateCvIllustration from "../../assets/create.jpg";
import errorImage from "../../assets/error.jpg";
import IditorCvIllustration from "../../assets/editorViewr.PNG"; // Core viewer

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import "@react-pdf-viewer/core/lib/styles/index.css";
import PdfReader from "./PdfReader";
import Typography from "@mui/material/Typography";
import {
  setDataCollection,
  setManualMode,
  setUserDataAgreement,
} from "../../redux/actions/dataCollectionAction";
import { setPdfFileData } from "../../redux/actions/pdfFileAction";
import { Box, FormControlLabel, Tooltip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import axios from "axios";
import config from "../../config";
import { jwtDecode } from "jwt-decode";

const DropFileInput = (props) => {
  // Create new plugin instance
  const { onDropFile = () => {}, nextPage = () => {} } = props;

  // for onchange event
  const [pdfFile, setPdfFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const dataCollection = useSelector((state) => state.dataCollection);
  const userToken = useSelector((state) => state.user.token);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const wrapperRef = useRef(null);
  const targetRef = useRef(null);

  const fileType = [
    "application/pdf",
    // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  useEffect(() => {
    fetchAcceptedTerms();
  }, [userToken]);

  const fetchAcceptedTerms = async () => {
    if (!userToken) {
      return;
    }

    if (userToken) {
      const decodedToken = jwtDecode(userToken);
      if (decodedToken.ldap_user) {
        return;
      }

      try {
        const url = `${config.urlBackOffice}/get-terms/`;
        const response = await axios.get(url, {
          params: { user_id: decodedToken.user_id },
        });

        if (response.status === 200) {
          const { acceptedTerms } = response.data;
          dispatch(setUserDataAgreement(acceptedTerms));
        } else {
          console.error("Error fetching acceptedTerms:", response.data.error);
        }
      } catch (error) {
        console.error("Error fetching acceptedTerms:", error);
      }
    }
  };

  const handleCheckBoxChange = async (event) => {
    if (dataCollection.userDataAgreement) {
      if (!userToken) {
        dispatch(setUserDataAgreement(!dataCollection.userDataAgreement));
        return;
      }

      if (userToken) {
        const decodedToken = jwtDecode(userToken);
        if (decodedToken.ldap_user) {
          dispatch(setUserDataAgreement(!dataCollection.userDataAgreement));
          return;
        }

        try {
          const payload = {
            user_id: decodedToken.user_id,
            terms: false,
          };
          const url = `${config.urlBackOffice}/accept-terms/`;
          const response = await axios.put(url, payload);
          fetchAcceptedTerms();
        } catch (error) {
          console.error("Erreur en décochant les CGU:", error);
          if (error.response && error.response.data) {
            console.log(`Erreur: ${error.response.data.error}`);
          } else {
            console.log("Erreur en décochant les CGU");
          }
        }
      }
    } else {
      navigate("/terms-of-service");
    }
  };

  const handleClick = () => {
    dispatch(setManualMode(true));
    onDropFile(false);
    nextPage();
  };

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const formData = new FormData();

  const getAbbreviationName = (userName) => {
    const name = Object.values(userName == null ? "" : userName)[0];
    if (name !== "") {
      const abrName = name.match(/\b\w/g).join(" ");
      return abrName;
    } else {
      return "";
    }
  };

  const onFileDrop = (e) => {
    if (e.target && e.target.files[0]) {
      formData.append("file", e.target.files[0]);
    }

    const newFile = e.target.files[0];
    if (newFile) {
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
      props.onFileChange(updatedList);

      let selectedFile = e.target.files[0];

      if (selectedFile) {
        if (selectedFile && fileType.includes(selectedFile.type)) {
          let reader = new FileReader();
          reader.readAsDataURL(selectedFile);
          reader.onloadend = (e) => {
            if (selectedFile.type === "application/pdf") {
              setPdfFile(e.target.result);
              // localStorage.setItem(
              //   "pdfReaderData",
              //   JSON.stringify(e.target.result)
              // );
              dispatch(setPdfFileData(JSON.stringify(e.target.result)));
            }
            setFileError("");
            retrieveCV(selectedFile);
          };
        } else {
          setPdfFile(null);
          setShowPopUp(true);
          setFileError("Please select a valid PDF file");
        }
      } else {
        console.log("Select your file");
      }
    }
  };
  const retrieveCV = async (result) => {
    var formFile = new FormData();

    formFile.append("file", result);

    try {
      setLoading(true);

      const response = await CvService.create(formFile);

      if (targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }

      if (!response?.data?.status) {
        setLoading(false);
        const errorMessage = response?.data?.error
          ? response.data.error
          : "server Error";

        setFileError(errorMessage);

        setShowPopUp(true);
        return;
      }
      if (response.data.pdf_base64 != null) {
        setPdfFile("data:application/pdf;base64," + response.data.pdf_base64);
        // localStorage.setItem(
        //   "pdfReaderData",
        //   JSON.stringify(
        //     "data:application/pdf;base64," + response.data.pdf_base64
        //   )
        // );
        dispatch(
          setPdfFileData(
            JSON.stringify(
              "data:application/pdf;base64," + response.data.pdf_base64
            )
          )
        );
      }
      const abrName = getAbbreviationName(response?.data?.data?.Name);
      // setUser({
      //   ...response.data.data,
      //   fileName: result?.name,
      //   abbreviationName: { Content: abrName, Score: "" },
      // });
      // localStorage.setItem(
      //   "cvUser",
      //   JSON.stringify({
      //     ...response.data.data,
      //     fileName: result?.name,
      //     abbreviationName: { Content: abrName, Score: "" },
      //   })
      // );
      dispatch(
        setDataCollection({
          ...dataCollection,
          ...response.data.data,
          fileName: result?.name,
          abbreviationName: { Content: abrName, Score: "" },
        })
      );

      setLoading(false);
      onDropFile(false);
    } catch (error) {
      const errorMessage = error?.message ? error.message : "Network error";
      setShowPopUp(true);
      setFileError(errorMessage);
    }
  };

  const onClosePopUp = () => {
    setShowPopUp(false);
    setFileError("");
  };

  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="loader-content">
            <div className="loader"></div>
            <div className="loader-text">Loading...</div>
          </div>
        </div>
      )}

      {showPopUp && (
        <div>
          <div className="popup">
            <div className="popup-content">
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <img
                  style={{ width: 60, height: 60 }}
                  src={errorImage}
                  alt=""
                />
                <p style={{ color: "red", marginTop: 10 }}>{fileError}</p>
                <button
                  style={{ padding: 3, borderRadius: 10, marginTop: 10 }}
                  onClick={onClosePopUp}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <section>
        <div className="container">
          <Tooltip
            title={
              !dataCollection.userDataAgreement
                ? "Veuillez accepter les conditions générales d'utilisation"
                : ""
            }
          >
            <div className="row">
              <div
                className="col-lg-6 col-md-12 col-sm-12 mb-2"
                style={{
                  pointerEvents: !dataCollection.userDataAgreement
                    ? "none"
                    : "auto", // Disable interactions
                  opacity: !dataCollection.userDataAgreement ? 0.5 : 1, // Visually indicate disabled state
                }}
              >
                <div
                  ref={wrapperRef}
                  className="drop-file-input-next"
                  onClick={handleClick}
                >
                  <div className="drop-file-input__label-next">
                    <div className="d-none d-md-block">
                      <img
                        src={CreateCvIllustration}
                        alt=""
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </div>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: {
                          xs: "1.1rem",
                          md: "inherit",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      Commencez à créer votre CV dès le début
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 mx-auto">
                <div
                  ref={wrapperRef}
                  className="drop-file-input"
                  onDragEnter={onDragEnter}
                  onDragLeave={onDragLeave}
                  onDrop={onDrop}
                  style={{
                    pointerEvents: !dataCollection.userDataAgreement
                      ? "none"
                      : "auto", // Disable interactions
                    opacity: !dataCollection.userDataAgreement ? 0.5 : 1, // Visually indicate disabled state
                  }}
                >
                  <div className="drop-file-input__label-next">
                    <div className="d-none d-md-block">
                      <img
                        src={uploadImg}
                        alt=""
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </div>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: {
                          xs: "1.1rem",
                          md: "inherit",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      Déposez votre fichier PDF
                    </Typography>
                    <input
                      type="file"
                      className="form-control"
                      required
                      onChange={onFileDrop}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Tooltip>
        </div>
      </section>
      <section>
        <div
          style={{
            pointerEvents: pdfFile !== null ? "none" : "auto", // Disable interactions
            opacity: pdfFile !== null ? 0.5 : 1,
            marginTop: "10px", // Visually indicate disabled state
          }}
          className="drop-file-input col-lg-9 col-md-9 col-sm-10 mx-auto"
        >
          <Box
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlank />}
                    checked={dataCollection.userDataAgreement}
                    onChange={handleCheckBoxChange}
                  />
                }
              />
            </div>
            <Link
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              to="/terms-of-service"
            >
              Acceptez-vous les conditions générales d'utilisation de vos
              données personnelles.
            </Link>
          </Box>
        </div>
      </section>
      <section>
        <div className="container">
          {pdfFile ? (
            <div>
              <div
                style={{
                  border: "1px solid #4267b2",
                  borderRadius: "10px",
                  marginTop: "16px",
                  marginBottom: "16px",
                  maxHeight: "86vh",
                  overflowY: "auto",
                }}
              >
                <PdfReader pdfFile={pdfFile} />
              </div>
              <div ref={targetRef}></div>
            </div>
          ) : (
            <div>
              <Box sx={{}}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={IditorCvIllustration}
                    alt="PDF file icon"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </Box>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};

export default DropFileInput;
