export const styles = {
  skillsTitle: {
    fontstyle: "oblique",
    fontWeight: "bold",
    marginRight: "10px",
  },
  skillsNumber: {
    backgroundColor: "#DA2889",
    maxHeight: "23px",
    minHeight: "23px",
    minWidth: "23px",
    color: "white",
    borderRadius: "50%",
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addSkillButtonContainer: {
    maxHeight: "2.3em",
    minHeight: "2.0em",
    minWidth: "2em",
    fontSize: ".85em",
    textTransform: "capitalize",
    border: "2px solid transparent",
    borderColor: "#DA2889",
    color: "#DA2889",
    fontweight: "bold",
    backgroundColor: "white",
    borderRadius: "5px",
  },
  addSkillButtonItemsContainer: {
    display: "flex ",
    justifyContent: "space-between",
    alignItems: "center",
  },
  collapsButton: {
    maxHeight: "2.3em",
    minHeight: "2.0em",
    minWidth: "2em",
    fontSize: ".85em",
    textTransform: "capitalize",
    border: "2px solid transparent",
    borderColor: "#1976d2",
    color: "#1976d2",
    fontweight: "bold",
    backgroundColor: "white",
    borderRadius: "5px",
  },
  collapsButtonItemsContainer: {
    display: "flex ",
    justifyContent: "space-between",
    alignItems: "center",
  },
  skillNameValue: {
    fontWeight: "bold",
    fontSize: "medium",
    color: "#a9bef0",
  },
  skillsContainer: {
    width: "100%",
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
  },
  chipContainer: { padding: "5px" },
  chip: {
    backgroundColor: "#f3f4fa",
  },
  editButton: {
    maxHeight: "2.3em",
    minHeight: "2.0em",
    minWidth: "2em",
    textTransform: "capitalize",
    border: "2px solid transparent",
    borderColor: "#DA2889",
    color: "#DA2889",
    fontweight: "bold",
    backgroundColor: "white",
    borderRadius: "5px",
  },
  deleteButton: {
    maxHeight: "2.3em",
    minHeight: "2.0em",
    minWidth: "2em",
    textTransform: "capitalize",
    border: "2px solid transparent",
    borderColor: "#686868",
    color: "#686868",
    fontweight: "bold",
    backgroundColor: "white",
    borderRadius: "5px",
  },
};
