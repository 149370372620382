import React, { useRef } from "react";

import "./style.css";
import PropTypes from "prop-types";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { Template } from "./Template";

const Adaptation = () => {
  const reportTemplateRef = useRef(null);

  return (
    <div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-11" ref={reportTemplateRef}>
          <div>
            <Template />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8"></div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </div>
  );
};

export default Adaptation;
