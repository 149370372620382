import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const RootContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '60px',
}));

export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <RootContainer>
      <Typography sx={{ fontSize: '4rem', fontWeight: 'bold' }} color="#200A5F">
        404
      </Typography>
      <Typography sx={{ fontSize: '1.5rem', marginBottom: '20px' }}>
        Page non trouvée
      </Typography>
      <Typography>
        La page que vous recherchez n'existe pas.
      </Typography>
      <Button
        variant="contained"
        style={{ marginTop: '2em' }}
        sx={{
          backgroundColor: '#200A5F',
          color: 'white',
          '&:hover': {
            backgroundColor: '#321a8b',
          },
        }}
        onClick={() => navigate('/global')}
      >
        Retour
      </Button>
    </RootContainer>
  );
}
