export const SET_DATA_COLLECTION = "SET_DATA_COLLECTION";
export const RESET_DATA_COLLECTION = "RESET_DATA_COLLECTION";
export const SET_SKILLS = "SET_SKILLS";
export const SET_EDUCATIONS = "SET_EDUCATIONS";
export const SET_CERTIFICATIONS = "SET_CERTIFICATIONS";
export const SET_EXPERIENCES = "SET_EXPERIENCES";
export const SET_DESCRIPTION = "SET_DESCRIPTION";
export const SET_HOBBIES = "SET_HOBBIES";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const MANUAL_MODE = "SET_MANUAL_MODE";
export const SET_PROFIL_IMAGE = "SET_PROFIL_IMAGE";
export const SET_LOGO_IMAGE = "SET_LOGO_IMAGE";
export const SET_USER_DATA_AGREEMENT = "SET_USER_DATA_AGREEMENT";

export const setDataCollection = (data) => ({
  type: SET_DATA_COLLECTION,
  payload: { data },
});
export const setSkills = (skills) => ({
  type: SET_SKILLS,
  payload: { skills },
});
export const setUserDataAgreement = (agreement) => ({
  type: SET_USER_DATA_AGREEMENT,
  payload: { agreement },
});
export const setProfilImage = ({
  base64Url,
  profilImageName,
  prefixBase64Url,
}) => ({
  type: SET_PROFIL_IMAGE,
  payload: { base64Url, profilImageName, prefixBase64Url },
});
export const setLogoImage = ({
  base64Url,
  logoImageName,
  prefixBase64Url,
}) => ({
  type: SET_LOGO_IMAGE,
  payload: { base64Url, logoImageName, prefixBase64Url },
});
export const setEducation = (educationList) => ({
  type: SET_EDUCATIONS,
  payload: { educationList },
});
export const setCertifications = (certificationList) => ({
  type: SET_CERTIFICATIONS,
  payload: { certificationList },
});
export const setExperiences = (experienceList) => ({
  type: SET_EXPERIENCES,
  payload: { experienceList },
});
export const setDescription = (description) => ({
  type: SET_DESCRIPTION,
  payload: { description },
});
export const setHobbies = (hobbies) => ({
  type: SET_HOBBIES,
  payload: { hobbies },
});
export const setLanguages = (languages) => ({
  type: SET_LANGUAGES,
  payload: { languages },
});
export const setManualMode = (manualMode) => ({
  type: MANUAL_MODE,
  payload: { manualMode },
});
export const resetDataCollection = () => ({
  type: RESET_DATA_COLLECTION,
  payload: {},
});
