export const styles = {
  title: {
    color: "#fff",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    fontFamily: "FancyFont, sans-serif",
  },
  illustration: {
    width: "50px",
    height: "50px",
    marginRight: "10px",
    filter: "hue-rotate(150deg) saturate(200%)",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.2)",
      filter: "brightness(110%)",
    },
  },
  header: {
    background: "linear-gradient(to right, #200A5F, #6A0080)",
  },
  separator: {
    width: "100%",
    height: "5px",
    backgroundColor: "#f3f4fa",
    marginTop: "15px",
    marginBottom: "20px",
  },
  technologieHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  technologieTitle: {
    fontStyle: "oblique",
    backgroundColor: "#200A5F",
    color: "white",
    padding: "5px",
    borderRadius: "5px",
  },
  addTechnologieButton: {
    backgroundColor: "#a9bef0",
    color: "#003366",
    borderRadius: "5px",
  },
  addTechnologieButtonTitle: { fontSize: "x-small" },
  technologiesContainer: {
    display: "flex",
    paddingTop: "10px",
  },
  technologieInput: {
    backgroundColor: "white",
    border: "1px solid gray",
    borderRadius: "5px",
    padding: "10px",
    fontSize: "16px",
    width: "100%",
    height: "30px",
  },
  deleteButtonContainer: {
    fontSize: "small",
  },
  deleteButton: {
    color: "#DA2889",
  },
  descriptionHeader: {
    display: "flex",
    justifyContent: "space-between",
    margin: "5px 5px 5px 0px ",
  },
  descriptionTitleContainer: { paddingTop: "5px" },
  descriptionTitle: {
    fontStyle: "oblique",
    backgroundColor: "#200A5F",
    color: "white",
    padding: "5px",
    borderRadius: "5px",
  },
  addDescriptionButtonContainer: {
    backgroundColor: "#a9bef0",
    color: "#003366",
    borderRadius: "5px",
  },
  addDescriptionButtonTitle: { fontSize: "x-small" },
  descriptionCardContainer: {
    paddingTop: "10px",
    maxWidth: "90%",
    margin: "auto",
  },
  descriptionCardNumber: {
    fontSize: "30px",
    fontWeight: "bold",
    color: "#DA2889",
    WebkitBackgroundClip: "text",
    textAlign: "center",
  },
  deleteDescriptionCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    color: "#DA2889",
  },
  addContextButtonTitle: {
    fontSize: "xx-small",
    paddingLeft: "5px",
    paddingTop: "3px",
  },
  contextInput: {
    width: "100%",
    backgroundColor: "#f3f4fa",
    paddingLeft: "5px",
  },
  arrowsButtonsContainers: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addDetailButtonTitle: {
    fontSize: "xx-small",
    paddingLeft: "5px",
    paddingTop: "3px",
  },
  detailInput: {
    width: "100%",
    color: "#200A5F",
    paddingLeft: "5px",
    border: "1px solid #ccc",
  },
  submitButton: {
    textTransform: "capitalize",
    border: "2px solid transparent",
    borderColor: "#DA2889",
    fontweight: "bold",
    borderRadius: "5px",
    backgroundColor: "#DA2889",
    color: "white",
  },
};
