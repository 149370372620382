import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DropFileComponent from "../drop-file-input/DropFileComponent";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import StepperComponent from "../StepperComponent.js";
import {
  resetStepper,
  setActiveStep,
  setNextButtonStatus,
} from "../../redux/actions/stepperAction.js";
import { resetDataCollection } from "../../redux/actions/dataCollectionAction.js";
import { resetCvTheme } from "../../redux/actions/cvThemeAction.js";
import { resetContactFooter } from "../../redux/actions/contactFooterAction.js";
import { resetPdfFileData } from "../../redux/actions/pdfFileAction.js";
import { resetTemplate } from "../../redux/actions/templateActions.js";
import { resetNotification } from "../../redux/actions/notificationAction.js";

export default function HorizontalLinearStepper() {

  const activeStep = useSelector((state) => state.stepper.activeStep);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  

  useEffect(() => {
    dispatch(resetStepper());
    dispatch(resetDataCollection());
    dispatch(resetCvTheme());
    dispatch(resetPdfFileData());
    
    // dispatch(resetNotification());
    // dispatch(resetContactFooter());
    dispatch(resetTemplate());
  }, []);
  return (
    <div>
      <StepperComponent />
      <React.Fragment>
        <Box>
          <Typography component={"span"}>
            {activeStep === 0 && (
              <div>
                <div>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 5, sm: 2, md: 3 }}
                  >
                    <Grid item xs={1}></Grid>
                    <Grid item sx={{ mt: 8 }} xs={10}>
                      <DropFileComponent
                        nextPage={(fileName) => {
                          dispatch(setActiveStep(activeStep + 1));
                          navigate("/correction");
                        }}
                        onDropFile={(isActive) => {
                          dispatch(setNextButtonStatus(isActive));
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </Typography>
        </Box>
      </React.Fragment>
    </div>
  );
}
