import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import Contact from "./Contact";
import Langues from "./Langues";
import Hobbies from "./Hobbies";
import Avatar from "@mui/material/Avatar";
import CreateIcon from "@mui/icons-material/Create";
import Button from "@mui/material/Button";
import ButtonGroup from "../mainPage/ButtonGroup";
import Experience from "./panel/Experience";
import Description from "./panel/Description";
import Formation from "./panel/Formation";
import Certificate from "./panel/Certificat";
import Skills from "./panel/Skills";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Box, Tooltip } from "@mui/material";

import ImageProfil from "../../assets/default-user-avatar.jpg";
import { setProfilImage } from "../../redux/actions/dataCollectionAction";
import { jwtDecode } from "jwt-decode";
import ImageCropModal from "./image/ImageCropModal";

function GlobalCorrection() {
  const experiencesRef = useRef();
  const decriptionRef = useRef();
  const formationRef = useRef();
  const certificatRef = useRef();
  const skillsRef = useRef();
  const imageRef = useRef();
  const profilRef = useRef();
  const autresRef = useRef();
  const [collapsIcon, setCollapsIcon] = useState({
    contact: true,
    skills: true,
    formations: true,
    experiences: true,
    certifications: true,
    description: true,
    hobbies: true,
    Languages: true,
  });
  const [showImageCropModal, setShowImageCropModal] = useState(false);
  const [premiumEnabled, setPremiumEnabled] = useState(false);
  const dispatch = useDispatch();

  const dataCollection = useSelector((state) => state.dataCollection);
  const userToken = useSelector((state) => state.user.token);

  const getIntoSection = (section) => {
    if (section === "experiences") {
      experiencesRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (section === "description") {
      decriptionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (section === "formations") {
      formationRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (section === "certificat") {
      certificatRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (section === "skills") {
      skillsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (section === "profil" || section === "collapsDown") {
      profilRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (section === "autres") {
      autresRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (userToken) {
      const decodedToken = jwtDecode(userToken);
      setPremiumEnabled(decodedToken.is_premium);
    } else {
      setPremiumEnabled(false);
      dispatch(
        setProfilImage({
          base64Url: "",
          profilImageName: "",
          prefixBase64Url: "",
        })
      );
    }
  }, [userToken]);

  return (
    <>
      <div>
        <div>
          <ButtonGroup
            onSectionCall={getIntoSection}
            collapsIcon={collapsIcon}
            setCollapsIcon={(collapsIconState) =>
              setCollapsIcon(collapsIconState)
            }
          />
        </div>
        <div ref={profilRef} className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {dataCollection?.profilImage.base64Url == "" ? (
                  <Box
                    sx={{
                      width: "151px",
                      height: "160px",
                      border: "2px dashed #ccc",
                      borderRadius: "4px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <img
                      alt="not fount"
                      width={"150px"}
                      height={"149px"}
                      src={ImageProfil}
                    />
                  </Box>
                ) : (
                  <img
                    alt="not fount"
                    width={"150px"}
                    height={"160px"}
                    style={{
                      // border: "2px solid #a9bef0",
                      // borderRadius: "4px",
                      objectFit: "contain",
                      // objectPosition: "center",
                      // filter: "drop-shadow(0 0 2px #DA2889)",
                    }}
                    src={`data:image/jpeg;base64,${dataCollection.profilImage.base64Url}`}
                    // src={dataCollection.profilImage.imageUrl}
                  />
                )}
                <div style={{ height: "220px" }}>
                  <Tooltip
                    title={!premiumEnabled ? "Premium requis" : ""}
                    arrow
                  >
                    <span>
                      <Button
                        onClick={() => setShowImageCropModal(true)}
                        style={{
                          maxHeight: "2.3em",
                          minHeight: "2.0em",
                          minWidth: "2em",
                          fontSize: ".85em",
                          textTransform: "capitalize",
                          border: "2px solid transparent",
                          borderColor: "#DA2889",
                          fontWeight: "bold",
                          borderRadius: "69px",
                          pointerEvents: !premiumEnabled ? "none" : "auto",
                          opacity: !premiumEnabled ? 0.5 : 1,
                          color: "white",
                        }}
                        sx={{ bgcolor: !premiumEnabled ? "gray" : "#DA2889" }}
                        disabled={!premiumEnabled}
                      >
                        <div className="row">
                          <div className="col-sm-1">
                            <CreateIcon
                              style={{
                                fontSize: "small",
                              }}
                            />
                          </div>
                        </div>
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div>
                {
                  //Check if message failed
                  dataCollection.Profile != null ? (
                    <div>
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          fontstyle: "oblique",
                          color: "#a9bef0",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {Object.values(dataCollection.Profile)[0]}
                      </p>
                    </div>
                  ) : (
                    <div> </div>
                  )
                }
              </div>
            </div>

            <div className="col-lg-4 col-md-5 col-12 mx-auto">
              <Contact
                collapsIcon={collapsIcon}
                setCollapsIcon={(collapsIconState) =>
                  setCollapsIcon(collapsIconState)
                }
              />
            </div>
          </div>
        </div>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 5, sm: 2, md: 3 }}
        ></Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 5, sm: 2, md: 3 }}>
          <Grid item md={0.8}></Grid>
          <Grid item xs={12} md={11}>
            <div style={{ padding: "30px" }}>
              <div style={{ paddingBottom: "20px" }} ref={skillsRef}>
                <Skills
                  collapsIcon={collapsIcon}
                  setCollapsIcon={(collapsIconState) =>
                    setCollapsIcon(collapsIconState)
                  }
                />
              </div>
              <div style={{ paddingBottom: "20px" }} ref={formationRef}>
                <Formation
                  collapsIcon={collapsIcon}
                  setCollapsIcon={(collapsIconState) =>
                    setCollapsIcon(collapsIconState)
                  }
                />
              </div>
              <div style={{ paddingBottom: "20px" }} ref={experiencesRef}>
                <Experience
                  collapsIcon={collapsIcon}
                  setCollapsIcon={(collapsIconState) =>
                    setCollapsIcon(collapsIconState)
                  }
                />
              </div>
              <div style={{ paddingBottom: "20px" }} ref={certificatRef}>
                <Certificate
                  collapsIcon={collapsIcon}
                  setCollapsIcon={(collapsIconState) =>
                    setCollapsIcon(collapsIconState)
                  }
                />
              </div>
              <div style={{ paddingBottom: "20px" }} ref={decriptionRef}>
                <Description
                  collapsIcon={collapsIcon}
                  setCollapsIcon={(collapsIconState) =>
                    setCollapsIcon(collapsIconState)
                  }
                />
              </div>
              <div>
                <div ref={autresRef} className="pb-4">
                  <Hobbies
                    collapsIcon={collapsIcon}
                    setCollapsIcon={(collapsIconState) =>
                      setCollapsIcon(collapsIconState)
                    }
                  />
                </div>
                <div>
                  <Langues
                    collapsIcon={collapsIcon}
                    setCollapsIcon={(collapsIconState) =>
                      setCollapsIcon(collapsIconState)
                    }
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {showImageCropModal && (
        <ImageCropModal
          show={showImageCropModal}
          imageType="profil"
          handleClose={() => setShowImageCropModal(false)}
        />
      )}
    </>
  );
}

export default GlobalCorrection;
