export const styles = {
  headerTitle: {
    fontstyle: "oblique",
    fontWeight: "bold",
    marginRight: "10px",
  },
  formationNumber: {
    backgroundColor: "#DA2889",
    maxHeight: "23px",
    minHeight: "23px",
    minWidth: "23px",
    color: "white",
    borderRadius: "50%",
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addButton: {
    maxHeight: "2.3em",
    minHeight: "2.0em",
    minWidth: "2em",
    fontSize: ".85em",
    textTransform: "capitalize",
    border: "2px solid transparent",
    borderColor: "#DA2889",
    color: "#DA2889",
    fontweight: "bold",
    backgroundColor: "white",
    borderRadius: "5px",
  },
  addIconContainer: {
    display: "flex ",
    justifyContent: "space-between",
    alignItems: "center",
  },
  collapsButton: {
    maxHeight: "2.3em",
    minHeight: "2.0em",
    minWidth: "2em",
    fontSize: ".85em",
    textTransform: "capitalize",
    border: "2px solid transparent",
    borderColor: "#1976d2",
    color: "#1976d2",
    fontweight: "bold",
    backgroundColor: "white",
    borderRadius: "5px",
  },
  collapsIconContainer: {
    display: "flex ",
    justifyContent: "space-between",
    alignItems: "center",
  },
  instituteLabel: {
    fontWeight: "bold",
    fontSize: "medium",
    color: "#a9bef0",
  },
  titleLabel: {
    fontSize: "small",
    justifyContent: "start",
    fontWeight: "bold",
  },
  periodLabel: {
    fontSize: "small",
    justifyContent: "start",
  },
  editButton: {
    maxHeight: "2.3em",
    minHeight: "2.0em",
    minWidth: "2em",
    textTransform: "capitalize",
    border: "2px solid transparent",
    borderColor: "#DA2889",
    color: "#DA2889",
    fontweight: "bold",
    backgroundColor: "white",
    borderRadius: "5px",
  },
  deleteButton: {
    maxHeight: "2.3em",
    minHeight: "2.0em",
    minWidth: "2em",
    textTransform: "capitalize",
    border: "2px solid transparent",
    borderColor: "#686868",
    color: "#686868",
    fontweight: "bold",
    backgroundColor: "white",
    borderRadius: "5px",
  },
};
