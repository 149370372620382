export const SET_TEMPLATE = 'SET_TEMPLATE';
export const RESET_TEMPLATE = 'RESET_TEMPLATE';

export const setTemplate = (templateURL, templateName) => ({
  type: SET_TEMPLATE,
  payload: { templateURL, templateName }
});

export const resetTemplate = () => ({
  type: RESET_TEMPLATE,
  payload: {},
});
