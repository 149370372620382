import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useMediaQuery } from "react-responsive";
import { setActiveStep } from "../redux/actions/stepperAction";
import defaultTemplate from "../Template/CV Modèle APTEA.docx";
import { resetTemplate } from "../redux/actions/templateActions.js";
import download from "js-file-download";
import CvService from "../service/CvService.js";
import { styled } from "@mui/material";

const StyledStepper = styled(Stepper)(({ theme }) => ({
  "& .MuiStepIcon-active": { color: "#DA2889" },
  "& .MuiStepIcon-completed": { color: "#DA2889" },
  "& .Mui-disabled .MuiStepIcon-root": { color: "#DA2889" },
}));

export default function StepperComponent() {
  const steps = ["Importation", "Correction", "Adaptation"];

  const activeStep = useSelector((state) => state.stepper.activeStep);
  const dataCollection = useSelector((state) => state.dataCollection);
  const contactFooter = useSelector((state) => state.contactFooter);
  const themeCv = useSelector((state) => state.themeCv);
  const userToken = useSelector((state) => state.user.token);
  const templateFile = useSelector((state) => state.template);
  const nextButtonStatus = useSelector(
    (state) => state.stepper.nextButtonStatus
  );
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const fullData = {
    ...dataCollection,
    ...contactFooter,
    ...themeCv,
  };

  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isStepOptional = (step) => {
    return step === 1;
  };

  const handleNext = async () => {
    if (activeStep < steps.length - 1) {
      dispatch(setActiveStep(activeStep + 1));
    }

    if (activeStep === steps.length - 1) {
      // Getting template and generating docx
      const formFile = new FormData();
      const fileName = dataCollection?.fileName
        ? dataCollection.fileName
        : dataCollection?.Name?.Content;
      const profilImage = dataCollection?.profilImage;
      const logoImage = dataCollection?.logoImage;
      const reduceFileName = fileName
        ? fileName.replace(/\s+/g, "_")
        : "exemple-cv";
      const fileUserName = fileName ? `CVerter-${reduceFileName}` : "CVerter";
      formFile.append("fileName", fileUserName);
      formFile.append("template", selectedTemplate);
      formFile.append("base64ImageString", profilImage.base64Url);
      formFile.append("profilImageName", profilImage.profilImageName);
      formFile.append("base64LogoImageString", logoImage.base64Url);
      formFile.append("logoImageName", logoImage.logoImageName);
      formFile.append("data", JSON.stringify(fullData));

      try {
        const headers = { Authorization: `Bearer ${userToken}` };
        const response = await CvService.generate(formFile, headers);
        const status = response?.status ?? true;
        if (status === false) return;

        const contentDisposition = response.headers["content-disposition"];
        if (contentDisposition) {
          const filename = contentDisposition.split("filename=")[1];
          download(response.data, filename);
        }
        const userDataAgreement = dataCollection.userDataAgreement ?? false;
        if (!userDataAgreement) {
          const data = JSON.stringify({
            fileName: fileUserName,
          });
          await CvService.agreementUser(data, headers);
        }
        dispatch(setActiveStep(3));
        if (activeStep + 1 === 3) {
          navigate("/end");
        }
      } catch (error) {
        console.error("Error generating CV:", error);
      }
      return;
    }

    if (activeStep + 1 === 1) {
      navigate("/correction");
    } else if (activeStep + 1 === 2) {
      navigate("/adaptation");
    }
  };

  const handleBack = () => {
    dispatch(setActiveStep(activeStep - 1));
    if (activeStep === 1) {
      navigate("/");
    } else if (activeStep === 2) {
      navigate("/correction");
    }
  };

  useEffect(() => {
    const fetchDefaultTemplate = async () => {
      try {
        const response = await fetch(defaultTemplate);
        const blob = await response.blob();
        const file = new File([blob], "CV Modèle APTEA.docx");
        setSelectedTemplate(file);
      } catch (error) {
        console.error("Failed to fetch default template:", error);
      }
    };

    const fetchTemplate = async (url, name) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], name);
        setSelectedTemplate(file);
      } catch (error) {
        console.error("Failed to fetch template:", error);
      }
    };

    if (activeStep === 2) {
      if (templateFile.templateURL) {
        fetchTemplate(templateFile.templateURL, templateFile.templateName);
      } else {
        fetchDefaultTemplate();
      }
    }
  }, [activeStep, templateFile]);

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 5, sm: 2, md: 3 }}>
        <Grid item xs={3}></Grid>
        <Grid item xs={8}>
          <Stack
            sx={{
              width: "80%",
              mt: 1,
              display: !isDesktopOrLaptop ? "none" : "flex",
            }}
            spacing={4}
          >
            <StyledStepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption"></Typography>
                  );
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel
                      // classes={{ label: classes.step_label_root }}
                      {...labelProps}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </StyledStepper>
          </Stack>
        </Grid>
      </Grid>
      {activeStep !== 3 && (
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          {activeStep !== 0 ? (
            <Button
              variant="outlined"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{
                ml: 2,
                mb: 2,
                bgcolor: "#200A5F",
                color: "white",
                fontSize: "12px",
                position: "fixed",
                bottom: 0,
                zIndex: 11,
                left: 0,
                ":hover": {
                  color: "#200A5F",
                  border: "1px solid #200A5F",
                },
              }}
              startIcon={<ArrowBackIosIcon />}
            >
              <div className="d-none d-md-block">Retour</div>
            </Button>
          ) : (
            <></>
          )}

          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            disabled={nextButtonStatus}
            id="nextIdButton"
            ref={buttonRef}
            // style={{ bgcolor: "#f3f4fa" }}
            variant="outlined"
            onClick={handleNext}
            sx={{
              mr: 2,
              mb: 2,
              bgcolor: nextButtonStatus ? "#808080" : "#200A5F",
              color: "white",
              fontSize: "12px",
              position: "fixed",
              bottom: 0,
              right: 0,
              zIndex: 11,
              ":hover": {
                color: "#200A5F",
                border: "1px solid #200A5F",
              },
            }}
            startIcon={<ArrowForwardIosIcon />}
          >
            {activeStep === steps.length - 1 ? (
              "Générer"
            ) : (
              <div className="d-none d-md-block">Suivant</div>
            )}
          </Button>
        </Box>
      )}
    </>
  );
}
