import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import logo from "../../assets/apteaLogo.png"; // with import
import { useNavigate } from "react-router-dom";
import LoginModal from "../logIn/LoginModal";
import AdminModal from "../admin/AdminModal";
import { useDispatch, useSelector } from "react-redux";
import { resetLoggedInUser } from "../../redux/actions/userActions";
import LogoutIcon from "@mui/icons-material/Logout";
import LockIcon from "@mui/icons-material/Lock";
import GroupsIcon from "@mui/icons-material/Groups";
import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { setLoggedOutNotif } from "../../redux/actions/notificationAction";
import ChangePasswordModal from "../logIn/ChangePasswordModal";
import { Divider } from "@mui/material";
import { resetContactFooter } from "../../redux/actions/contactFooterAction";
import { setUserDataAgreement } from "../../redux/actions/dataCollectionAction";

// const pages = ['Products', 'Pricing', 'Blog'];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar() {
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const [showAdminModal, setShowAdminModal] = React.useState(false);
  const userEmail = useSelector((state) => state.user.userEmail);
  const userToken = useSelector((state) => state.user.token);
  const [LDAPuser, setLDAPuser] = React.useState(false);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setAnchorEl(null);
    if (userToken) {
      const decodedToken = jwtDecode(userToken);
      setLDAPuser(decodedToken.ldap_user);
    } else {
      setLDAPuser(false);
    }
  }, [userToken]);

  const onHideLoginModal = () => {
    setShowLoginModal(false);
  };
  const onPremiumClick = () => {
    setShowLoginModal(true);
  };

  const onHideAdminModal = () => {
    setShowAdminModal(false);
  };
  const onGestionClick = () => {
    setShowAdminModal(true);
  };

  const handleOpenChangePasswordModal = () => {
    setChangePasswordModalOpen(true);
  };
  const handleCloseChangePasswordModal = () => {
    setChangePasswordModalOpen(false);
  };

  const navigate = useNavigate();

  const coursesPage = () => {
    navigate("/Configuration");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(resetLoggedInUser());
    dispatch(resetContactFooter());
    dispatch(setLoggedOutNotif(true));
    dispatch(setUserDataAgreement(false));
  };
  const goToMainPage = () => {
    navigate("/global");
  };

  return (
    <AppBar style={{ background: "#200A5F" }} position="static">
      <LoginModal show={showLoginModal} onHide={onHideLoginModal} />
      <AdminModal show={showAdminModal} onHide={onHideAdminModal} />

      <Toolbar disableGutters>
        <Typography
          variant="h6"
          noWrap
          component="a"
          onClick={goToMainPage}
          sx={{
            mx: 3,
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          <img src={logo} width="90" height="50" alt="Logo" />
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        <div>
          {!userEmail && (
            <Button
              sx={{
                background:
                  "linear-gradient(to bottom right, #EDE6EB,#62538f,#EDE6EB)",
                color: "white",
                padding: "5px 7px",
                cursor: "pointer",
                borderRadius: "4px",
                marginRight: "2rem", //"10vw"
                "@media (min-width: 768px)": {
                  marginRight: "10rem",
                },
                transition: "background-color 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.background =
                  "linear-gradient(to bottom right, #62538f, #EDE6EB, #62538f)";
                e.currentTarget.style.color = "black";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background =
                  "linear-gradient(to bottom right, #EDE6EB, #62538f, #EDE6EB)";
                e.currentTarget.style.color = "white";
              }}
              onClick={onPremiumClick}
            >
              Premium
            </Button>
          )}

          {LDAPuser && (
            <Button
              sx={{
                background: "#4A69A8",
                color: "white",
                padding: "6px 8px",
                cursor: "pointer",
                borderRadius: "4px",
                marginRight: "2rem",
                transition: "background-color 0.3s, transform 0.2s",
                "&:hover": {
                  background: "#6D88B4",
                },
                "@media (min-width: 768px)": {
                  marginRight: "10rem",
                },
              }}
              onClick={onGestionClick}
            >
              Gestion
              <GroupsIcon fontSize="small" sx={{ ml: 1 }} />
            </Button>
          )}
          {userEmail && (
            <>
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{ mr: 2 }}
              >
                <Avatar
                  sx={{
                    bgcolor: "#4A69A8",
                    "&:hover": { background: "#6D88B4" },
                    transition: "background-color 0.3s, transform 0.2s",
                  }}
                >
                  {userEmail.charAt(0).toUpperCase()}
                </Avatar>
              </IconButton>

              <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <Box px={2} py={1}>
                  {userEmail}
                </Box>
                <Divider color="gray" />
                {!LDAPuser && (
                  <MenuItem onClick={handleOpenChangePasswordModal}>
                    <LockIcon fontSize="small" sx={{ mr: 1 }} />
                    Changer son mot de passe
                  </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>
                  <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
                  Déconnexion
                </MenuItem>
              </Menu>
            </>
          )}
        </div>

        {/* <Box sx={{ flexGrow: 0, mx: 3 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={coursesPage} sx={{ p: 0 }}>
              <Avatar alt="C" src="/static/images/avatar/2.jpg" />
            </IconButton>
          </Tooltip>
        </Box> */}
      </Toolbar>

      <ChangePasswordModal
        open={isChangePasswordModalOpen}
        onClose={handleCloseChangePasswordModal}
      />
    </AppBar>
  );
}
export default ResponsiveAppBar;
