import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export default function SnackBar({
  openSnack,
  setOpenSnack,
  message,
  type = "info",
}) {
  const snackBarInitialState = {
    status: false,
    message: "",
    type: "",
  };
  return (
    <Snackbar
      open={openSnack}
      autoHideDuration={5000}
      onClose={() => setOpenSnack(snackBarInitialState)}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MuiAlert
        onClose={() => setOpenSnack(snackBarInitialState)}
        severity={type}
        sx={{ width: "100%" }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
