import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Button from "@mui/material/Button";
import { setDataCollection } from "../../../redux/actions/dataCollectionAction";
import {
  emailRegex,
  linkedInRegex,
  phoneRegex,
  yearsOfExperienceRegex,
} from "../../../constants";

export default function UpdateContactModal({
  show,
  handleClose = () => {},
  setContactFields = () => {},
  contactFields,
}) {
  const [errors, setErrors] = useState({
    emailError: "",
    phoneError: "",
    yearsOfExperienceError: "",
    linkedInError: "",
  });

  const dataCollection = useSelector((state) => state.dataCollection);

  const dispatch = useDispatch();
  const getAbbreviationName = (userName) => {
    const name = Object.values(userName == null ? "" : userName)[0];
    if (name !== "") {
      const abrName = name.match(/\b\w/g).join(" ");
      return abrName;
    } else {
      return "";
    }
  };
  const validateFields = () => {
    let emailError = "";
    let phoneError = "";
    let yearsOfExperienceError = "";
    let linkedInError = "";

    // Validate Email
    if (
      contactFields.Email.Content &&
      !emailRegex.test(contactFields.Email.Content)
    ) {
      emailError = "Le format du email est invalide.";
    }

    // Validate Phone Number
    if (
      contactFields.Phone.Content &&
      !phoneRegex.test(contactFields.Phone.Content)
    ) {
      phoneError =
        "Le numéro de téléphone est invalide. Veuillez entrer un numéro au format 0XXXXXXXXX ou +33XXXXXXXXX.";
    }

    if (
      contactFields.YearsOfExperience.Content &&
      !yearsOfExperienceRegex.test(contactFields.YearsOfExperience.Content)
    ) {
      yearsOfExperienceError =
        "Le nombre d'années d'expérinces doit être un digital.";
    }
    if (
      contactFields.Linkedin.Content &&
      !linkedInRegex.test(contactFields.Linkedin.Content)
    ) {
      linkedInError = "Le lien linkedIn n'est pas valide.";
    }

    setErrors({
      emailError,
      phoneError,
      yearsOfExperienceError,
      linkedInError,
    });

    // Return true if no errors
    return (
      !emailError && !phoneError && !yearsOfExperienceError && !linkedInError
    );
  };
  const handleRegisterButton = () => {
    if (validateFields()) {
      // Submit form data
      const abrName = getAbbreviationName(contactFields?.Name);
      dispatch(
        setDataCollection({
          ...dataCollection,
          ...contactFields,
          abbreviationName: { Content: abrName, Score: "" },
        })
      );
      handleClose("save");
    }
  };

  useEffect(() => {
    setErrors({
      emailError: "",
      phoneError: "",
      yearsOfExperienceError: "",
      linkedInError: "",
    });
  }, [show]);

  return (
    <Modal show={show} onHide={() => handleClose("reset")}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            bgcolor: "#200A5F",
            color: "#200A5F",
          }}
        >
          Contact
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Nom
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              value={
                Object.values(
                  contactFields.Name == null ? 0 : contactFields.Name
                )[0]
              }
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  Name: {
                    ...contactFields.Name,
                    Content: event.target.value,
                  },
                });
              }}
              placeholder="nom"
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Phone
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              // value={Object.values(user.Phone == null ? 0 : user.Phone)[0]}
              value={
                Object.values(
                  contactFields.Phone == null ? 0 : contactFields.Phone
                )[0]
              }
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  Phone: {
                    ...contactFields.Phone,
                    Content: event.target.value,
                  },
                });
              }}
              placeholder="phone"
              isInvalid={!!errors.phoneError}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phoneError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                E-mail
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              // value={Object.values(user.Email == null ? 0 : user.Email)[0]}
              value={
                Object.values(
                  contactFields.Email == null ? 0 : contactFields.Email
                )[0]
              }
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  Email: {
                    ...contactFields.Email,
                    Content: event.target.value,
                  },
                });
              }}
              placeholder="email"
              isInvalid={!!errors.emailError}
            />
            <Form.Control.Feedback type="invalid">
              {errors.emailError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Rôle
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              // value={
              //   Object.values(user.Profile == null ? 0 : user.Profile)[0]
              // }
              value={
                Object.values(
                  contactFields.Profile == null ? 0 : contactFields.Profile
                )[0]
              }
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  Profile: {
                    ...contactFields.Profile,
                    Content: event.target.value,
                  },
                });
              }}
              placeholder="rôle"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                {`Nombre d'années d'expérience(s)`}
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              value={
                Object.values(
                  contactFields.YearsOfExperience == null
                    ? 0
                    : contactFields.YearsOfExperience
                )[0]
                // Object.values(
                //   user.YearsOfExperience == null ? 0 : user.YearsOfExperience
                // )[0]
              }
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  YearsOfExperience: {
                    ...contactFields.YearsOfExperience,
                    Content: event.target.value,
                  },
                });
              }}
              placeholder="nombre d'années d'expérience(s)"
              isInvalid={!!errors.yearsOfExperienceError}
            />
            <Form.Control.Feedback type="invalid">
              {errors.yearsOfExperienceError}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Résidence Principale
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              value={
                Object.values(
                  contactFields.Address == null ? 0 : contactFields.Address
                )[0]
              }
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  Address: {
                    ...contactFields.Address,
                    Content: event.target.value,
                  },
                });
              }}
              placeholder="résidence principale"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Linkedin
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              value={
                Object.values(
                  contactFields.Linkedin == null ? 0 : contactFields.Linkedin
                )[0]
              }
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  Linkedin: {
                    ...contactFields.Linkedin,
                    Content: event.target.value,
                  },
                });
              }}
              placeholder="linkedin"
              isInvalid={!!errors.linkedInError}
            />
            <Form.Control.Feedback type="invalid">
              {errors.linkedInError}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-xs-3"></div>
        <Button
          style={{
            textTransform: "capitalize",
            border: "2px solid transparent",
            borderColor: "#DA2889",
            fontweight: "bold",
            borderRadius: "5px",
          }}
          sx={{ bgcolor: "#DA2889", color: "white" }}
          onClick={handleRegisterButton}
        >
          Enregistrer Changements
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
