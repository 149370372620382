import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Modal from "react-bootstrap/Modal";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import ImageCropper from "./ImageCropper";

export default function ImageCropModal({
  show,
  imageType,
  handleClose = () => {},
}) {
  return (
    <Modal show={show} onHide={() => handleClose("reset")}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            bgcolor: "#200A5F",
            color: "#200A5F",
          }}
        >
          {imageType == "profil"
            ? "Choisir et ajuster l'image du profil"
            : "Choisir et ajuster le logo"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid>
          <Grid>
            <ImageCropper handleClose={handleClose} imageType={imageType} />
          </Grid>
          <Grid></Grid>
        </Grid>
      </Modal.Body>
    </Modal>
  );
}
