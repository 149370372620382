import { RESET_LOGGED_IN_USER, SET_LOGGED_IN_USER } from '../actions/userActions';

const initialState = {
    userEmail: null,
    token: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGGED_IN_USER:
      return {
        ...state,
        userEmail: action.payload.userEmail,
        token: action.payload.token,
      };
    case RESET_LOGGED_IN_USER:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default userReducer;
