import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import ImageProfil from "../../../assets/photo_default.jpg";

import { getCroppedImg } from "./imageCrop";
import { useDispatch } from "react-redux";
import {
  setLogoImage,
  setProfilImage,
} from "../../../redux/actions/dataCollectionAction";
import SnackBar from "../../SnackBar";

const ImageCropper = ({ handleClose, imageType }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [openSnack, setOpenSnack] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const dataCollection = useSelector((state) => state.dataCollection);

  const imageRef = useRef();
  const dispatch = useDispatch();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onSelectFile = (event) => {
    const file = event.target.files[0];
    const fileName = file?.name ? file.name : "";
    const imageExtensions = [
      ".jpg",
      ".jpeg",
      ".jfif",
      ".pjpeg",
      ".pjp",
      ".png",
    ];
    const fileExtension = fileName
      .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2)
      .toLowerCase();
    const isAnImage = imageExtensions.includes(`.${fileExtension}`);
    if (!isAnImage) {
      setOpenSnack(true);
      return;
    } else {
      setOpenSnack(false);
    }

    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const regex = /^data:.*;base64,/;
        const dataPrefix = reader.result.match(regex)[0];

        const base64ImageString = reader.result.replace(/^data:.+;base64,/, "");
        if (imageType == "profil") {
          dispatch(
            setProfilImage({
              base64Url: base64ImageString,
              profilImageName: fileName,
              prefixBase64Url: dataPrefix,
            })
          );
        }
        if (imageType == "logo") {
          dispatch(
            setLogoImage({
              base64Url: base64ImageString,
              logoImageName: fileName,
              prefixBase64Url: dataPrefix,
            })
          );
        }
      };
    }
  };

  const cropImage = useCallback(async () => {
    getCroppedImg(imageSrc, croppedAreaPixels)
      .then((croppedBase64) => {
        const base64ImageString = croppedBase64.replace(/^data:.+;base64,/, "");

        if (imageType == "profil") {
          dispatch(
            setProfilImage({
              ...dataCollection.profilImage,
              base64Url: base64ImageString,
            })
          );
        }
        if (imageType == "logo") {
          dispatch(
            setLogoImage({
              ...dataCollection.logoImage,
              base64Url: base64ImageString,
            })
          );
        }
        handleClose();
      })
      .catch((error) => {
        console.error("Error cropping image:", error);
      });
  }, [imageSrc, croppedAreaPixels]);

  useEffect(() => {
    if (imageType == "profil" && dataCollection.profilImage.base64Url) {
      setImageSrc(
        dataCollection.profilImage.prefixBase64Url +
          dataCollection.profilImage.base64Url
      );
    }
    if (imageType == "logo" && dataCollection.logoImage.base64Url) {
      setImageSrc(
        dataCollection.logoImage.prefixBase64Url +
          dataCollection.logoImage.base64Url
      );
    }
  }, [dataCollection.profilImage, dataCollection.logoImage]);

  return (
    <>
      <div>
        <div
          style={{
            margin: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => imageRef.current.click()}
            style={{
              maxHeight: "2.3em",
              minHeight: "2.0em",
              minWidth: "10em",
              fontSize: ".85em",
              textTransform: "capitalize",
              border: "2px solid transparent",
              borderColor: "#DA2889",
              fontWeight: "bold",
              borderRadius: "10px",
              pointerEvents: "auto",
              opacity: 1,
              color: "white",
            }}
            sx={{ bgcolor: "#DA2889" }}
          >
            <div className="row">
              <div className="col-sm-12">
                <div>Selectionnez une image</div>
                <input
                  ref={imageRef}
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  name="selectedImage"
                  onChange={onSelectFile}
                />
              </div>
            </div>
          </Button>
        </div>
        {imageSrc ? (
          <>
            <div style={{ position: "relative", width: "100%", height: 400 }}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={imageType == "logo" ? 4 / 3 : 4 / 5}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            <div>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
          </>
        ) : (
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 400,
              backgroundColor: "#D3D3D3",
            }}
          >
            <img
              alt="not fount"
              width={"100%"}
              height={"400px"}
              src={ImageProfil}
            />
          </div>
        )}

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Button
            style={{
              textTransform: "capitalize",
              border: "2px solid transparent",
              borderColor: "#DA2889",
              fontweight: "bold",
              borderRadius: "5px",
            }}
            sx={{ bgcolor: "#DA2889", color: "white" }}
            onClick={cropImage}
          >
            Enregistrer
          </Button>
        </div>
      </div>
      <SnackBar
        openSnack={openSnack}
        setOpenSnack={(value) => setOpenSnack(value)}
        message={`L'extension d'image doit être .jpg | .jpeg | .jfif | .pjpeg | .pjp |
        .png`}
        type="error"
      />
    </>
  );
};
export default ImageCropper;
