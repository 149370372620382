import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";

import logo from "../../assets/finish.png";
import { setActiveStep } from "../../redux/actions/stepperAction";

export default function EndOperation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const handleReset = () => {
    dispatch(setActiveStep(0));
    navigate("/");
  };
  return (
    <React.Fragment>
      {/* All steps completed  */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "60vh",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: "10%", mb: 1 }}
        >
          <img
            style={{
              maxWidth: isSmallScreen ? "100%" : "40%",
              height: "auto",
              marginBottom: "20px",
            }}
            src={logo}
          />
        </Box>

        <Button
          style={{
            padding: "10px 20px",
            fontSize: isSmallScreen ? "12px" : "16px",
            backgroundColor: "#200A5F",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            ":hover": {
              color: "#200A5F",
              border: "1px solid #200A5F",
            },
          }}
          onClick={handleReset}
        >
          Nouveau CV
        </Button>
      </div>
    </React.Fragment>
  );
}
