import React, { useEffect } from "react";
import { Navigate, Routes, Route, useLocation } from "react-router-dom";

import "./App.css";

import Global from "./components/global/Global";
import Correction from "./pages/Correction";
import logo from "../src/assets/apteaLogo2.png";

import Adaptation from "./pages/AdaptationPage";
import EndOperation from "./components/mainPage/EndOperation";
import NotFoundPage from "./pages/NotFoundPage";
import ResetPassword from "./components/logIn/ResetPassword";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoggedInNotif,
  setLoggedOutNotif,
  setPasswordResetNotif,
} from "./redux/actions/notificationAction";
import { Box } from "@mui/material";
import TermsOfService from "./pages/TermsOfService";
import TemplateGuide from "./pages/TemplateGuide";
import OriginalCv from "./pages/OriginalCv";
import { setLogoImage } from "./redux/actions/dataCollectionAction";
import config from "./config";
import Test from "./Test";

function App() {
  const loggedInNotif = useSelector(
    (state) => state.notification.loggedInNotif
  );
  const loggedOutNotif = useSelector(
    (state) => state.notification.loggedOutNotif
  );
  const passwordResetNotif = useSelector(
    (state) => state.notification.passwordResetNotif
  );
  const [openLoggedInSnackbar, setOpenLoggedInSnackbar] = React.useState(false);
  const [openLoggedOutSnackbar, setOpenLoggedOutSnackbar] =
    React.useState(false);
  const [openPasswordResetSnackbar, setOpenPasswordResetSnackbar] =
    React.useState(false);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (loggedInNotif) {
      setOpenLoggedInSnackbar(true);
      dispatch(setLoggedInNotif(false));
    }
    if (passwordResetNotif) {
      setOpenPasswordResetSnackbar(true);
      dispatch(setPasswordResetNotif(false));
    }
    if (loggedOutNotif) {
      setOpenLoggedOutSnackbar(true);
      dispatch(setLoggedOutNotif(false));
    }
  }, [loggedInNotif, loggedOutNotif, passwordResetNotif]);

  const handleCloseLoggedInSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenLoggedInSnackbar(false);
  };

  const handleCloseLoggedOutSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenLoggedOutSnackbar(false);
  };

  const handleClosePasswordResetSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenPasswordResetSnackbar(false);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const convertImageToBase64 = async (path) => {
      try {
        const response = await fetch(path);
        const blob = await response.blob();
        const reader = new FileReader();

        reader.onloadend = () => {
          const regex = /^data:.*;base64,/;
          const dataPrefix = reader.result.match(regex)[0];
          const base64ImageString = reader.result.replace(
            /^data:.+;base64,/,
            ""
          );
          dispatch(
            setLogoImage({
              base64Url: base64ImageString,
              logoImageName: "apteaLogo.png",
              prefixBase64Url: dataPrefix,
            })
          );
        };

        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error converting image to Base64:", error);
      }
    };

    convertImageToBase64(logo);
  }, []);

  return (
    <Box sx={{ minHeight: "81vh" }}>
      <Snackbar
        open={openLoggedInSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseLoggedInSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseLoggedInSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Vous êtes connecté
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={openLoggedOutSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseLoggedOutSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleCloseLoggedOutSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          Vous êtes déconnecté
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={openPasswordResetSnackbar}
        autoHideDuration={5000}
        onClose={handleClosePasswordResetSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleClosePasswordResetSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Le mot de passe a bien été défini
        </MuiAlert>
      </Snackbar>

      {!config.testMode ? (
        <Routes>
          {/* Default routes & 404 */}
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/" element={<Navigate to="/global" replace />} />

          {/* Application routes */}
          <Route path="/global" key="global" element={<Global />} />
          <Route path="/correction" key="correction" element={<Correction />} />
          <Route path="/adaptation" key="adaptation" element={<Adaptation />} />
          <Route path="/end" key="end" element={<EndOperation />} />
          <Route
            path="/reset-password/:token"
            key="reset-password"
            element={<ResetPassword />}
          />
          <Route
            path="/terms-of-service"
            key="terms-of-service"
            element={<TermsOfService />}
          />
          <Route
            path="/original-cv"
            key="original-cv"
            element={<OriginalCv />}
          />
          <Route
            path="/template-guide"
            key="template-guide"
            element={<TemplateGuide />}
          />
        </Routes>
      ) : (
        <Test />
      )}
    </Box>
  );
}

export default App;
