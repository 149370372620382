import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Grid from "@mui/material/Grid";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { setLanguages } from "../../../redux/actions/dataCollectionAction";

export default function UpdateLanguagesModal({ show, handleClose = () => {} }) {
  const [languagesList, setLanguagesList] = useState([]);
  const [parametersLang, setParametersLang] = useState([]);
  const [parametersTechno, setParametersTechno] = useState([]);

  const dataCollection = useSelector((state) => state.dataCollection);

  const dispatch = useDispatch();

  const handelLanguage = (event, index) => {
    const editedLanguagesArray = languagesList?.map((lang, key) => {
      if (index.toString() === key.toString()) {
        return { ...lang, Language: event.target.value };
      } else {
        return lang;
      }
    });
    setLanguagesList(editedLanguagesArray);
  };

  const deleteItem = (index) => {
    const filtredLanguagesArray = languagesList?.filter(
      (lang, key) => index.toString() !== key.toString()
    );
    setLanguagesList(filtredLanguagesArray);
  };

  const handelLevel = (event, index) => {
    const editedLevelArray = languagesList?.map((lang, key) => {
      if (index.toString() === key.toString()) {
        return { ...lang, Level: event.target.value };
      } else {
        return lang;
      }
    });
    setLanguagesList(editedLevelArray);
  };
  const addFields = () => {
    setLanguagesList((prevState) => [
      ...prevState,
      { Language: "", Level: "" },
    ]);
    // setUser({ ...user, Languages: parentsArray });
  };

  const handleDeleteLanguage = () => {
    dispatch(setLanguages(languagesList));
    handleClose();
  };

  useEffect(() => {
    const parametersFromLocalStorage = JSON.parse(
      localStorage.getItem("parameters") || "[]"
    );

    const parametersReduced = parametersFromLocalStorage.reduce(
      (acc, parameter) => {
        if (parameter.name === "techno") {
          return {
            ...acc,
            parametersTechno: [...acc.parametersTechno, parameter],
          };
        } else if (parameter.name === "lang") {
          return { ...acc, parametersLang: [...acc.parametersLang, parameter] };
        }
        return acc;
      },
      { parametersLang: [], parametersTechno: [] }
    );

    setParametersLang(parametersReduced.parametersLang);
    setParametersTechno(parametersReduced.parametersTechno);
  }, []);
  useEffect(() => {
    if (
      dataCollection?.Languages != null &&
      dataCollection.Languages.length > 0
    ) {
      setLanguagesList(dataCollection?.Languages);
    } else {
      setLanguagesList([{ Language: "", Level: "" }]);
    }
  }, [show]);

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            bgcolor: "#200A5F",
            color: "#200A5F",
          }}
        >
          Langues
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <div className="row">
              <div className="row">
                <div className="col-md-3">
                  <Grid mb={2}>
                    <Button
                      sx={{ bgcolor: "#200A5F", color: "white" }}
                      onClick={addFields}
                    >
                      <AddCircleOutlineIcon fontSize="small" />
                      <span style={{ fontSize: "x-small" }}> Ajouter</span>
                    </Button>
                  </Grid>
                </div>
              </div>
              <div className="col-md-2">
                <Form.Label size="sm">
                  <span
                    style={{
                      fontstyle: "oblique",
                      bgcolor: "#200A5F",
                      color: "#200A5F",
                    }}
                  >
                    Langues:
                  </span>
                </Form.Label>
              </div>
              <div className="col-md-4">
                {
                  //Check if message failed
                  dataCollection?.Languages != null && languagesList != null ? (
                    <div>
                      {Object.entries(languagesList).map(([key, value]) => (
                        <Grid key={key} mb={1}>
                          <Form.Control
                            xm={1}
                            size="sm"
                            type="text"
                            value={value.Language}
                            onChange={(e) => handelLanguage(e, key)}
                            placeholder="language"
                            autoFocus
                          />
                        </Grid>
                      ))}
                    </div>
                  ) : (
                    <div> </div>
                  )
                }
              </div>
              <div className="col-md-2">
                <Form.Label size="sm">
                  <span
                    style={{
                      fontstyle: "oblique",
                      bgcolor: "#200A5F",
                      color: "#200A5F",
                    }}
                  >
                    Niveau:
                  </span>
                </Form.Label>
              </div>
              <div className="row col-md-4">
                <div className="col-10 col-md-10">
                  {
                    //Check if message failed
                    dataCollection?.Languages != null &&
                    languagesList != null ? (
                      <div>
                        {Object.entries(languagesList).map(([key, value]) => (
                          <Grid key={key} mb={1}>
                            <input
                              key={key}
                              size="sm"
                              type="text"
                              list="techList"
                              style={{
                                backgroundColor: "#F6F8FA",
                                border: "1px solid #E8E8E8",
                                borderRadius: "6px",
                                padding: "10px 15px",
                                fontSize: "16px",
                                width: "100%",
                                height: "31px",
                                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                                outline: "none",
                                transition: "all 0.3s ease",
                              }}
                              value={value.Level}
                              onChange={(e) => handelLevel(e, key)}
                              placeholder="level"
                              autoFocus
                            />
                            <datalist
                              id="techList"
                              style={{
                                position: "absolute",
                                top: "100%",
                                left: 0,
                                zIndex: 1,
                                background: "#fff",
                                border: "1px solid #E8E8E8",
                                borderRadius: "6px",
                                width: "100%",
                                maxHeight: "200px",
                                overflowY: "auto",
                                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              {parametersLang.map((param) => (
                                <option key={param.id} value={param.value} />
                              ))}
                            </datalist>
                          </Grid>
                        ))}{" "}
                      </div>
                    ) : (
                      <div> </div>
                    )
                  }
                </div>
                <div className="col-2 col-md-2">
                  {
                    //Check if message failed
                    dataCollection.Languages != null &&
                    languagesList != null ? (
                      <div>
                        {Object.entries(languagesList).map(([key, value]) => (
                          <Grid key={key} mb={1}>
                            <Grid mx={-3}>
                              <Button
                                onClick={() => {
                                  deleteItem(key);
                                }}
                              >
                                <span style={{ fontSize: "small" }}>x</span>
                              </Button>
                            </Grid>
                          </Grid>
                        ))}{" "}
                      </div>
                    ) : (
                      <div> </div>
                    )
                  }
                </div>
              </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-xs-3"></div>

        <Button
          style={{
            textTransform: "capitalize",
            border: "2px solid transparent",
            borderColor: "#DA2889",
            fontweight: "bold",
            borderRadius: "5px",
          }}
          sx={{ bgcolor: "#DA2889", color: "white" }}
          onClick={handleDeleteLanguage}
        >
          Enregistrer Changements
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
