import { combineReducers } from "redux";
import userReducer from "./userReducer";
import stepperReducer from "./stepperReducer";
import dataCollectionReducer from "./dataCollectionReducer";
import contactFooterReducer from "./contactFooterReducer";
import cvThemeReducer from "./cvThemeReducer";
import pdfFileReducer from "./pdfFileReducer";
import templateReducer from "./templateReducer";
import notificationReducer from "./notificationReducer";

const rootReducer = combineReducers({
  pdfFile: pdfFileReducer,
  themeCv: cvThemeReducer,
  contactFooter: contactFooterReducer,
  dataCollection: dataCollectionReducer,
  stepper: stepperReducer,
  template: templateReducer,
  user: userReducer,
  notification: notificationReducer,
});

export default rootReducer;
