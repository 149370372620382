import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import "bootstrap/dist/css/bootstrap.css";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditIcon from "@mui/icons-material/Edit";
import UpdateContactModal from "./panel/UpdateContactModal";
import { styles } from "./panel/contactStyles";

export default function Contact({ collapsIcon, setCollapsIcon = () => {} }) {
  const initialFieldsState = {
    Name: { Content: "", Score: "" },
    Phone: { Content: "", Score: "" },
    Email: {
      Content: "",
      Score: "",
    },
    Profile: {
      Content: "",
      Score: "",
    },
    YearsOfExperience: {
      Content: "",
      Score: "",
    },
    Address: {
      Content: "",
      Score: "",
    },
    Linkedin: {
      Content: "",
      Score: "",
    },
  };
  const [contactFields, setContactFields] = useState(initialFieldsState);
  const [savedContactFields, setsavedContactFields] = useState({});

  const dataCollection = useSelector((state) => state.dataCollection);

  const [show, setShow] = useState(false);

  if (window.UndefinedVariable) {
    Object.assign(window.UndefinedVariable, {});
  }

  const handleShow = () => setShow(true);

  const handleClose = (type) => {
    if (type == "reset") {
      setContactFields(savedContactFields);
    }
    setShow(false);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  if (window.UndefinedVariable) {
    Object.assign(window.UndefinedVariable, {});
  }

  useEffect(() => {
    if (dataCollection) {
      const contact = {
        Name: dataCollection.Name,
        Phone: dataCollection.Phone,
        Email: dataCollection.Email,
        Profile: dataCollection.Profile,
        YearsOfExperience: dataCollection.YearsOfExperience,
        Address: dataCollection?.Address
          ? dataCollection?.Address
          : { Content: "" },
        Linkedin: dataCollection.Linkedin,
      };
      setContactFields(contact);
      setsavedContactFields(contact);
    }
  }, [dataCollection]);

  return (
    <Card>
      <CardContent sx={styles.headerContact}>
        <div className="row ">
          <div
            className="col-3 col-md-4 col-lg-3"
            style={styles.headerTitleContainer}
          >
            <p style={styles.headerTitle}>Contact</p>
          </div>
          <div className="col-4 col-md-2 col-lg-4"></div>
          <div className="col-5 col-md-6 col-lg-5 d-flex justify-content-around align-items-center">
            <div>
              <Button
                style={styles.editButton}
                onClick={handleShow}
                sx={{ color: "white" }}
              >
                <div style={styles.editTitleContainer}>
                  <div>
                    <EditIcon fontSize="small" />
                  </div>
                </div>
              </Button>
            </div>
            <div>
              <Button
                style={styles.collapsButton}
                onClick={() => {
                  setCollapsIcon({
                    ...collapsIcon,
                    contact: !collapsIcon.contact,
                  });
                }}
                sx={{ color: "white" }}
              >
                <div style={styles.collapsButtonContainer}>
                  <div>
                    {collapsIcon.contact ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </CardContent>

      {collapsIcon.contact && (
        <Box sx={{ mt: 0, t: 50 }}>
          <Stack spacing={0.1}>
            <Item>
              <Stack spacing={1} ml={2} mt={1} direction="row">
                <p style={styles.nameLabel}>Nom:</p>

                <p className="item" style={styles.nameValue}>
                  {
                    Object.values(
                      contactFields.Name == null ? 0 : contactFields.Name
                    )[0]
                  }
                </p>
              </Stack>
              <Stack spacing={1} ml={2} mt={1} direction="row">
                <p style={styles.telLabel}>Tél:</p>

                <p className="item">
                  {
                    Object.values(
                      contactFields.Phone == null ? 0 : contactFields.Phone
                    )[0]
                  }
                </p>
              </Stack>
              <Stack spacing={1} ml={2} mt={1} direction="row">
                <p style={styles.emailLabel}>E-mail:</p>

                <p className="item" style={styles.emailValue}>
                  {
                    Object.values(
                      contactFields.Email == null ? 0 : contactFields.Email
                    )[0]
                  }
                </p>
              </Stack>
              <Stack spacing={1} ml={2} mt={1} direction="row">
                <p style={styles.roleLabel}>Rôle:</p>

                <p className="item" style={styles.roleValue}>
                  {
                    Object.values(
                      contactFields.Profile == null ? 0 : contactFields.Profile
                    )[0]
                  }
                </p>
              </Stack>
            </Item>
            <Item>
              <Stack spacing={1} ml={2} mt={1} direction="row">
                <p style={styles.experienceNumberLabel}>
                  {`Nombre d'années d'expérience(s):`}
                </p>

                <p className="item" style={styles.experienceNumberValue}>
                  {
                    Object.values(
                      contactFields.YearsOfExperience == null
                        ? 0
                        : contactFields.YearsOfExperience
                    )[0]
                  }
                </p>
              </Stack>

              <Stack spacing={2} ml={2} mt={1} direction="row">
                <p style={styles.addressLabel}>Résidence principale:</p>

                <p className="item" style={styles.addressValue}>
                  {
                    Object.values(
                      contactFields.Address == null ? 0 : contactFields.Address
                    )[0]
                  }
                </p>
              </Stack>
              <Stack spacing={2} ml={2} mt={1} mb={0.5} direction="row">
                <p style={styles.linkedInLabel}>Linkedin:</p>

                <p className="item" style={styles.linkedInValue}>
                  {
                    Object.values(
                      contactFields.Linkedin == null
                        ? 0
                        : contactFields.Linkedin
                    )[0]
                  }
                </p>
              </Stack>
            </Item>
          </Stack>
        </Box>
      )}

      <UpdateContactModal
        contactFields={contactFields}
        setContactFields={(fields) => {
          setContactFields(fields);
        }}
        show={show}
        handleClose={(type) => handleClose(type)}
      />
    </Card>
  );
}
