import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { setHobbies } from "../../../redux/actions/dataCollectionAction";

export default function UpdateHobbiesModal({ show, handleClose = () => {} }) {
  const dataCollection = useSelector((state) => state.dataCollection);
  const [hobbiesList, setHobbiesList] = useState(dataCollection?.Hobbies);

  const dispatch = useDispatch();

  const addFields = () => {
    setHobbiesList([
      ...hobbiesList,
      {
        Name: "",
      },
    ]);
  };

  const handelHobbies = (event, key) => {
    if (key !== -1) {
      const newArray = hobbiesList?.map((hobby, index) => {
        if (key.toString() === index.toString()) {
          return { ...hobby, Name: event.target.value };
        } else {
          return hobby;
        }
      });
      setHobbiesList(newArray);
    }
  };

  const deleteItem = (key) => {
    const filtredArray = hobbiesList?.filter(
      (_hobby, index) => key.toString() !== index.toString()
    );
    setHobbiesList(filtredArray);
  };

  const handleEditHobbies = () => {
    dispatch(setHobbies(hobbiesList));
    handleClose();
  };

  useEffect(() => {
    if (dataCollection?.Hobbies?.length !== 0) {
      setHobbiesList(dataCollection?.Hobbies);
    } else {
      setHobbiesList([{ Name: "" }]);
    }
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            bgcolor: "#200A5F",
            color: "#200A5F",
          }}
        >
          Loisirs
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <div className="row">
              <div className="row">
                <div className="col-md-3">
                  <Grid mb={2}>
                    <Button
                      sx={{ bgcolor: "#200A5F", color: "white" }}
                      onClick={addFields}
                    >
                      <AddCircleOutlineIcon fontSize="small" />
                      <span style={{ fontSize: "x-small" }}> Ajouter</span>
                    </Button>
                  </Grid>
                </div>
              </div>
              {
                //Check if message failed
                dataCollection.Hobbies != null ? (
                  <div>
                    {Object.entries(hobbiesList).map(([key, value]) => (
                      <div className="row" key={key}>
                        <div className="col-md-10">
                          <Grid mb={1}>
                            <Form.Control
                              xm={1}
                              size="sm"
                              type="text"
                              value={value.Name}
                              onChange={(e) => handelHobbies(e, key)}
                              placeholder="loisir"
                              autoFocus
                            />
                          </Grid>
                        </div>

                        <div className="col-md-1">
                          <div>
                            <Grid mb={1}>
                              <Grid mx={-3}>
                                <Button
                                  onClick={() => {
                                    deleteItem(key);
                                  }}
                                >
                                  <span style={{ fontSize: "small" }}>x</span>
                                </Button>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div> </div>
                )
              }
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-xs-3"></div>

        <Button
          style={{
            textTransform: "capitalize",
            border: "2px solid transparent",
            borderColor: "#DA2889",
            fontweight: "bold",
            borderRadius: "5px",
          }}
          sx={{ bgcolor: "#DA2889", color: "white" }}
          onClick={handleEditHobbies}
        >
          Enregistrer Changements
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
