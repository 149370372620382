import { RESET_PDF_FILE, SET_PDF_FILE } from "../actions/pdfFileAction";

const initialState = {
    pdfReaderData: null,
};

const pdfFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PDF_FILE:
      return {
        ...state,
        pdfReaderData: action.payload.data,
      };
    case RESET_PDF_FILE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default pdfFileReducer;
