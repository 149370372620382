import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, IconButton, InputAdornment, Modal, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import config from '../../config';
import axios from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const ChangePasswordModal = ({ open, onClose }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const userEmail = useSelector((state) => state.user.userEmail);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (open) {
      setError("");
      setSuccess("");
      setCurrentPassword("");
      setNewPassword("");
      setLoading(false);
      setShowPassword(false);
    }
  }, [open]);

  const handleChangePassword = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordPattern = /^(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!emailPattern.test(userEmail)) {
      setError("Adresse email invalide");
      return;
    }
    if (!passwordPattern.test(newPassword)) {
      setError("Le nouveau mot de passe doit comporter au moins 8 caractères, inclure au moins un chiffre et un caractère spécial");
      return;
    }
    if (newPassword === currentPassword) {
      setError("Le nouveau mot de passe est identique au précédent");
      return;
    }
    
    try {
      setLoading(true);
      const url = `${config.urlBackOffice}/change-password/`;
      await axios.put(url, {
        email: userEmail,
        currentPassword: currentPassword,
        newPassword: newPassword
      });
      setLoading(false);
      setSuccess("Le mot de passe a été modifié");
      setError('');
    } catch (error) {
      setLoading(false);
      setSuccess('');
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError("Erreur lors de la requête");
      }
      console.error('Error changing password:', error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}>
        <Typography variant="h6" component="h2" sx={{ mb: 4 }}>
          Changer son mot de passe
        </Typography>
        <TextField
          label="Mot de passe actuel"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          variant="outlined"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <TextField
          label="Nouveau mot de passe"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          variant="outlined"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
        {error && (
          <Box mr={3} color="red" fontWeight={"bold"} fontSize={"small"} maxWidth={"65%"}>{error}</Box>
        )}
        {success && (
          <Box mr={3} color="green" fontWeight={"bold"} fontSize={"small"} maxWidth={"65%"}>{success}</Box>
        )}
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#200A5F',
            color: 'white',
            '&:hover': {
              backgroundColor: '#321a8b',
            },
          }}
          onClick={handleChangePassword}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Modifier'}
        </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
