import { RESET_CV_THEME, SET_CV_THEME } from "../actions/cvThemeAction";

const initialState = {
  theme: { Content: "#C41070" },
  position: { Content: "center" },
  selectedImage: { Content: "" },
  positionLogo: { Content: "center" },
  showLine: { Content: true },
  showName: { Content: true },
  showAbbriviationName: { Content: false },
  showHobbies: { Content: true },
  backgroundColor: { Content: "#d11fb6" },
  font: { Content: "bold" },
  size: { Content: "15" },
  mleft: { Content: "10" },
  mtop: { Content: "-2" },
  sketchPickerColor: "#c41070",
  fontFamily: { Content: "Times New Roman" },
};

const cvThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CV_THEME:
      return {
        ...state,
        ...action.payload.data,
      };
    case RESET_CV_THEME:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default cvThemeReducer;
