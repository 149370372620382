export const styles = {
  headerTitle: {
    color: "#200A5F",
  },
  competenceLabel: {
    fontstyle: "oblique",
    bgcolor: "#200A5F",
    color: "#200A5F",
  },
  skillsLabel: {
    fontstyle: "oblique",
    bgcolor: "#200A5F",
    color: "#200A5F",
  },
  saveButton: {
    textTransform: "capitalize",
    border: "2px solid transparent",
    borderColor: "#DA2889",
    fontweight: "bold",
    borderRadius: "5px",
  },
};
