import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@mui/material/Grid";
import Adaptation from "../components/cvAdaptation/Adaptation";
import StepperComponent from "../components/StepperComponent";
import { setActiveStep } from "../redux/actions/stepperAction";
export default function AdaptationPage() {
  const activeStep = useSelector((state) => state.stepper.activeStep);

  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(setActiveStep(2));
  // }, [activeStep]);

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <StepperComponent />
      </div>
      <div>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 5, sm: 2, md: 3 }}>
          <Grid item xs={3.7}></Grid>
          <Grid item sx={{ mt: 8 }} xs={11.5} className="my-5">
            <Adaptation />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
