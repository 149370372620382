import React, { useState } from "react";
import { useSelector } from "react-redux";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import UpdateDescriptionModal from "./UpdateDescriptionModal";

export default function Description({
  collapsIcon,
  setCollapsIcon = () => {},
}) {
  const dataCollection = useSelector((state) => state.dataCollection);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (window.UndefinedVariable) {
    Object.assign(window.UndefinedVariable, {});
  }

  return (
    <Card>
      <CardContent sx={{ bgcolor: "#f3f4fa" }}>
        <div className="row ">
          <div className="col-4 col-md-2 col-lg-2  d-flex">
            <p
              className="mt-3"
              style={{ fontstyle: "oblique", fontWeight: "bold" }}
            >
              Description
            </p>
          </div>
          <div className="col-3 col-md-7 col-lg-8"></div>
          <div className="col-5 col-md-3 col-lg-2 d-flex justify-content-around align-items-center">
            <div>
              <Button
                style={{
                  maxHeight: "2.3em",
                  minHeight: "2.0em",
                  minWidth: "2em",
                  fontSize: ".85em",
                  textTransform: "capitalize",
                  border: "2px solid transparent",
                  borderColor: "#DA2889",
                  color: "#DA2889",
                  fontweight: "bold",
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                onClick={handleShow}
                sx={{ color: "white" }}
              >
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ margin: "4px" }}>
                    {/* <AjouterIcon /> */}
                    <EditIcon fontSize="inherit" />
                  </div>

                  <div className="d-none d-md-block">Modifier</div>
                </div>
              </Button>
            </div>
            <div>
              <Button
                style={{
                  maxHeight: "2.3em",
                  minHeight: "2.0em",
                  minWidth: "2em",
                  fontSize: ".85em",
                  textTransform: "capitalize",
                  border: "2px solid transparent",
                  borderColor: "#1976d2",
                  color: "#1976d2",
                  fontweight: "bold",
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  setCollapsIcon({
                    ...collapsIcon,
                    description: !collapsIcon.description,
                  });
                }}
                sx={{ color: "white" }}
              >
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {collapsIcon.description ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>

                  {/* <div>Modifier</div> */}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </CardContent>

      {collapsIcon.description &&
        dataCollection?.Description &&
        dataCollection?.Description?.Content && (
          <Box style={{ left: "20px" }} sx={{ mt: 5, mb: 5, ml: 2, mr: 8 }}>
            <Stack pacing={2} spacing={1} direction="row">
              <p>{dataCollection.Description.Content}</p>
            </Stack>
          </Box>
        )}
      <UpdateDescriptionModal show={show} handleClose={handleClose} />
    </Card>
  );
}
