export const SET_AVTIVE_STEP = "SET_AVTIVE_STEP";
export const SET_NEXT_BUTTON_STATUS = "SET_NEXT_BUTTON_STATUS";
export const RESET_STEPPER = "RESET_STEPPER";

export const setActiveStep = (activeStep) => ({
  type: SET_AVTIVE_STEP,
  payload: { activeStep },
});
export const setNextButtonStatus = (nextButtonStatus) => ({
  type: SET_NEXT_BUTTON_STATUS,
  payload: { nextButtonStatus },
});
export const resetStepper = () => ({
  type: RESET_STEPPER,
  payload: {},
});
