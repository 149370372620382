import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  setDataCollection,
  setEducation,
} from "../../../redux/actions/dataCollectionAction";
import UpdateEducationModal from "./UpdateEducationModal";
import AddEducationModal from "./AddEducationModal";
import { styles } from "./formationStyles";

export default function Formation({ collapsIcon, setCollapsIcon = () => {} }) {
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [dataCollection, setCurrentDataCollection] = useState({});
  const [savedDataCollection, setSavedDataCollection] = useState({});
  const [openModal, setopenModal] = useState(false);

  const collection = useSelector((state) => state.dataCollection);

  const dispatch = useDispatch();

  const handleClose = (type) => {
    if (type == "reset") {
      dispatch(setDataCollection({ ...savedDataCollection }));
    }

    setShow(false);
  };

  const handleAddFormationClose = () => setopenModal(false);

  const handleSelect = (item) => {
    setSelectedItem(item);
    setShow(true);
  };

  if (window.UndefinedVariable) {
    Object.assign(window.UndefinedVariable, {});
  }

  const deleteFormation = (index) => {
    const parentsArray = dataCollection.Education || [];
    if (index !== -1) {
      const newListArray = parentsArray.filter(
        (elem, key) => key.toString() !== index
      );
      dispatch(setEducation(newListArray));
    }
  };

  const ajoueFormation = () => {
    setopenModal(true);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newList = reorder(
      dataCollection.Education,
      result.source.index,
      result.destination.index
    );
    dispatch(setEducation(newList));
  };

  useEffect(() => {
    if (Object.keys(collection).length) {
      setCurrentDataCollection(collection);
      setSavedDataCollection(collection);
    }
  }, [collection]);
  return (
    <Card>
      <CardContent sx={{ bgcolor: "#f3f4fa" }}>
        <div className="row ">
          <div className="col-4 col-md-2 col-lg-2  d-flex justify-content-start align-items-center ">
            <p className="mt-3" style={styles.headerTitle}>
              Formations
            </p>
            <span style={styles.formationNumber}>
              {dataCollection !== null &&
              Object.keys(dataCollection).length &&
              dataCollection.Education !== null
                ? dataCollection.Education.length < 100
                  ? dataCollection.Education.length
                  : "99+"
                : 0}
            </span>
          </div>
          <div className="col-3 col-md-7 col-lg-8"></div>
          <div className="col-5 col-md-3 col-lg-2 d-flex justify-content-around align-items-center">
            <div>
              <Button
                style={styles.addButton}
                sx={{ color: "white" }}
                onClick={ajoueFormation}
              >
                <div style={styles.addIconContainer}>
                  <div>
                    <AddIcon />
                  </div>

                  <div className="d-none d-md-block">Ajouter</div>
                </div>
              </Button>
            </div>
            <div>
              <Button
                style={styles.collapsButton}
                onClick={() => {
                  setCollapsIcon({
                    ...collapsIcon,
                    formations: !collapsIcon.formations,
                  });
                }}
                sx={{ color: "white" }}
              >
                <div style={styles.collapsIconContainer}>
                  <div>
                    {collapsIcon.formations ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </CardContent>
      {dataCollection.Education != null && collapsIcon.formations ? (
        <div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {Object.entries(dataCollection.Education).map(
                    ([key, value]) => (
                      <Draggable
                        key={key}
                        draggableId={key.toString()}
                        index={parseInt(key)}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Box key={key}>
                              <div>
                                <div className="row">
                                  <div className="col-7 col-md-10">
                                    <Box
                                      align="left"
                                      spacing={2}
                                      mt={3}
                                      ml={3}
                                      direction="row"
                                    >
                                      <p style={styles.instituteLabel}>
                                        {value.Institute}
                                      </p>
                                    </Box>
                                    <Box
                                      align="left"
                                      spacing={2}
                                      mt={-2}
                                      ml={3}
                                      direction="row"
                                    >
                                      <p style={styles.titleLabel}>
                                        {value.Title}
                                      </p>
                                    </Box>
                                    <Box
                                      align="left"
                                      spacing={2}
                                      mt={-2}
                                      ml={3}
                                      direction="row"
                                    >
                                      <p style={styles.periodLabel}>
                                        {value.Period}
                                      </p>
                                    </Box>
                                  </div>
                                  {/* <div className="col-md-1"></div> */}

                                  <div className="col-5 col-md-2">
                                    <div className="row">
                                      <div className="col-6 col-md-6  mt-4">
                                        <Button
                                          key={key}
                                          style={styles.editButton}
                                          onClick={() => {
                                            setSelectedItemId(key);
                                            handleSelect(value);
                                          }}
                                        >
                                          <CreateIcon
                                            style={{
                                              fontSize: "medium",
                                            }}
                                          />
                                        </Button>
                                      </div>
                                      <div className=" col-6 col-md-6   mt-4 pb-3">
                                        <Button
                                          style={styles.deleteButton}
                                          onClick={() => deleteFormation(key)}
                                        >
                                          <DeleteIcon
                                            style={{
                                              fontSize: "medium",
                                            }}
                                          />
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Box>
                          </div>
                        )}
                      </Draggable>
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : (
        <div> </div>
      )}
      {/* Update Formation */}
      <UpdateEducationModal
        type={"formation"}
        modalTitle={"Formation"}
        show={show}
        selectedItem={selectedItem}
        selectedItemId={selectedItemId}
        setSelectedItem={(item) => {
          const data = dataCollection?.Education || [];
          const newListArray = data.map((elem, index) => {
            if (index.toString() === selectedItemId) return item;
            return elem;
          });
          setCurrentDataCollection({
            ...dataCollection,
            Education: newListArray,
          });
          setSelectedItem(item);
        }}
        handleClose={(type) => handleClose(type)}
      />

      {/* Add Formation */}
      <AddEducationModal
        type={"formation"}
        modalTitle={"Ajouter une Formation"}
        openModal={openModal}
        handleAddFormationClose={handleAddFormationClose}
      />
    </Card>
  );
}
