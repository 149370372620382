import { RESET_NOTIFICATION, SET_LOGGED_IN_NOTIF, SET_LOGGED_OUT_NOTIF, SET_PASSWORD_RESET_NOTIF } from "../actions/notificationAction";

const initialState = {
    loggedInNotif: false,
    loggedOutNotif: false,
    passwordResetNotif: false,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGGED_IN_NOTIF:
      return {
        ...state,
        loggedInNotif: action.payload.data,
      };
    case SET_LOGGED_OUT_NOTIF:
      return {
        ...state,
        loggedOutNotif: action.payload.data,
      };
    case SET_PASSWORD_RESET_NOTIF:
      return {
        ...state,
        passwordResetNotif: action.payload.data,
      };
    case RESET_NOTIFICATION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default notificationReducer;
