export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const RESET_LOGGED_IN_USER = 'RESET_LOGGED_IN_USER';

export const setLoggedInUser = (userEmail, token) => ({
  type: SET_LOGGED_IN_USER,
  payload: { userEmail, token }
});

export const resetLoggedInUser = () => ({
  type: RESET_LOGGED_IN_USER,
  payload: {},
});
