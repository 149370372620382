import React, { useState, useCallback } from "react";
import { Button, Grid } from "@mui/material";
import "./test.css";
import ExperienceTools from "./components/correction/panel/ExperienceTools";

const Test = () => {
  const [animate, setAnimate] = useState(false);

  // Function to toggle animation
  const toggleAnimation = () => {
    setAnimate(!animate);
  };

  return <ExperienceTools />;
};

export default Test;
