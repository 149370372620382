import React, { useState } from "react";
import { useSelector } from "react-redux";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CreateIcon from "@mui/icons-material/Create";
import "bootstrap/dist/css/bootstrap.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import UpdateLanguagesModal from "./panel/UpdateLanguagesModal";

export default function Langues({ collapsIcon, setCollapsIcon = () => {} }) {
  const [show, setShow] = useState(false);

  const dataCollection = useSelector((state) => state.dataCollection);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (window.UndefinedVariable) {
    Object.assign(window.UndefinedVariable, {});
  }

  return (
    <Card>
      <CardContent sx={{ bgcolor: "#f3f4fa" }}>
        <div className="row ">
          <div className="col-4 col-md-2 col-lg-2  d-flex justify-content-start align-items-center">
            <p
              className="mt-3"
              style={{
                fontstyle: "oblique",
                fontWeight: "bold",
                marginRight: "10px",
              }}
            >
              Langues
            </p>
            <span
              style={{
                backgroundColor: "#DA2889",
                maxHeight: "23px",
                minHeight: "23px",
                minWidth: "23px",
                color: "white",
                borderRadius: "50%",
                fontSize: "12px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {dataCollection.Languages !== null
                ? dataCollection.Languages.length < 100
                  ? dataCollection.Languages.length
                  : "99+"
                : 0}
            </span>
          </div>
          <div className="col-3 col-md-7 col-lg-8"></div>
          <div className="col-5 col-md-3 col-lg-2 d-flex justify-content-around align-items-center">
            <div>
              <Button
                style={{
                  maxHeight: "2.3em",
                  minHeight: "2.0em",
                  minWidth: "2em",
                  fontSize: ".85em",
                  textTransform: "capitalize",
                  border: "2px solid transparent",
                  borderColor: "#DA2889",
                  color: "#DA2889",
                  fontweight: "bold",
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                sx={{ color: "white" }}
                onClick={handleShow}
              >
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ padding: "4px" }}>
                    <CreateIcon
                      style={{
                        fontSize: "small",
                      }}
                    />
                  </div>

                  <div className="d-none d-md-block">Modifier</div>
                </div>
              </Button>
            </div>
            <div>
              <Button
                style={{
                  maxHeight: "2.3em",
                  minHeight: "2.0em",
                  minWidth: "2em",
                  fontSize: ".85em",
                  textTransform: "capitalize",
                  border: "2px solid transparent",
                  borderColor: "#1976d2",
                  color: "#1976d2",
                  fontweight: "bold",
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  setCollapsIcon({
                    ...collapsIcon,
                    Languages: !collapsIcon.Languages,
                  });
                }}
                sx={{ color: "white" }}
              >
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {collapsIcon.Languages ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>

                  {/* <div>Modifier</div> */}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </CardContent>

      {
        //Check if message failed
        dataCollection.Languages != null && collapsIcon.Languages ? (
          <div>
            {Object.entries(dataCollection.Languages).map(([key, value]) => (
              <Box key={key} mt={1}>
                <div className="row">
                  <div className="col-6 col-md-6 d-flex justify-content-center align-items-center">
                    <p
                      style={{
                        fontSize: "medieum",
                        justifyContent: "start",
                        fontstyle: "oblique",
                        fontWeight: "bold",
                      }}
                    >
                      {value.Language}
                    </p>
                  </div>
                  <div className="col-6 col-md-6 d-flex justify-content-center align-items-center">
                    <p
                      style={{
                        fontSize: "small",
                        justifyContent: "start",
                      }}
                    >
                      {value.Level}
                    </p>
                  </div>
                </div>
              </Box>
            ))}
          </div>
        ) : (
          <div> </div>
        )
      }

      <UpdateLanguagesModal show={show} handleClose={handleClose} />
    </Card>
  );
}
