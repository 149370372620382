import React from "react";
import { useSelector } from "react-redux";
import { Container } from "@mui/material";
import PdfReader from "../components/drop-file-input/PdfReader";

const OriginalCv = () => {
  const pdfFile = useSelector((state) => state.pdfFile);
  const pdfReaderData = JSON.parse(pdfFile?.pdfReaderData);
  return (
    <Container sx={{ my: 1 }}>
      <PdfReader pdfFile={pdfReaderData} />
    </Container>
  );
};

export default OriginalCv;
