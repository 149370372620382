import React, { useEffect } from "react";
import HorizontalLinearStepper from "../mainPage/HorizontalLinearStepper";
import Grid from "@mui/material/Grid";
import "bootstrap/dist/css/bootstrap.css";

function Global(props) {
  // useEffect(() => {
  //   const cleanupStorage = () => {
  //     var persistItem = localStorage.getItem("persist:root");
  //     localStorage.clear();
  //     localStorage.setItem("persist:root", persistItem);
  //   };

  //   window.addEventListener("beforeunload", cleanupStorage);

  //   return () => {
  //     window.removeEventListener("beforeunload", cleanupStorage);
  //   };
  // }, []);
  return (
    <div>
      <Grid sx={{ mt: 7 }}>
        <HorizontalLinearStepper />
      </Grid>
    </div>
  );
}

export default Global;
