import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Box, TextField, Button, Typography, Paper, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import config from '../../config';
import { setPasswordResetNotif } from '../../redux/actions/notificationAction';
import { useDispatch } from 'react-redux';

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loadingPage, setLoadingPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [tokenInvalid, setTokenInvalid] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const url = `${config.urlBackOffice}/verify-password-reset-token/`;
        await axios.post(url, { token });
        setLoadingPage(false);
      } catch (error) {
        setError("Le lien de réinitialisation est invalide ou a expiré.");
        setTokenInvalid(true);
        setLoadingPage(false);
      }
    };
    verifyToken();
  }, [token]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const passwordPattern = /^(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordPattern.test(password)) {
      setError("Le nouveau mot de passe doit comporter au moins 8 caractères, inclure au moins un chiffre et un caractère spécial");
      return;
    }
    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas");
      return;
    }
    
    try {
      setLoading(true);
      const url = `${config.urlBackOffice}/reset-password/`;
      await axios.put(url, { token, password });
      setLoading(false);
      setError('');
      dispatch(setPasswordResetNotif(true));
      navigate('/global');

    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError("Erreur lors de la requête");
      }
      console.error('Error changing password:', error);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Grid container justifyContent="center" style={{ paddingTop: '18vh' }}>
        <Grid item xs={12} sm={8} md={4} lg={3}>
          <Paper elevation={3} style={{ padding: '2em' }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h5" component="h2" gutterBottom>
                Réinitialisation du mot de passe
              </Typography>
              {loadingPage ? (
                <CircularProgress />
              ) : tokenInvalid ? (
                <>
                  <Typography color="error" style={{ marginTop: '1em' }}>
                    {error}
                  </Typography>
                  <Button
                    variant="contained"
                    style={{ marginTop: '2em' }}
                    sx={{
                      backgroundColor: '#200A5F',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#321a8b',
                      },
                    }}
                    onClick={() => navigate('/global')}
                  >
                    Retour
                  </Button>
                </>
              ) : (
                <form onSubmit={handleResetPassword} style={{ width: '100%' }}>
                  <TextField
                    label="Nouveau mot de passe"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Confirmer mot de passe"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {error && (
                    <Typography color="error" style={{ marginTop: '1em' }}>
                      {error}
                    </Typography>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    style={{ marginTop: '2em' }}
                    sx={{
                      backgroundColor: '#200A5F',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#321a8b',
                      },
                    }}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Confirmer'}
                  </Button>
                </form>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPassword;