import Axios from "axios";
import config from "../config";
const CvService = {
  async create(data) {
    try {
      const url = `${config.urlBackOffice}/extract/`;
      const response = await Axios.post(url, data);
      return response;
    } catch (error) {
      console.log("error===>", error);
      const errorResponse = {
        data: {
          data: {
            statut: false,
            error: error?.message ? error?.message : "Network error",
          },
        },
      };
      return errorResponse;
    }
  },
  async agreementUser(data, headers) {
    try {
      const url = `${config.urlBackOffice}/delete-generated-data/`;
      const response = await Axios.post(url, data, {
        timeout: 10000,
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
      });
      return response;
    } catch (error) {
      console.log("error===>", error);
      const errorResponse = {
        data: {
          data: {
            statut: false,
            error: error?.message ? error?.message : "Network error",
          },
        },
      };
      return errorResponse;
    }
  },
  async generate(data, headers) {
    const url = `${config.urlBackOffice}/generate/`;

    try {
      const response = await Axios.post(url, data, {
        timeout: 10000,
        responseType: "arraybuffer",
        headers: headers,
      });
      return response;
    } catch (error) {
      console.log("error===>", error);
      const errorResponse = {
        data: {
          error: error?.message ? error?.message : "Network error",
        },
        status: false,
      };
      return errorResponse;
    }

    // return await Axios({
    //   url,
    //   data: data,
    //   timeout: 10000,
    //   method: "POST",
    //   responseType: "arraybuffer",
    //   headers: headers,
    // });
  },
};

export default CvService;
