import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { setSkills } from "../../../redux/actions/dataCollectionAction";

export default function AddSkillModal({
  openModal,
  handleAddSkillsClose = () => {},
}) {
  const [name, setName] = useState("");
  const [skill, setSkill] = useState("");

  const dataCollection = useSelector((state) => state.dataCollection);
  const dispatch = useDispatch();

  const addNewCertification = (newName, newSkills) => {
    const skills = !newSkills.includes(",")
      ? [newSkills]
      : newSkills.split(",");
    const parentsArray = dataCollection.Skills || [];
    const NewParentsArray = [
      ...parentsArray,
      {
        Name: newName,
        Skills: skills,
      },
    ];

    dispatch(setSkills(NewParentsArray));
    handleAddSkillsClose();
  };

  useEffect(() => {
    setName("");
    setSkill("");
  }, [openModal]);

  return (
    <Modal show={openModal} onHide={handleAddSkillsClose}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            bgcolor: "#200A5F",
            color: "#200A5F",
          }}
        >
          Ajouter des compétences
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-1">
            <Form.Label size="sm" color="primary">
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Titre
              </span>
            </Form.Label>
            <div className="row">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <Grid mb={1}>
                      <Form.Control
                        xm={1}
                        size="sm"
                        type="text"
                        value={name}
                        placeholder="Titre..."
                        autoFocus
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label size="sm" color="primary">
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Compétences
              </span>
            </Form.Label>
            <div className="row">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <Grid mb={1}>
                      <Form.Control
                        xm={1}
                        size="sm"
                        type="text"
                        value={skill}
                        placeholder="please separate skills with ( , )"
                        autoFocus
                        onChange={(e) => setSkill(e.target.value)}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-xs-3"></div>

        <Button
          style={{
            textTransform: "capitalize",
            border: "2px solid transparent",
            borderColor: "#DA2889",
            fontweight: "bold",
            borderRadius: "5px",
          }}
          sx={{ bgcolor: "#DA2889", color: "white" }}
          onClick={() => addNewCertification(name, skill)}
        >
          Enregistrer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
