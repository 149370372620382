import { RESET_TEMPLATE, SET_TEMPLATE } from '../actions/templateActions';

const initialState = {
  templateURL: null,
  templateName: null
};

const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEMPLATE:
      return {
        ...state,
        templateURL: action.payload.templateURL,
        templateName: action.payload.templateName
      };
    case RESET_TEMPLATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default templateReducer;
